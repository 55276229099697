/** Applies a function to a value, either immediately or after it resolves */

export function applyAfter<SourceType, TargetType, RejectType = TargetType>(
  value: SourceType | Promise<SourceType>,
  func: (value: SourceType) => TargetType,
  rejects?: (exc: unknown) => RejectType,
  finalizer?: () => void
): TargetType | RejectType | Promise<TargetType | RejectType> {
  if (value instanceof Promise)
    if (rejects || finalizer)
      return value
        .then((val) => {
          const res = func(val);
          finalizer?.();
          return res;
        })
        .catch((e) => {
          try {
            if (rejects) return rejects(e);
          } finally {
            finalizer?.();
          }
          throw e;
        });
    else
      return value.then((val) => {
        return func(val);
      });
  else if (rejects || finalizer)
    try {
      return func(value);
    } catch (e) {
      if (rejects) return rejects(e);
      throw e;
    } finally {
      finalizer?.();
    }
  else return func(value);
}

/** Applies a function to a calculated value, either immediately or after it resolves */

export function applyAfterEval<SourceType, TargetType, RejectType = TargetType>(
  calc: () => SourceType | Promise<SourceType>,
  func: (value: SourceType) => TargetType,
  rejects?: (exc: unknown) => RejectType,
  finalizer?: () => void
): TargetType | RejectType | Promise<TargetType | RejectType> {
  let res: SourceType | Promise<SourceType>;
  try {
    res = calc();
  } catch (exc) {
    try {
      if (rejects) return rejects(exc);
    } finally {
      finalizer?.();
    }
    throw exc;
  }
  return applyAfter(res, func, rejects, finalizer);
}
