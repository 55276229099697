import { OperationSpec, OperationType } from '@sqior/js/operation';

export const DeviceFixedRolesPath = 'device-fixed-roles';

export type FixedRolesItemViewModel = { label: string; id: string };
export type FixedRolesViewModel = { items: FixedRolesItemViewModel[]; selected?: string };

export function SelectFixedRoles(id: string): OperationSpec<string> {
  return { type: OperationType.Add, path: DeviceFixedRolesPath, data: id };
}
