/** Error which can carried multiple error messages
 */
export class MultipleMessageError extends Error {
  constructor(msg: string[]) {
    super(msg.join(', '));
    this.messages = msg;
  }

  readonly messages: string[];
}

export class ValidationError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
