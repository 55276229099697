import { InformGroupVM } from '@sqior/viewmodels/communication';
import ThreadGroupItem from '../thread-group-item/thread-group-item';
import styles from './thread-groups.module.css';

/* eslint-disable-next-line */
export interface ThreadGroupsProps {
  items: InformGroupVM[];
  onGroupClicked: (item: InformGroupVM) => void;
}

export function ThreadGroups(props: ThreadGroupsProps) {
  return (
    <div className={styles['container']}>
      {props.items.map((item) => {
        return (
          <ThreadGroupItem
            key={JSON.stringify(item.id)}
            item={item}
            onGroupClicked={props.onGroupClicked}
          />
        );
      })}
    </div>
  );
}

export default ThreadGroups;
