import { NoTimestamp } from '@sqior/js/data';
import { DataResult, StdOperationHandler } from '@sqior/js/operation';
import { PhoneNumbersVM } from '@sqior/viewmodels/device';

export class PhoneCallHandler extends StdOperationHandler<PhoneNumbersVM> {
  /** Handles the phone call operation  */
  override async add(data: PhoneNumbersVM): Promise<DataResult> {
    if (data.numbers.length) document.location.href = data.numbers[0].phoneNumber;
    return ['', NoTimestamp];
  }
}
