import styles from './pie-chart.module.css';

/* eslint-disable-next-line */
export interface PieChartProps {
  className: string;
  percentage: number;
  alwaysIndiciatePie?: boolean;
  text: string;
  color?: string;
}

interface ExtendedCSSProperties extends React.CSSProperties {
  '--p': number;
}

/** Renders a PieChart
 *
 * Required CSS parameters, e.g.:
 * --bgcolor: var(#000000);
 * --fgcolor: var(#ffffff);
 * --pie-border: 1pt;
 */
export function PieChart(props: PieChartProps) {
  let renderPercentage = props.percentage;
  if (props.alwaysIndiciatePie) renderPercentage = Math.max(Math.min(renderPercentage, 99), 0.5);

  let pieStyle: ExtendedCSSProperties = {
    '--p': renderPercentage,
  };

  if (props.color !== undefined) pieStyle = { ...pieStyle, ...{ '--fgcolor': props.color } };

  return (
    <div
      className={`${props.className} ${styles['pie-chart']} ${styles['animate-animate']}`}
      style={pieStyle}
    >
      {props.text}
    </div>
  );
}

export default PieChart;
