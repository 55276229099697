import styles from './contact-list-content.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { AddressListContent } from '@sqior/viewmodels/patient';
import { AddressList } from '@sqior/react/uichat';

export type AddressListContentProps = FactoryProps<AddressListContent>;

export function ContactListContent({ data: { team, relatedId } }: AddressListContentProps) {
  return (
    <div className={styles['container']}>
      {team && team.length > 0 && <AddressList items={team} relatedId={relatedId} />}
    </div>
  );
}

export default ContactListContent;
