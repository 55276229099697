import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';

export const UserSettingsPath = 'usersettings';
export const UserSettingsSubPathSlogans = 'slogans';

export type UserSettingsVM = {
  disableSlogans?: boolean;
};

export function UserSettingsEnableSlogans(enable: boolean): OperationSpec<boolean> {
  return {
    type: OperationType.Add,
    path: joinPath(UserSettingsPath, UserSettingsSubPathSlogans),
    data: enable,
  };
}
