import { Entity, EntityHeader } from '@sqior/js/entity';
import { SelectionInput } from './selection-menu';

export enum EscalationReason {
  Deferred = 'deferred',
  Denied = 'denied',
  Timeout = 'timeout',
  Undeliverable = 'undeliverable',
}
export type EscalationReasonEntity = EntityHeader & {
  reason: string;
  delay?: Entity; // only in case reason: Deferred
  denyReason?: string; // only in case reason: Denied
};

/* Entity representing the input parameter for escalataion reasons */

export const EscalationReasonInputType = 'EscalationReasonInput';
export type EscalationReasonInput = Entity & {
  delayHeader: string;
  delays: SelectionInput[];
  otherHeader: string;
  others: SelectionInput[];
};

/* Entity representing the selected input parameters of a transport order */

export const EscalationReasonParametersType = 'EscalationReasonParameters';
export type EscalationReasonParameters = Entity & { reason: Entity };
