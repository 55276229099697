import logoRectBlack from './logo_rect_black.svg';
import logoRectWhite from './logo_rect_white.svg';
import logoSquareBlack from './logo_square_black.svg';
import logoSquareWhite from './logo_square_white.svg';
import logoPure from './logo_pure.svg';

export enum LogoType {
  RectBlack,
  RectWhite,
  SquareBlack,
  SquareWhite,
  LogoOnly,
}
/* eslint-disable-next-line */
export interface LogoProps {
  className: string;
  type?: LogoType;
}

export function Logo(props: LogoProps) {
  let logo = logoRectWhite;
  switch (props.type) {
    case LogoType.LogoOnly:
      logo = logoPure;
      break;
    case LogoType.RectBlack:
      logo = logoRectBlack;
      break;
    case LogoType.RectWhite:
      logo = logoRectWhite;
      break;
    case LogoType.SquareBlack:
      logo = logoSquareBlack;
      break;
    case LogoType.SquareWhite:
      logo = logoSquareWhite;
      break;
  }

  return <img src={logo} className={props.className} alt="logo" />;
}

export default Logo;
