import { ChecklistOptionViewModel } from '@sqior/viewmodels/checklist';
import { HighlightButton } from '@sqior/react/uibase';
import { useContext } from 'react';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { Entity } from '@sqior/js/entity';

export interface ChecklistOptionControlProps {
  data: ChecklistOptionViewModel;
  disabled: boolean;
  select: (check: boolean, data?: Entity) => void;
}

export function ChecklistOptionControl(props: ChecklistOptionControlProps) {
  const selection = useContext(SelectionPageContext);
  return (
    <HighlightButton
      secondary={!props.data.checked}
      disabled={props.disabled}
      onClick={() => {
        /* Check if a value selection is specified for this option */
        if (props.data.selection && !props.data.checked)
          selection(props.data.selection, (ok, data) => {
            if (ok)
              props.select(
                !props.data.checked,
                data?.['entity'] ? (data['entity'] as Entity) : undefined
              );
          });
        else props.select(!props.data.checked);
      }}
      style={{ width: 'max-content', minWidth: '52px', padding: '8px', fontWeight: 'bolder' }}
    >
      {props.data.text}
    </HighlightButton>
  );
}

export default ChecklistOptionControl;
