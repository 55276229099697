import {
  ComposedMessage,
  ComposedMessageType,
  Message,
  MessageFunc,
  MessageHandler,
} from './message';

export class MessageDispatcher<ResultType = void, Types extends unknown[] = []>
  implements MessageHandler<ResultType, Types>
{
  constructor() {
    this.handlers = new Map<string, MessageFunc<Message, ResultType, Types>>();
    this.set<ComposedMessage>(ComposedMessageType, async (msg, ...params) => {
      let res: ResultType[] = [];
      for (const m of msg.msgs) res = res.concat(await this.handle(m, ...params));
      return res;
    });
  }

  set<MessageType extends Message = Message>(
    type: string,
    handler: MessageHandler<ResultType, Types> | MessageFunc<MessageType, ResultType, Types>
  ) {
    if (typeof handler === 'function')
      this.handlers.set(type, (msg, ...params) => {
        return handler(msg as MessageType, ...params);
      });
    else
      this.handlers.set(type, (msg, ...params) => {
        return handler.handle(msg, ...params);
      });
  }

  async handle(msg: Message, ...params: Types) {
    const handler = this.handlers.get(msg.type);
    if (handler) return handler(msg, ...params);
    else throw new Error('No handler registered for message type: ' + msg.type);
  }

  private handlers: Map<string, MessageFunc<Message, ResultType, Types>>;
}
