import { OperationSpec, OperationType } from '@sqior/js/operation';

/** Path for fixed push devices */
export const FixedPushDevicesPath = 'fixed-push-devices';

/** Type of fixed push devices entries */
export type FixedPushDevicesType = { roles: { id: string; text: string }[]; selected: string };

/** Operation setting the fixed push device role */
export function SelectFixedPushDeviceRole(roleKey: string): OperationSpec<string> {
  return { type: OperationType.Add, path: FixedPushDevicesPath, data: roleKey };
}
