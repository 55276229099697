import { AddressGroupInfoVM } from '@sqior/viewmodels/communication';
import { CaveInfoVM, WorkflowInfoVM } from '@sqior/viewmodels/patient';
import { MainWorkflowOverview } from '@sqior/viewmodels/workflow';

export const ORWorkflowOverviewType = 'ORWorkflowOverview';

export enum EmergencyCategoryType {
  N0 = 'n0',
  N1 = 'n1',
  N2 = 'n2',
  N3 = 'n3',
  N4 = 'n4',
  N5 = 'n5',
}

export type EmergencyClassificationVM = {
  category: EmergencyCategoryType;
  note?: string;
  color?: string;
  opacity?: number;
  emergencyCountdown?: boolean;
};

export const EmergencyClassificationInfoVMType = 'EmergencyClassificationInfoVMType';
export type EmergencyClassificationInfoVM = WorkflowInfoVM & EmergencyClassificationVM;
export function makeEmergencyClassificationInfoVM(
  ei: EmergencyClassificationVM
): EmergencyClassificationInfoVM {
  return {
    ...ei,
    ...{ entityType: EmergencyClassificationInfoVMType, type: 'short', alwaysVisibile: true },
  };
}

export type ORWorkflowOverview = MainWorkflowOverview & {
  team?: AddressGroupInfoVM[]; // To be removed
  caveInfos?: CaveInfoVM[];
};
