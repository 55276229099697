export enum TimestampClass {
  Before = 'before',
  After = 'after',
}

export type LogicalTimestamp = {
  type: string;
  age?: number[];
  value?: string;
};

export const NoTimestamp: LogicalTimestamp = { type: TimestampClass.Before };
export const Eternity: LogicalTimestamp = { type: TimestampClass.After };

export function isBefore(first: LogicalTimestamp, second: LogicalTimestamp) {
  /* Check if the timestamp type is different, check for the build in types that go before and after all others */
  if (first.type != second.type)
    if (first.type == TimestampClass.Before) return true;
    else if (second.type == TimestampClass.Before) return false;
    else if (first.type == TimestampClass.After) return false;
    else if (second.type == TimestampClass.After) return true;
    else
      throw new TypeError('Timestamp type ' + first.type + ' cannot be compared to ' + second.type);
  /* Compare the age */
  if (first.age && second.age) {
    for (let i = 0; i < Math.min(first.age.length, second.age.length); i++)
      if (first.age[i] < second.age[i]) return true;
      else if (first.age[i] > second.age[i]) return false;
    return first.age.length < second.age.length;
  } else if (first.value && second.value) return first.value < second.value;
  /* Compare the value */
  else if (!first.age && !first.value && !second.age && !second.value) return false;
  throw new TypeError('Timestamp type ' + first.type + ' cannot be compared to ' + second.type);
}
