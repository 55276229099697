import styles from './sqior-iosconfirm-dialog.module.css';
import { FC } from 'react';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import HighlightButton from '../highlight-button/highlight-button';
import { classes } from '@sqior/react/utils';

interface Props {
  onConfirm: (data: unknown) => void;
  onCancel: () => void;
  title?: string;
  confirm?: string;
  cancel?: string;
  data?: unknown;
  operation?: 'delete' | 'remove' | 'leave';
}

export const SqiorIOSConfirmDialog: FC<Props> = ({
  title,
  confirm,
  cancel,
  data,
  onConfirm,
  operation,
  onCancel,
}) => {
  if (!data) return null;
  return (
    <div className={styles['container']}>
      <motion.div
        className={styles['buttons-container']}
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        <div className={styles['message-container']}>
          <HighlightButton
            secondary
            onClick={() => onConfirm(data)}
            additionalClassName={styles['button-confirm']}
          >
            <div className={styles['warning']}>{confirm}</div>
          </HighlightButton>
        </div>
        <div className={styles['cancel-button']}>
          <HighlightButton
            secondary
            onClick={onCancel}
            additionalClassName={styles['button-cancel']}
          >
            {cancel}
          </HighlightButton>
        </div>
      </motion.div>
    </div>
  );
};

export default SqiorIOSConfirmDialog;
