import styles from './static-card.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { StaticCardVM } from '@sqior/viewmodels/analytics-dashboard';
import { Typography } from '@sqior/react/sqior-ui';
import CardLayout from '../card-layout/card-layout';
import stars from './stars.png';
import { motion } from 'framer-motion';

export type StaticCardProps = FactoryProps<StaticCardVM>;

export function StaticCard({
  data: {
    currentDayIncluded,
    title = 'Hallo, Willkommen im Analyse Dashboard',
    description = 'Das Analyse-Dashboard stellt die wichtigsten Kennzahlen Ihrer Krankenhausprozesse in Echtzeit dar. Die Datenbasis beginnt am 1.1.2024 und umfasst aktuell 12345 Patienten.',
  },
}: StaticCardProps) {
  return (
    <CardLayout style={{ padding: 26 }}>
      <div className={styles['gradient']} />
      <motion.img
        src={stars}
        alt=""
        className={styles['stars']}
        animate={currentDayIncluded ? { rotate: 360 } : {}}
        transition={{
          repeat: Infinity,
          duration: 360,
          ease: 'linear',
        }}
      />
      <div className={styles['container']}>
        <Typography primary>Analyse</Typography>
        <Typography
          style={{
            fontSize: 36,
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Typography secondary className={styles['text']}>
          {description}
        </Typography>
      </div>
    </CardLayout>
  );
}

export default StaticCard;
