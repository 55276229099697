import { TextResourceAccess, TextState } from '@sqior/js/language';
import { useDynamicState } from '@sqior/react/state';

export function useTextResources() {
  /* Use the text resource state */
  const textResources = useDynamicState<TextState>('merged-text-resource', {
    lang: '',
    resources: {},
  });
  return new TextResourceAccess(textResources);
}
