import { FC } from 'react';
import { FactoryProps } from '@sqior/react/factory';
import { ORWorkflowOverviewDashboardQuota } from '@sqior/viewmodels/orworkflow';
import { useUIGlobalState } from '@sqior/react/state';
import styles from './background-marker.module.css';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { useTheme } from '@sqior/react/theme';

type BGMarkerViewProps = FactoryProps<ORWorkflowOverviewDashboardQuota>;

const OUTER_SPACE = 4;

export const BackgroundMarker: FC<BGMarkerViewProps> = ({ data }) => {
  const {
    UIGlobalState: { showBackgroundMarker },
  } = useUIGlobalState();

  const { getContentColor } = useTheme();

  if (!showBackgroundMarker) return null;

  const { bg, fg } = getContentColor(data.specialty?.specialtyColor as ColorCodes);

  const title = data.title;
  const description = data.description;

  const getBackground = (): string => {
    return bg.value;
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -OUTER_SPACE,
        right: -OUTER_SPACE,
        background: getBackground(),
        padding: 5,
      }}
    >
      {title && (
        <div
          className={styles['title']}
          style={{
            color: fg.value || 'white',
          }}
        >
          {title}
        </div>
      )}
      {description && (
        <div
          className={title ? styles['description'] : styles['description-alone']}
          style={{
            color: fg.value || 'white',
          }}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default BackgroundMarker;
