import { ArraySource, ensureArray } from './array';
import { Bytes } from './binary';
import { writeMagic } from './magic';
import { makeImmutable } from './mutability';
import { Value, ValueOrNothing } from './value';

/* Matching operators */

export const Wildcard = writeMagic('*');
export const MatchOperatorName = '__comp__';

export enum MatchOperator {
  Not = 'not',
  And = 'and',
  Or = 'or',
  Greater = 'gt',
  GreaterOrEqual = 'gte',
  Less = 'lt',
  LessOrEqual = 'lte',
  ArraySubset = 'array-subset',
  ArrayNotEmpty = 'array-not-empty',
}

export type MatchHeader = { [MatchOperatorName]: MatchOperator };
export type SubMatchType = MatchHeader & { match: Value };
export type ArrayMatchType = MatchHeader & { values: Value[] };
export type NumericComparison = MatchHeader & { value: number };
export type MatchType = NumericComparison | SubMatchType | ArrayMatchType;

/* Logical operators */

export function notMatch(match: Value): SubMatchType {
  return { [MatchOperatorName]: MatchOperator.Not, match };
}
export function andMatch(values: ArraySource<Value>): ArrayMatchType {
  return { [MatchOperatorName]: MatchOperator.And, values: ensureArray(values) };
}
export function orMatch(values: ArraySource<Value>): ArrayMatchType {
  return { [MatchOperatorName]: MatchOperator.Or, values: ensureArray(values) };
}

/** Numeric operators */

export function greaterThan(value: number): NumericComparison {
  return { [MatchOperatorName]: MatchOperator.Greater, value };
}
export function greaterThanOrEqual(value: number): NumericComparison {
  return { [MatchOperatorName]: MatchOperator.GreaterOrEqual, value };
}
export function lessThan(value: number): NumericComparison {
  return { [MatchOperatorName]: MatchOperator.Less, value };
}
export function lessThanOrEqual(value: number): NumericComparison {
  return { [MatchOperatorName]: MatchOperator.LessOrEqual, value };
}

/** Array-specifc operators */

export const ArrayNotEmpty: MatchHeader = makeImmutable({
  [MatchOperatorName]: MatchOperator.ArrayNotEmpty,
});
export function arraySubsetMatch(values: Value[]): ArrayMatchType {
  return { [MatchOperatorName]: MatchOperator.ArraySubset, values };
}

export function matchArraySubset(subset: Value[], full: Value[]) {
  /* All values need to match */
  for (const value of subset) {
    let found = false;
    for (const ref of full)
      if (subsetMatch(value, ref)) {
        found = true;
        break;
      }
    if (!found) return false;
  }
  return true;
}

/** Matches a comparison operator object against a value */
export function matchComparisonOperator(comp: MatchType, value: ValueOrNothing) {
  switch (comp[MatchOperatorName]) {
    /* Logical operators */
    case MatchOperator.Not:
      return !subsetMatch((comp as SubMatchType).match, value);
    case MatchOperator.And:
      for (const match of (comp as ArrayMatchType).values)
        if (!subsetMatch(match, value)) return false;
      return true;
    case MatchOperator.Or:
      for (const match of (comp as ArrayMatchType).values)
        if (subsetMatch(match, value)) return true;
      return false;
    /* Numeric comparison */
    case MatchOperator.Greater:
      return typeof value === 'number' && value > (comp as NumericComparison).value;
    case MatchOperator.GreaterOrEqual:
      return typeof value === 'number' && value >= (comp as NumericComparison).value;
    case MatchOperator.Less:
      return typeof value === 'number' && value < (comp as NumericComparison).value;
    case MatchOperator.LessOrEqual:
      return typeof value === 'number' && value <= (comp as NumericComparison).value;
    /* Array specific operators */
    case MatchOperator.ArrayNotEmpty:
      return typeof value === 'object' && value instanceof Array ? value.length > 0 : false;
    case MatchOperator.ArraySubset:
      return typeof value === 'object' && value instanceof Array
        ? matchArraySubset((comp as ArrayMatchType).values, value)
        : false;
    default:
      throw new Error('Unknown comparison operator provided: ' + comp[MatchOperatorName]);
  }
}

/** Checks recursively if all properties in the first object match the second, supporting magic comparison operators */
export function subsetMatch(
  first: ValueOrNothing,
  second: ValueOrNothing,
  checkForArrayElement = true
): boolean {
  /* Check for wildcard */
  if (first === Wildcard) return !!second;
  /* Check if there is literal identity */
  if (first === second) return true;
  /* Check for a comparison object */
  if (typeof first === 'object' && MatchOperatorName in first)
    return matchComparisonOperator(first as MatchType, second);
  /* The only two cases in which there is no literal identity are records and arrays */
  /* Check if this is an object (and a non-array as arrays are objects, too) */
  if (
    first instanceof Object &&
    second instanceof Object &&
    !(first instanceof Bytes) &&
    !(second instanceof Bytes) &&
    !(first instanceof Array) &&
    !(second instanceof Array)
  ) {
    for (const key in first)
      if (
        !subsetMatch(
          first[key] as unknown as ValueOrNothing,
          second[key] as unknown as ValueOrNothing
        )
      )
        return false;
    return true;
  } else if (first instanceof Array && second instanceof Array) {
    /* Check if all array elements are equal */
    if (first.length != second.length) return false;
    for (let i = 0; i < first.length; i++) if (!subsetMatch(first[i], second[i])) return false;
    return true;
  } else if (checkForArrayElement && second instanceof Array)
    /* Check for an array element being contained in an array */
    for (let i = 0; i < second.length; i++) if (subsetMatch(first, second[i], false)) return true;

  return false;
}
