import { useEffect } from 'react';

export interface UseBackButtonProps {
  deps?: any[];
  onBackClick: () => void;
}

export const useBackButton = ({ deps, onBackClick }: UseBackButtonProps) => {
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      onBackClick && onBackClick();
      window.history.pushState(null, '', window.location.pathname);
    };

    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, deps);
};

export default useBackButton;
