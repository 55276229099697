import { ensureAndCombineArray, now } from '@sqior/js/data';
import { AddOperation } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import {
  ButtonCheck,
  ButtonUndo,
  ContextMenu,
  CountdownTimer,
  HighlightButton,
  Position,
} from '@sqior/react/uibase';
import { classes, ConfigContext, getPrettyTimeString } from '@sqior/react/utils';
import { MouseEvent, useContext, useState } from 'react';
import {
  doUndo,
  EntityHTML,
  InformProjectionFlag,
  InformProjectionVM,
  isChatMessage,
  isEscalation,
  isFlaggedActive,
  MarkReadData,
  ReadConfirmationTypes,
  ResponseData,
} from '@sqior/viewmodels/communication';
import EntityVisual from '../entity-visual/entity-visual';
import ThreadItemStatus from '../thread-item-status/thread-item-status';
import SimpleInterpreterMenu from '../simple-interpreter-menu/simple-interpreter-menu';
import styles from './thread-item.module.css';
import '../uiconversation.css';
import EscalationIcon from '../escalation-icon/escalation-icon';
import { CreateAndDisplayInquiry } from '@sqior/viewmodels/communication';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { SelectionMenu } from '@sqior/viewmodels/input';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { useUIGlobalState } from '@sqior/react/state';

/* eslint-disable-next-line */
export interface ThreadItemProps {
  className?: string;
  content: InformProjectionVM;
  displayMarkRead?: boolean;
  displaySenderOrRecipient?: boolean;
  considerSentByMySelf?: boolean;
  displayResponseOptionsAnswers?: boolean;
  displayFollowUpResponses?: boolean;
}

export function ThreadItem(props: ThreadItemProps) {
  const { setUIGlobalState } = useUIGlobalState();

  const debugMode = false;
  const dispatcher = useContext(OperationContext);
  const configContext = useContext(ConfigContext);

  const [responseBlock, responseTimeout, responseDuration] = getResponseBlock(
    props.content,
    handleUndoClick,
    props.displayResponseOptionsAnswers,
    props.displayFollowUpResponses
  );

  function handleClick(e: MouseEvent) {
    const selectedTarget = e.target as HTMLElement;
    const isImage = selectedTarget.tagName === 'IMG';
    if (isImage) return;

    if (props.content.related) {
      dispatcher.start(SelectPatient(props.content.related));
    } else if (isChatMessage(props.content)) {
      dispatcher.start(CreateAndDisplayInquiry(props.content.reference));
    }
  }

  function markRead(item: InformProjectionVM) {
    const data: MarkReadData = {
      id: ensureAndCombineArray(item.id, item.additionalIds),
      confirmationType: ReadConfirmationTypes.Explicit,
      timestamp: now(),
    };
    dispatcher.handle(new AddOperation(data), 'communication-read-confirmation');
  }
  function handleUndoClick(e: React.MouseEvent, undoInterpreterKey: string | undefined) {
    e.stopPropagation();
    if (undoInterpreterKey) {
      doUndo(dispatcher, undoInterpreterKey);
    }
  }

  const styleUndoOrStop =
    props.content.flag === InformProjectionFlag.Undo
      ? styles['thread-item-undo']
      : props.content.flag === InformProjectionFlag.Stop
      ? styles['thread-item-stop']
      : '';
  const styleSentOrReceived =
    props.considerSentByMySelf && props.content.sentByMyself
      ? 'thread-item-sent'
      : 'thread-item-received';
  const styleClickable = ''; //props.content.patKey ? 'thread-item-message-clickable':'';
  return (
    <div className={classes('thread-item', styleSentOrReceived, props.className)}>
      <div
        className={classes('thread-item-message', styleUndoOrStop, styleClickable)}
        onClick={handleClick}
      >
        {(debugMode || (props.displaySenderOrRecipient && !props.content.sentByMyself)) && (
          <div className={styles['sender']}>
            <EntityVisual content={props.content.sender} />
          </div>
        )}
        {debugMode && (
          <div className={styles['recipient']}>
            <EntityVisual content={props.content.recipient} />
          </div>
        )}

        <div className={styles['content-area']}>
          <div className={styles['content']}>
            {props?.content?.attachments &&
              props.content.attachments.map((a, idx) => (
                <div
                  key={idx}
                  className={styles['image-container']}
                  onClick={() => {
                    setUIGlobalState((prev) => ({
                      ...prev,
                      previewSrc: configContext.getEndpoint(a.url.url).toString(),
                    }));
                  }}
                >
                  <img
                    className={styles['image-preview']}
                    src={configContext.getEndpoint(a.url.url).toString()}
                    alt="transaction"
                  />
                </div>
              ))}
            {Array.isArray(props.content.message) ? (
              props.content.message.map((i, idx) => {
                return <EntityVisual key={idx} content={i} />;
              })
            ) : (
              <EntityVisual content={props.content.message} />
            )}
          </div>
          <div className={styles['thread-item-buttons']}>
            {props.displayMarkRead && (
              <ButtonCheck className={styles['button']} onClick={() => markRead(props.content)} />
            )}
            {props.content.undoInterpreterKey && (
              <ButtonUndo
                className={classes(styles['button'], styles['button-undo'])}
                onClick={(e) => {
                  handleUndoClick(e, props.content.undoInterpreterKey);
                }}
              />
            )}
          </div>
        </div>
        <div className={styles['status-line']}>
          <div className={styles['timestamp']}>{getPrettyTimeString(props.content.timestamp)}</div>
          {isChatMessage(props.content) && props.content.sentByMyself && (
            <ThreadItemStatus
              className={styles['thread-item-status']}
              readConfirmation={props.content.readConfirmationStatus}
            />
          )}
        </div>
        {responseBlock}
      </div>
      <div className={styles['thread-item-border']}>
        {responseTimeout && responseDuration && (
          <CountdownTimer
            className={styles['countdown-timer']}
            timeout={responseTimeout}
            duration={responseDuration}
          />
        )}
        {isEscalation(props.content) && <EscalationIcon className={styles['symbol-escalation']} />}
      </div>
    </div>
  );
}

export function getResponseBlock(
  inform: InformProjectionVM,
  undoHandler: ((e: React.MouseEvent, undoInterpreterKey: string | undefined) => void) | undefined,
  displayResponseOptionsAnswers = true,
  displayFollowUpResponses = true,
  displayOpenResponseOptions = true
): [JSX.Element[] | undefined, number | undefined, number | undefined] {
  const responseOptions = inform.responseOptions;
  const sequenceId = inform.sequenceId;

  let responseBlock: JSX.Element[] | undefined = undefined;
  let responseTimeout: number | undefined = undefined;
  let responseDuration: number | undefined = undefined;

  if (responseOptions) {
    let firstUnanswered = true;
    for (let idx = 0, breakLoop = false; idx < responseOptions.length && !breakLoop; idx++) {
      let responseBlockItem: JSX.Element | undefined;
      const ros = responseOptions[idx];

      if (ros.response === undefined) {
        if (isFlaggedActive(inform)) {
          // Calculate timeout
          if (ros.relativeTimeout === 'Infinity' || ros.timeout === 'Infinity') {
            responseDuration = Number.POSITIVE_INFINITY;
            responseTimeout = Number.POSITIVE_INFINITY;
          } else if (ros.relativeTimeout) {
            if (!responseTimeout || !responseDuration) {
              responseDuration = ros.relativeTimeout;
              responseTimeout = ros.timeout;
            }
          }

          const enabledRO = firstUnanswered; /* Just to make the variable local */
          const responseOptions = ros.options.map((o, index) => {
            return (
              <ResponseOption
                index={index}
                enabled={enabledRO}
                id={o.id}
                text={o.text}
                key={sequenceId + ' ' + idx + ' ' + o.responseId}
                interpreterKey={o.addOptionsInterpreterKey}
                addOptionsMenu={o.addOptionsMenu}
                sequenceId={sequenceId}
                responseId={o.responseId}
                responseIndex={idx}
              />
            );
          });

          let styleDisabled = '';
          if (!firstUnanswered) {
            styleDisabled = styles['response-options-disabled'];
          } else firstUnanswered = false;

          responseBlockItem = (
            <div
              className={`${styles['response-options']} ${styleDisabled}`}
              key={sequenceId + ' ' + idx}
            >
              {responseOptions}
            </div>
          );
          if (!displayFollowUpResponses) breakLoop = true;
        }
      } else if (displayResponseOptionsAnswers === true) {
        // Display answer
        responseBlockItem = (
          <div className={styles['response']} key={sequenceId + ' ' + idx}>
            <div className={styles['response-content']}>
              <div>
                <EntityVisual content={ros.response} />
              </div>
              {ros.undoInterpreterKey && undoHandler && (
                <ButtonUndo
                  className={classes(styles['button'], styles['button-undo'])}
                  onClick={(e) => {
                    undoHandler?.(e, ros.undoInterpreterKey);
                  }}
                />
              )}
            </div>
            <div className={styles['timestamp']}>{getPrettyTimeString(ros.responseTimestamp)}</div>
          </div>
        );
      } else responseBlockItem = undefined;

      if (responseBlockItem)
        if (responseBlock === undefined) responseBlock = [responseBlockItem];
        else responseBlock.push(responseBlockItem);
    }
  }
  return [responseBlock, responseTimeout, responseDuration];
}

interface ResponseOptionProps {
  index: number;
  enabled: boolean;
  text: EntityHTML;
  id: string;
  sequenceId: number;
  responseId: number;
  responseIndex: number;
  interpreterKey?: string;
  addOptionsMenu?: SelectionMenu;
}
function ResponseOption(props: ResponseOptionProps) {
  const selContext = useContext(SelectionPageContext);
  const [contextMenuPosition, setContextMenuPosition] = useState<Position | undefined>(undefined);
  const dispatcher = useContext(OperationContext);
  const [buttonClicked, setButtonClicked] = useState(false);

  function handleResponseClick(e: MouseEvent) {
    e.stopPropagation();
    setButtonClicked(true);

    if (props.addOptionsMenu)
      selContext(props.addOptionsMenu, (ok: boolean) => {
        if (!ok) setButtonClicked(false);
      });
    else if (props.interpreterKey === undefined) {
      const op = new AddOperation<ResponseData>({
        id: props.id,
        sequenceId: props.sequenceId,
        responseIndex: props.responseIndex,
        responseId: props.responseId,
        timestamp: now(),
      });
      dispatcher.handle(op, 'communication-response');
    }
  }

  return (
    <HighlightButton
      secondary={props.index !== 0}
      animated={true}
      key={`${props.id}:${props.responseId}`}
      disabled={buttonClicked}
      onClick={(e) => {
        if (props.enabled) handleResponseClick(e);
      }}
    >
      <EntityVisual content={props.text} />
      {props.interpreterKey && contextMenuPosition && (
        <ContextMenu
          position={contextMenuPosition}
          hideContextMenu={() => {
            setContextMenuPosition(undefined);
          }}
        >
          <SimpleInterpreterMenu
            interpreterKey={props.interpreterKey}
            itemSelected={() => setContextMenuPosition(undefined)}
          />
        </ContextMenu>
      )}
    </HighlightButton>
  );
}

export default ThreadItem;
