import styles from './sqior-chart-block.module.css';
import { memo, useRef, useState } from 'react';
import SqiorBlock from '../sqior-block/sqior-block';
import { v4 as uuidv4 } from 'uuid';

const MIN_BLOCK_SIZE = 33;

interface Props<T> {
  data: T[];
  overflow?: T[];
  renderItem: (item: ExtendedChartData<T>) => React.ReactNode;
  width?: number;
  height?: number;
  noBorder?: boolean;
}

type ExtendedChartData<T> = ChartData & T;

export const SqiorChartBlock = <T,>({
  data,
  renderItem,
  overflow,
  width,
  height,
  noBorder,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({
    width: 250,
    height: 0,
  });

  const chartData = getChartData(data, dimensions.width, width ? width : MIN_BLOCK_SIZE);
  const overflowData = getOverflowData(overflow);
  //
  // useEffect(() => {
  //   const observeTarget = ref.current;
  //   if (!observeTarget) return;
  //
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     for (const entry of entries) {
  //       const entryWidth = entry.contentRect.width;
  //       const entryHeight = entry.contentRect.height;
  //
  //       if (entryWidth !== dimensions.width || entryHeight !== dimensions.height) {
  //         console.log({ entryWidth, dimensionWidth: dimensions.width });
  //         setDimensions({
  //           width: entryWidth,
  //           height: entryHeight,
  //         });
  //       }
  //     }
  //   });
  //
  //   resizeObserver.observe(observeTarget);
  //
  //   return () => {
  //     resizeObserver.unobserve(observeTarget);
  //   };
  // }, [dimensions]);

  return (
    <div ref={ref} className={styles['container']}>
      {chartData.map((item) => (
        <SqiorBlock
          key={item.id}
          width={width ? width : MIN_BLOCK_SIZE}
          height={height ? height : MIN_BLOCK_SIZE}
          borderLeft={noBorder === true ? undefined : item.borderLeft}
          borderTop={noBorder === true ? undefined : item.borderTop}
          borderRight={noBorder === true ? undefined : item.borderRight}
          borderBottom={noBorder === true ? undefined : item.borderBottom}
          borderTopLeftRadius={noBorder === true ? undefined : item.borderTopLeftRadius}
          borderBottomLeftRadius={noBorder === true ? undefined : item.borderBottomLeftRadius}
          borderTopRightRadius={noBorder === true ? undefined : item.borderTopRightRadius}
          borderBottomRightRadius={noBorder === true ? undefined : item.borderBottomRightRadius}
          borderFix={noBorder === true ? undefined : item.borderFix}
        >
          {renderItem(item)}
        </SqiorBlock>
      ))}
      {overflowData.map((item) => (
        <SqiorBlock
          key={item.id}
          width={width ? width : MIN_BLOCK_SIZE}
          height={height ? height : MIN_BLOCK_SIZE}
        >
          {renderItem(item)}
        </SqiorBlock>
      ))}
    </div>
  );
};

export default memo(SqiorChartBlock);

const getMaxBlocksInLine = (width: number, blockWidth: number) => {
  return Math.floor(width / blockWidth);
};

interface ChartData {
  id: string;
  row: number;
  column: number;

  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;

  borderTopLeftRadius?: boolean;
  borderTopRightRadius?: boolean;
  borderBottomLeftRadius?: boolean;
  borderBottomRightRadius?: boolean;

  borderFix?: boolean;
}

const getChartData = <T,>(
  data: T[],
  containerWidth: number,
  blockWidth: number
): ExtendedChartData<T>[] => {
  const maxBlocksInLine = getMaxBlocksInLine(containerWidth, blockWidth);
  const chartData: ExtendedChartData<T>[] = [];

  data.forEach((item, index) => {
    const row = Math.floor(index / maxBlocksInLine);
    const column = index % maxBlocksInLine;

    chartData.push({
      ...item,
      id: uuidv4(),
      row,
      column,
    });
  });

  chartData.forEach((item) => {
    item.borderTop = getBorderTop(chartData, item);
    item.borderRight = getBorderRight(chartData, item);
    item.borderBottom = getBorderBottom(chartData, item);
    item.borderLeft = getBorderLeft(chartData, item);
  });

  chartData.forEach((item) => {
    item.borderTopLeftRadius = item.borderTop && item.borderLeft;
    item.borderTopRightRadius = item.borderTop && item.borderRight;
    item.borderBottomLeftRadius = item.borderBottom && item.borderLeft;
    item.borderBottomRightRadius = item.borderBottom && item.borderRight;
    item.borderFix = getBorderFix(chartData, item);
  });

  return chartData;
};

const getOverflowData = <T,>(overflow?: T[]): ExtendedChartData<T>[] => {
  if (!overflow) return [];
  return overflow.map((item) => ({
    ...item,
    id: uuidv4(),
    row: 0,
    column: 0,
  }));
};

const getBorderLeft = (data: ChartData[], item: ChartData) => {
  return !data.find((_) => _.row === item.row && _.column === item.column - 1);
};

const getBorderTop = (data: ChartData[], item: ChartData) => {
  return !data.find((_) => _.row === item.row - 1 && _.column === item.column);
};

const getBorderRight = (data: ChartData[], item: ChartData) => {
  return !data.find((_) => _.row === item.row && _.column === item.column + 1);
};

const getBorderBottom = (data: ChartData[], item: ChartData) => {
  return !data.find((_) => _.row === item.row + 1 && _.column === item.column);
};

const getBorderFix = (data: ChartData[], item: ChartData) => {
  const rightNeibour = data.find((_) => _.row === item.row && _.column === item.column + 1);
  const bottomNeibour = data.find((_) => _.row === item.row + 1 && _.column === item.column);
  const bottomRightNeibour = data.find(
    (_) => _.row === item.row + 1 && _.column === item.column + 1
  );

  return rightNeibour && bottomNeibour && !bottomRightNeibour;
};
