import axios, { AxiosProgressEvent } from 'axios';
import {
  AddOperation,
  BasicOperationHandler,
  ContentTypes,
  DataResult,
  DeleteOperation,
  DeleteResult,
  ReadOperation,
  ReadResult,
} from '@sqior/js/operation';
import { IConfigContext } from '@sqior/js/url';
import {
  Bytes,
  NoTimestamp,
  Value,
  hasBinaries,
  transformPrimitives,
  writeMagic,
} from '@sqior/js/data';
import { IAuthContext } from '@sqior/js/authbase';

export class OperationRest extends BasicOperationHandler {
  constructor(urlConfig: IConfigContext, authContext?: IAuthContext, prefix = 'api/') {
    super();
    this.urlConfig = urlConfig;
    this.authContext = authContext;
    this.prefix = prefix;
  }

  override async addOp(op: AddOperation, path: string): Promise<DataResult> {
    /* Deconstruct the data separating meta data from the payload */
    let meta = op.data;
    const payload: { name: string; data: Blob }[] = [];
    if (hasBinaries(op.data)) {
      let serial = 1;
      meta = transformPrimitives(meta, (value) => {
        if (value instanceof Bytes) {
          const key = 'binary' + serial++;
          payload.push({ name: key, data: value.as<Blob>() });
          return writeMagic('binary', key);
        } else return value;
      }) as Value;
    }

    /* Prepare the form data */
    const formData = new FormData();
    formData.append('meta', JSON.stringify(meta));
    for (const pl of payload) formData.append(pl.name, pl.data);

    /* Create the final endpoint URL with the help of the configuration object */
    const uploadEndpoint = this.urlConfig.getEndpoint(this.prefix + path);
    let headers = {};
    headers = { ...headers, ...{ 'Content-Type': 'multipart/form-data' } };

    headers = { ...headers, ...(await this.authContext?.getAuthorizationHeader('dummy')) };

    /* Emit the actual REST call */
    const result = await axios.post(uploadEndpoint.href, formData, {
      headers: headers,
      onUploadProgress: (progEv: AxiosProgressEvent) => {
        op.setProgress(progEv.total ? (progEv.loaded / progEv.total) * 100 : 100);
      },
    });
    return [result.data.id, result.data.timestamp];
  }

  /** Loads an information element */
  override async readOp(op: ReadOperation, path: string): Promise<ReadResult> {
    /* Create the final endpoint URL with the help of the configuration object */
    const readEndpoint = this.urlConfig.getEndpoint(this.prefix + path + '/' + op.id);

    let headers = {};
    headers = { ...headers, ...(await this.authContext?.getAuthorizationHeader('dummy')) };

    /* Emit the actual REST call */
    const result = await axios.get<Value>(readEndpoint.href, {
      headers: headers,
    });
    const contentType = result.headers['Content-Type'];
    return [
      result.data,
      typeof contentType === 'string' ? contentType : ContentTypes.JSON,
      NoTimestamp,
    ];
  }

  /** Deletes an information element */
  override async deleteOp(op: DeleteOperation, path: string): Promise<DeleteResult> {
    /* Create the final endpoint URL with the help of the configuration object */
    const delEndpoint = this.urlConfig.getEndpoint(this.prefix + path + '?id=' + op.id);

    let headers = {};
    headers = { ...headers, ...(await this.authContext?.getAuthorizationHeader('dummy')) };

    /* Emit the actual REST call */
    const result = await axios.delete(delEndpoint.href, {
      headers: headers,
    });
    return result.data.timestamp;
  }

  private urlConfig: IConfigContext;
  private authContext?: IAuthContext;
  private prefix: string;
}
