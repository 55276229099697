export enum ZIndex {
  Base = 1,

  ProcedureBackground = 10,
  DashboardCurrentTimeLine = 15,
  DashboardProcedureContent = 99,
  DashboardTargetIcon = 100,

  SutureIncisionDuration = 150,

  PopupPageMainMessenger = 1000,
  BottomTabs = 999,

  /* Informational pages on top of messenger */
  PhoneNumberEntryPage = 1002,
  AppUpdatePage = 1003,

  DashboardVirtualScroll = 9999,
  SelectControlOverlay = 1000,
  ServerConnectionErrorPage = 9999,
  DashboardErrorConnectionShade = 9999,
}
