import { OperationContext } from '@sqior/react/operation';
import { useDynamicState } from '@sqior/react/state';
import { Checkbox, ClosablePage, HighlightButton, useTextResources } from '@sqior/react/uibase';
import {
  AddRole,
  RemoveRole,
  RoleSelectionItem,
  RoleSelectionStatePath,
  SetRoles,
} from '@sqior/viewmodels/user';
import { FactoryProps, factoryGetProp } from '@sqior/react/factory';
import { useContext } from 'react';
import styles from './role-selection-page.module.css';

/* eslint-disable-next-line */

export function RoleSelectionPage(props: FactoryProps) {
  const roles = useDynamicState<RoleSelectionItem[]>(RoleSelectionStatePath, []);
  let switchRoles: string[] = [];
  if (
    roles.findIndex((role) => {
      return !role.active;
    }) >= 0
  )
    switchRoles = roles.map((role) => {
      return role.id;
    });
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  return (
    <ClosablePage
      onClose={() => props.onClose?.(true, undefined)}
      className={factoryGetProp('className', props)}
    >
      <div className={styles['inner-container']}>
        <div className={styles['title']}>Rollenauswahl</div>
        <div className={styles['item']}>
          <HighlightButton
            onClick={() => {
              dispatcher.start(SetRoles(switchRoles));
            }}
          >
            {textDict.get(switchRoles.length > 0 ? 'select_all_roles' : 'deselect_all_roles')}
          </HighlightButton>
        </div>
        {roles.map((item) => (
          <div key={item.id} className={styles['item']}>
            <Checkbox
              checked={item.active}
              label={item.label}
              onChanged={(checked) => {
                dispatcher.start(checked ? AddRole(item.id) : RemoveRole(item.id));
              }}
            />
          </div>
        ))}
      </div>
    </ClosablePage>
  );
}

export default RoleSelectionPage;
