import styles from './blocked-card.module.css';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useEffect, useRef, useState } from 'react';
import { FactoryProps } from '@sqior/react/factory';
import {
  ORWorkflowDashboardBlockerData,
  ORWorkflowDashboardBlockerType,
} from '@sqior/viewmodels/orworkflow';
import { SIcon } from '@sqior/react/uibase';
import { Icons } from '@sqior/viewmodels/visual';

export type BlockedCardProps = FactoryProps<ORWorkflowDashboardBlockerData>;

export function BlockedCard({ data: { title, description, type } }: BlockedCardProps) {
  const [iconSize, setIconSize] = useState(60);
  const containerRef = useRef<HTMLDivElement>(null);

  const { height, width } = useResizeObserver({
    ref: containerRef,
  });

  useEffect(() => {
    const container = containerRef.current as HTMLDivElement;
    if (!container) return;
    const isOverflow = container.scrollHeight > container.clientHeight;

    if (isOverflow) {
      setIconSize(30);
    } else {
      setIconSize(60);
    }
  }, [height, width]);

  return (
    <div ref={containerRef} className={styles['container']}>
      <div className={styles['name-container']}>
        <div className={styles['title']}>{title}</div>
        {type === ORWorkflowDashboardBlockerType.Blocker && (
          <SIcon icon={Icons.Lock} size={28} color="#e0e0e0" />
        )}
      </div>

      <div className={styles[title ? 'description' : 'description-alone']}>{description}</div>
    </div>
  );
}

export default BlockedCard;
