import styles from './messenger.module.css';
import { useContext } from 'react';
import {
  AppVersionUpdatePage,
  PhoneNumberEntryPage,
  ServerConnectionPage,
  useConnectionError,
} from '@sqior/react/uiapp';
import { classes } from '@sqior/react/utils';
import { MessengerMainPage } from '../messenger-main-page/messenger-main-page';
import { AppStyle, useAppStyle, useDynamicState, useDynamicStateRaw } from '@sqior/react/state';
import MenuPage from '../menu-page/menu-page';
import { SelectionPageContext, SelectionStack, useSelectionStack } from '@sqior/react/uiselection';
import { OperationContext } from '@sqior/react/operation';
import {
  AppVersionUpdateData,
  AppVersionUpdatePath,
  ClosePage,
  MessengerTab,
} from '@sqior/viewmodels/app';
import { isMessengerMessengerTab, lastPage, useMessengerTabState } from '../messenger-tab-stack';
import { PhoneNumberEntryPath } from '@sqior/viewmodels/device';

export interface MessengerProps {
  className?: string;
  fullDashboard: boolean;
}

export function Messenger(props: MessengerProps) {
  const { appStyle } = useAppStyle();
  const dispatcher = useContext(OperationContext);
  const tabStack = useMessengerTabState();

  const connError = useConnectionError();

  function closePopupPage() {
    dispatcher.start(ClosePage());
  }

  /* Get the app version update state */
  const avus = useDynamicStateRaw<AppVersionUpdateData>(AppVersionUpdatePath);
  /* Get the phone number entry state, if this is in mobile phone layout */
  const phoneNumberEntry = useDynamicState(PhoneNumberEntryPath, false);

  const selectionStack = useSelectionStack();
  return (
    <SelectionPageContext.Provider
      value={(menu, onClose) => {
        selectionStack[0].add({ data: menu, cb: onClose });
      }}
    >
      <div className={classes(styles['messenger'], props.className)}>
        <MessengerMainPage fullDashboard={props.fullDashboard} tabStack={tabStack} />
        {isMessengerMessengerTab(lastPage(tabStack), MessengerTab.Menu) && (
          <MenuPage
            onClose={() => {
              closePopupPage();
            }}
          />
        )}
        {selectionStack[1].length > 0 && (
          <div className={styles['menu']}>
            <div className={styles['selection']}>
              <SelectionStack data={selectionStack} />
            </div>
          </div>
        )}
        {connError && <ServerConnectionPage />}
        {avus && <AppVersionUpdatePage data={avus} />}
        {appStyle === AppStyle.Messenger && phoneNumberEntry && <PhoneNumberEntryPage />}
      </div>
    </SelectionPageContext.Provider>
  );
}

export default Messenger;
