import { hasBinaries } from '@sqior/js/data';
import { Operation, OperationEmitter, OperationHandler } from '@sqior/js/operation';

export class OperationRestSwitch implements OperationHandler {
  constructor() {
    this.rest = new OperationEmitter();
    this.other = new OperationEmitter();
  }

  handle(op: Operation, path: string) {
    /* Operations with binaries are especially suited for REST */
    const value = op.toJSON();
    if (value && hasBinaries(value) && path === 'media') this.rest.operation.emit(op, path);
    else this.other.operation.emit(op, path);
  }

  rest: OperationEmitter;
  other: OperationEmitter;
}
