import { ColorCodes } from '@sqior/viewmodels/visual';
import { ContentColor, Theme } from './theme.types';

export type ContentColorsMap = {
  [key in ColorCodes]: ContentColor;
};

export const createContentColorMap = (theme: Theme): ContentColorsMap => {
  const {
    colors: { content },
  } = theme;
  return {
    [ColorCodes.SqiorBlue]: content.sqiorBlue,
    [ColorCodes.GreenOlive]: content.greenOlive,
    [ColorCodes.Purple]: content.purple,
    [ColorCodes.Red]: content.red,
    [ColorCodes.Blue]: content.blue,
    [ColorCodes.Peach]: content.peach,
    [ColorCodes.SteelBlue]: content.steelBlue,
    [ColorCodes.Cream]: content.cream,
    [ColorCodes.Cyan]: content.cyan,
    [ColorCodes.RiverBlue]: content.riverBlue,
    [ColorCodes.Green]: content.green,
    [ColorCodes.LightGreen]: content.lightGreen,
    [ColorCodes.Yellow]: content.yellow,
    [ColorCodes.Sand]: content.sand,
    [ColorCodes.Orange]: content.orange,
    [ColorCodes.Salmon]: content.salmon,
    [ColorCodes.Violet]: content.violet,
    [ColorCodes.Pink]: content.pink,
    [ColorCodes.LightPink]: content.lightPink,
    [ColorCodes.LightBrown]: content.lightBrown,
    [ColorCodes.EggShell]: content.eggshell,
    [ColorCodes.Brown]: content.brown,
    [ColorCodes.LightYellow]: content.lightYellow,
    [ColorCodes.DarkBlue]: content.darkBlue,
    [ColorCodes.LightOrange]: content.lightOrange,
    [ColorCodes.DarkGray]: content.darkGray,
    [ColorCodes.Gray]: content.gray,
    [ColorCodes.White]: content.gray,
    [ColorCodes.Teal]: content.teal,

    [ColorCodes.ScaleRG3Level1]: {
      border: content.greenOlive.border,
      bg: content.greenOlive.bg,
      fg: content.greenOlive.fg,
    },
    [ColorCodes.ScaleRG3Level2]: {
      border: content.yellow.border,
      bg: content.yellow.bg,
      fg: content.yellow.fg,
    },

    [ColorCodes.ScaleRG3Level3]: {
      border: content.red.border,
      bg: content.red.bg,
      fg: content.red.fg,
    },
  };
};
