import { classes } from '@sqior/react/utils';
import styles from './input-control.module.css';

/* eslint-disable-next-line */
export interface InputControlProps {
  className?: string;
  value: string;
  onChange: React.ChangeEventHandler;
  placeholder?: string;
  pattern?: string;
  type?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  confirm?: () => void;
}

export function InputControl(props: InputControlProps) {
  return (
    <input
      disabled={props.disabled}
      className={classes(styles['input'], props.className)}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      pattern={props.pattern}
      type={props.type}
      autoFocus={props.autoFocus}
      onKeyDown={(ev) => {
        if ((ev.key === 'Enter' || ev.key === 'Tab') && props.confirm) props.confirm();
      }}
    />
  );
}

export default InputControl;
