import styles from './analytic-dashboard-group.module.css';
import {
  AnalyticDashboardItem,
  AnalyticsDashboardGroupVM,
  CardSize,
} from '@sqior/viewmodels/analytics-dashboard';
import { useFactoryComponent } from '@sqior/react/factory';
import { ReactNode } from 'react';
import { useTheme } from '@sqior/react/theme';
import { ColorCodes } from '@sqior/viewmodels/visual';

export type AnalyticDashboardGroupProps = AnalyticsDashboardGroupVM;

export function AnalyticDashboardGroup({ title, color, items }: AnalyticDashboardGroupProps) {
  const FactoryComponent = useFactoryComponent();
  const {
    getContentColor,
    theme: {
      colors: {
        background: {
          borderGrey: { value: borderColor },
        },
      },
      shape: {
        borderRadius: { medium },
      },
    },
  } = useTheme();

  const contentColor = getContentColor(color);

  const renderItemRecursively = (item: AnalyticDashboardItem, keyPrefix = ''): ReactNode => {
    if (Array.isArray(item)) {
      // Render the array as a nested structure
      return (
        <>
          {item.map((nestedItem, nestedIndex) =>
            renderItemRecursively(nestedItem, `${keyPrefix}-${nestedIndex}`)
          )}
        </>
      );
    } else {
      return (
        <div
          key={keyPrefix}
          style={{
            flexGrow: item.size === CardSize.M ? 1 : 1,
          }}
        >
          <FactoryComponent data={item} noStyle />
        </div>
      );
    }
  };

  const getBackgroundColor = (color?: ColorCodes) => {
    if (!color) return undefined;
    const colorValue = contentColor.bg.value;
    return `linear-gradient(to right, ${colorValue} 0px, transparent 200px)`;
  };

  return (
    <div className={styles['container']}>
      <div
        className={styles['title']}
        style={{
          color: color ? contentColor.fg.value : undefined,
        }}
      >
        {title}
      </div>
      <div className={styles['cards-container']}>
        {items.map((row, rowIndex) => (
          <div
            className={styles['card-row']}
            key={`row-${rowIndex}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              background: getBackgroundColor(color),
              border: `1px solid ${borderColor}`,
              borderRadius: medium,
              padding: '10px',
            }}
          >
            {row.map((item, itemIndex) => renderItemRecursively(item, `${rowIndex}-${itemIndex}`))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnalyticDashboardGroup;
