import { longesCommonSubsequence } from '@sqior/js/data';
import { TemporaryText, TemporaryTextType } from '@sqior/viewmodels/input';

/** Represents a part of an input chain
 * The InputPart contains the entered visible text and a reference to the corresponding TemporaryText if applicable
 */
export type InputPart = {
  text: string; // The string representation
  item?: TemporaryText; // Either the matched TemporaryText or unknown to mark it as an filler
};

/** InputChain represents the complete input data of the input control
 */
export type InputChain = InputPart[];

/** Represents the cursor position within the InputChain
 */
export type CursorPos = {
  ipId: number; // Points to the InputPart of an INputChain
  curPos: number; // Points to the character of the text entry within an InputPart; Note the cursor position can be (much) beyond the actual text e.g. in case there is a suggestion
};

/** Creates a CursorPos object
 */
export function makeCurPos(ipId: number, curPos: number) {
  return { ipId: ipId, curPos: curPos };
}

export function curPosCompare(pos1: CursorPos | undefined, pos2: CursorPos | undefined) {
  if (pos1 === undefined && pos2 === undefined) return 0;
  else if (pos1 === undefined && pos2 !== undefined) return 1;
  else if (pos1 !== undefined && pos2 === undefined) return -1;
  else if (pos1 !== undefined && pos2 !== undefined) {
    if (pos1.ipId === pos2.ipId)
      return pos1.curPos === pos2.curPos ? 0 : pos1.curPos < pos2.curPos ? +1 : -1;
    else return pos1.ipId === pos2.ipId ? 0 : pos1.ipId < pos2.ipId ? +1 : -1;
  }

  return 0;
}

/** Represents an input state, a pair of InputChain and corresponding cursor position
 */
export type InputState = {
  input: InputChain;
  curPos?: CursorPos;
};
export function makeInputState(input: InputChain, curPos: CursorPos): InputState {
  return { input: input, curPos: curPos };
}

/** Returns the match part of each items joined with spaces
 */
export function temporaryTextJoinMatch(tempText: TemporaryText[]) {
  let textNew = '';
  for (const t of tempText) {
    if (textNew.length > 0) textNew += ' ';
    textNew = textNew + t.match;
  }
  return textNew;
}

/** Converts a TemporaryText with a string representation to a InputChain
 */
export function convertToInputChain(
  tempText: TemporaryText[],
  text: string,
  ignoreAfterFirstIncomplete: boolean,
  notBeyondText: boolean,
  cursorPos = 0
): [InputChain, CursorPos | undefined] {
  const inputChain: InputChain = [];

  let iLast = 0;
  let iTT = 0;
  let iTTText = 0;
  let curposTemp = cursorPos;
  let curPosRes: CursorPos | undefined = undefined;

  function addToInputChain(part: InputPart) {
    inputChain.push(part);

    curposTemp -= part.text.length;
    iTTText += part.text.length;
    if (curposTemp <= 0 && curPosRes === undefined)
      curPosRes = makeCurPos(inputChain.length - 1, curposTemp + part.text.length);
  }

  let stop = false;
  for (; iLast < text.length || iTT < tempText.length; ) {
    const tt: TemporaryText | undefined = stop ? undefined : tempText[iTT];

    if (tt === undefined) {
      addToInputChain({ text: text.slice(iLast), item: undefined });
      iLast = text.length;
      if (stop) break;
    } else {
      const iMatchStart = text.indexOf(tt.match);

      if (iLast < iMatchStart) {
        addToInputChain({ text: text.slice(iLast, iMatchStart), item: undefined });
        iLast = iMatchStart;
      } else if (iTT > 0) {
        // Add spacer if nothing else was added and this was not the first one
        addToInputChain({ text: ' ', item: undefined });
        iLast++;
      }

      // Add real TemporaryText item
      const inputItem = { text: tt.match, item: tt };
      addToInputChain(inputItem);

      stop = ignoreAfterFirstIncomplete && inputRequired(tt);

      iTT++;
      iLast += tt.match.length;
    }

    if (notBeyondText && iTTText >= text.length - 1) break;
  }

  if (cursorPos > 0 && curPosRes === undefined)
    curPosRes = makeCurPos(inputChain.length - 1, inputChain[inputChain.length - 1].text.length);

  return [inputChain, curPosRes];
}

/** Converts a TemporaryText with a string representation to a InputChain
 *  Additionally it tries to map the oldCursorPosition to a suitable position in the new InputChain
 *
 * @param icOld Previous InputChain
 * @param curPosOld Cursor position within icPosOld
 * @param tempText TemoraryText data to convert
 * @param text Corresponding text representation
 * @returns
 */
export function mergeLocalRemoteInputState(
  inputStateLocal: InputState,
  tempText: TemporaryText[],
  notBeyondLocalText: boolean
): [InputChain, CursorPos | undefined] {
  const [textLocal, cursorLocal] = convertToStringWithCursor(
    inputStateLocal.input,
    inputStateLocal.curPos
  );
  const textRemote = temporaryTextJoinMatch(tempText);

  // Calculate the LCS (longest common substring)
  const textDiff = longesCommonSubsequence(textLocal, textRemote);

  // Inspect the lcs and adjust cursor position accordingly
  let cursorNew = cursorLocal === undefined ? 0 : cursorLocal;
  let iLocal = 0,
    iRemote = 0,
    iDiff = 0;
  while (iLocal < textLocal.length || iRemote < textRemote.length) {
    if (textLocal[iLocal] !== textDiff[iDiff] && textRemote[iRemote] !== textDiff[iDiff]) {
      // Added & Deleted => no cursor adjustment needed
      iLocal++;
      iRemote++;
    } else if (textLocal[iLocal] === textDiff[iDiff] && textRemote[iRemote] === textDiff[iDiff]) {
      // Present in both => no cursor adjustment needed
      iLocal++;
      iRemote++;
      iDiff++;
    } else if (textLocal[iLocal] !== textDiff[iDiff]) {
      // Deleted
      iLocal++;
    } else if (textRemote[iRemote] !== textDiff[iDiff]) {
      // Added
      iRemote++;
      if (cursorLocal !== undefined && cursorLocal > iLocal)
        // Adjust cursor position if cursor is before the actual modified position
        cursorNew++;
    }
  }

  const [icNew, curPosNew] = convertToInputChain(
    tempText,
    textLocal,
    true,
    notBeyondLocalText,
    cursorNew
  );
  console.log('merge', tempText, textLocal, icNew);
  return [icNew, cursorLocal === undefined ? undefined : curPosNew];
}

function whitespacePressed(textOld: string, textNew: string, textDiff: string) {
  let iOld = 0,
    iNew = 0,
    iDiff = 0;
  let whiteSpacePressed = false;
  let additionalEditWhiteSpacePressed = false;
  while (iOld < textOld.length || iNew < textNew.length) {
    if (textOld[iOld] !== textDiff[iDiff] && textNew[iNew] !== textDiff[iDiff]) {
      if (textNew[iNew] === ' ') whiteSpacePressed = true;
      else additionalEditWhiteSpacePressed = true;
      iOld++;
      iNew++;
    } else if (textOld[iOld] === textDiff[iDiff] && textNew[iNew] === textDiff[iDiff]) {
      // Present in both => no cursor adjustment needed
      iOld++;
      iNew++;
      iDiff++;
    } else if (textOld[iOld] !== textDiff[iDiff]) {
      // Deleted
      additionalEditWhiteSpacePressed = true;
      iOld++;
    } else if (textNew[iNew] !== textDiff[iDiff]) {
      // Added
      if (textNew[iNew] === ' ') whiteSpacePressed = true;
      else additionalEditWhiteSpacePressed = true;

      iNew++;
    }
  }

  return whiteSpacePressed && !additionalEditWhiteSpacePressed;
}

export function applyEdits(
  inputStateLocal: InputState,
  textOld: string,
  textNew: string,
  spacePressed: () => boolean
): [InputChain | undefined, CursorPos | undefined] {
  const textDiff = longesCommonSubsequence(textOld, textNew);
  let iOld = 0,
    iNew = 0,
    iDiff = 0;
  let ic = inputStateLocal.input;
  let lastCurPosEdit = inputStateLocal.curPos;
  let curPosEdit = makeCurPos(0, 0);

  if (whitespacePressed(textOld, textNew, textDiff)) {
    if (spacePressed()) return [undefined, undefined];
  }

  while (iOld < textOld.length || iNew < textNew.length) {
    if (textOld[iOld] !== textDiff[iDiff] && textNew[iNew] !== textDiff[iDiff]) {
      ic = deleteText(ic, curPosEdit, +1)[0];
      [ic, lastCurPosEdit] = insertText(ic, curPosEdit, textNew[iNew]);

      iOld++;
      iNew++;
      curPosEdit = calculateNewCurPos(ic, curPosEdit, +1);
    } else if (textOld[iOld] === textDiff[iDiff] && textNew[iNew] === textDiff[iDiff]) {
      // Present in both => no cursor adjustment needed
      iOld++;
      iNew++;
      iDiff++;
      curPosEdit = calculateNewCurPos(ic, curPosEdit, +1);
    } else if (textOld[iOld] !== textDiff[iDiff]) {
      // Deleted
      [ic, lastCurPosEdit] = deleteText(ic, curPosEdit, +1);
      iOld++;
    } else if (textNew[iNew] !== textDiff[iDiff]) {
      // Added
      [ic, lastCurPosEdit] = insertText(ic, curPosEdit, textNew[iNew]);
      iNew++;
      curPosEdit = calculateNewCurPos(ic, curPosEdit, +1);
    }
  }

  return [ic, lastCurPosEdit];
}

export function findCurPos(ic: InputChain, refText: string, refPos: number): CursorPos {
  const icText = convertToString(ic);
  const diff = longesCommonSubsequence(icText, refText);

  let curPos = makeCurPos(0, 0);
  let icPtr = 0,
    refPtr = 0,
    diffPtr = 0;
  while ((icPtr < icText.length || refPtr < refText.length) && refPtr < refPos) {
    if (icText[icPtr] !== diff[diffPtr] && refText[refPtr] !== diff[diffPtr]) {
      icPtr++;
      curPos = calculateNewCurPos(ic, curPos, +1);
      refPtr++;
    } else if (icText[icPtr] === diff[diffPtr] && refText[refPtr] === diff[diffPtr]) {
      icPtr++;
      curPos = calculateNewCurPos(ic, curPos, +1);
      refPtr++;
      diffPtr++;
    } else if (icText[icPtr] !== diff[diffPtr]) {
      // Deleted
      icPtr++;
      curPos = calculateNewCurPos(ic, curPos, +1);
    } else if (refText[refPtr] !== diff[diffPtr]) {
      // Added
      refPtr++;
    }
  }

  return curPos;
}

/** Converts a InputChain to string representation
 */
export function convertToString(inputChain: InputChain): string {
  return convertToStringWithCursor(inputChain, undefined)[0];
}

/** Converts a InputChain to string representation including a cursor position
 */
export function convertToStringWithCursor(
  inputChain: InputChain,
  cursor: CursorPos | undefined
): [string, number | undefined] {
  let curStr = 0;
  let str = '';

  for (const [i, item] of inputChain.entries()) {
    str += item.text;

    if (cursor !== undefined) {
      if (i < cursor.ipId) curStr += item.text.length;
      else if (i === cursor.ipId) curStr += cursor.curPos;
    }
  }
  return [str, cursor === undefined ? undefined : curStr];
}

/** Inserts a suggestion
 * (i.e. the InputPart is replaced the inserted suggestion)
 *
 * @param input InputChain to modify
 * @param curPos Current cursor position
 * @param insert String representing the suggestion
 * @returns Updated InputChain and suitable CursorPos
 */
export function insertSuggestion(
  input: InputChain,
  curPos: CursorPos,
  insert: string
): [InputChain, CursorPos] {
  const res = input.slice(0, curPos.ipId);

  // Replace current item
  const curItem = input[curPos.ipId];
  const newItem = { item: curItem.item, text: insert };
  res.push(newItem);

  // Ensure at least one space is added as the succeeding items
  if (
    curPos.ipId + 1 >= input.length ||
    input[curPos.ipId + 1].text.length < 0 ||
    !input[curPos.ipId + 1].text.startsWith(' ')
  )
    res.push({ item: undefined, text: ' ' });

  // Just remember this cursor pos, final cursorpos is calculated at the end
  const taggedCurPos = makeCurPos(curPos.ipId, insert.length);

  // Append the rest
  res.push(...input.slice(curPos.ipId + 1));

  const newCurPos = calculateNewCurPos(res, taggedCurPos, +1);
  return [res, newCurPos];
}

/** Inserts a text (or character)
 *
 * @param input InputChain to modify
 * @param curPos Current cursor position
 * @param insert String representing the suggestion
 * @returns Updated InputChain and suitable CursorPos
 */
export function insertText(
  input: InputChain,
  curPos: CursorPos | undefined,
  insert: string
): [InputChain, CursorPos] {
  if (curPos === undefined) curPos = makeCurPos(0, 0);

  curPos = clipCurPos(input, curPos);

  if (insert !== ' ') {
    if (isImmediateBeforeSpacer(input, curPos)) {
      curPos = calculateNewCurAfterLastNonSpacer(input, curPos);
    } else if (isImmediateBehindSpacer(input, curPos)) {
      curPos = calculateNewCurBeforeNextNonSpacer(input, curPos);
    }
  }

  const res = input.slice(0, curPos.ipId);

  const tempText = curPos.ipId < input.length ? input[curPos.ipId].text : '';
  const tempTextNew = tempText.slice(0, curPos.curPos) + insert + tempText.slice(curPos.curPos);
  res.push({
    item: curPos.ipId < input.length ? input[curPos.ipId].item : undefined,
    text: tempTextNew,
  });

  res.push(...input.slice(curPos.ipId + 1));

  const newPos = calculateNewCurPos(res, curPos, insert.length);

  return [res, newPos];
}

/** Deletes characters from a InputChain
 *
 * @param input InputChain to modify
 * @param curPos Current cursor position
 * @param numChars Number of characters to delete;
 *                 If positive, characters are deleted to the right (Delete)
 *                 If negative characters are deleted to the left (Backspace)
 * @returns Updated InputChain and suitable CursorPos
 */
export function deleteText(
  input: InputChain,
  curPos: CursorPos,
  numChars: number
): [InputChain, CursorPos] {
  if (numChars === 0) return [input, curPos];

  let from: CursorPos, to: CursorPos;

  curPos = clipCurPos(input, curPos);

  if (numChars < 0) {
    from = calculateNewCurPos(input, curPos, numChars);
    to = curPos;
  } else {
    to = calculateNewCurPos(input, curPos, numChars);
    from = curPos;
  }

  // Part 1: Before from
  const res = [...input.slice(0, from.ipId)];

  // Part 2: Deleted part (might be partial InputParts)
  if (from.ipId === to.ipId) {
    const item = input[from.ipId];
    const text = item.text.slice(0, from.curPos) + item.text.slice(to.curPos);
    if (text) res.push({ text: text, item: item.item });
  } else {
    const item1 = input[from.ipId];
    const text1 = item1.text.slice(0, from.curPos);
    if (text1) res.push({ text: text1, item: item1.item });

    const item2 = input[to.ipId];
    const text2 = item2.text.slice(to.curPos);
    if (text2) res.push({ text: text2, item: item2.item });
  }
  // Part 3: After to
  if (to.ipId + 1 < input.length) res.push(...input.slice(to.ipId + 1));

  return [res, numChars < 0 ? from : curPos];
}

/** Clips the curPos part to the text length represented by the InputPart the cursor position (idPd) is pointing to */
export function clipCurPos(input: InputChain, curPos: CursorPos): CursorPos {
  if (curPos.ipId < input.length)
    return makeCurPos(curPos.ipId, Math.min(input[curPos.ipId].text.length, curPos.curPos));

  if (input.length > 0)
    return makeCurPos(input.length - 1, Math.max(0, input[input.length - 1].text.length));

  return makeCurPos(0, 0);
}

export function maxCurPos(input: InputChain): CursorPos {
  if (input.length > 0) return makeCurPos(input.length - 1, input[input.length - 1].text.length);

  return makeCurPos(0, 0);
}

/** Calculates a new cursor position
 *
 * @param input InputChain
 * @param curPos Current cursor position
 * @param diff Number of characters to move to left (negative) or right (positive)
 * @returns New cursor position
 */
export function calculateNewCurPos(input: InputChain, curPos: CursorPos, diff: number): CursorPos {
  // Clip Input Data - left and right border
  if (curPos.ipId < 0 || (curPos.ipId === 0 && curPos.curPos < 0))
    // Clip at front
    curPos = makeCurPos(0, 0);

  if (
    curPos.ipId >= input.length ||
    (curPos.ipId === input.length - 1 && curPos.curPos >= input[input.length - 1].text.length)
  )
    // Clip at end
    curPos = makeCurPos(input.length - 1, input[input.length - 1].text.length);

  if (diff === 0) return curPos;

  if (curPos.curPos + diff < 0) {
    // Move beyond current InputPart to the left
    if (curPos.ipId < 1) return makeCurPos(0, 0);
    else
      return calculateNewCurPos(
        input,
        makeCurPos(curPos.ipId - 1, input[curPos.ipId - 1].text.length),
        curPos.curPos + diff
      );
  } else if (curPos.curPos + diff > input[curPos.ipId].text.length) {
    if (curPos.ipId + 1 >= input.length)
      return makeCurPos(input.length - 1, input[input.length - 1].text.length);
    else
      return calculateNewCurPos(
        input,
        makeCurPos(curPos.ipId + 1, 0),
        diff - (input[curPos.ipId].text.length - curPos.curPos)
      );
  }

  return makeCurPos(curPos.ipId, curPos.curPos + diff);
}

/** Returns the closest InputPart to the cursor having an valid item associated
 *
 * @param input Input chain
 * @param curPos Current cursor position
 * @returns Found InputPart and assumed cursor position
 */
export function getClosestInputPartWithItem(
  input: InputChain,
  curPos: CursorPos
): [InputPart | undefined, CursorPos | undefined] {
  const curIP = input[curPos.ipId];

  if (curIP === undefined) return [undefined, undefined];

  if (curIP.item !== undefined) return [curIP, curPos];

  // Cursor is at front of current InputPart => return item from preceeding InputPart
  if (curPos.curPos === 0 && curPos.ipId > 0 && input[curPos.ipId - 1].item !== undefined)
    return [
      input[curPos.ipId - 1],
      makeCurPos(curPos.ipId - 1, input[curPos.ipId - 1].text.length),
    ];

  // Cursor is at end of current InputPart => return item from succeeding InputPart
  if (
    curPos.curPos >= curIP.text.length &&
    curPos.ipId < input.length - 1 &&
    input[curPos.ipId + 1].item !== undefined
  )
    return [input[curPos.ipId + 1], makeCurPos(curPos.ipId + 1, 0)];

  return [undefined, undefined];
}

/** Returns true if InputPart represents a spacer (i.e. InputPart containing one or more white spaces)
 */
export function isSpacer(inputPart: InputPart): boolean {
  return inputPart !== undefined && inputPart.text.length > 0 && inputPart.text.trim().length === 0; // Trim removes all whitespaces at begin/end => must be empty
}

/** Returns true if InputPart requires additional input (i.e. associated TemporaryText is not of type Fixed)
 */
export function inputRequired(input: InputPart | InputChain | TemporaryText): boolean {
  if (Array.isArray(input)) {
    for (const ip of input) if (inputRequired(ip)) return true;

    return false;
  } else if ('type' in input)
    // TemporyText
    return input.type !== TemporaryTextType.Fixed;
  else return input.item !== undefined && input.item.type !== TemporaryTextType.Fixed;
}

/** Returns true if cursor is immediately before an spacer
 */
export function isImmediateBeforeSpacer(input: InputChain, curPos: CursorPos): boolean {
  if (input[curPos.ipId] === undefined) return false;

  // At end of text of current IP (= within proposal text) => check successor
  if (
    !isSpacer(input[curPos.ipId]) &&
    input[curPos.ipId].text.length <= curPos.curPos &&
    input[curPos.ipId].text.length > 0
  )
    return isImmediateBeforeSpacer(input, makeCurPos(curPos.ipId + 1, 0));

  // Spacer and the curPos is not yet at the end of the spacer
  if (isSpacer(input[curPos.ipId]) && curPos.curPos < input[curPos.ipId].text.length) return true;

  return false;
}

/** Returns true if cursor is immediately behind an spacer
 */
export function isImmediateBehindSpacer(input: InputChain, curPos: CursorPos): boolean {
  if (input[curPos.ipId] === undefined) return false;

  // At front of text of current IP (= within proposal text) => check predecessor
  if (curPos.ipId > 0 && !isSpacer(input[curPos.ipId]) && curPos.curPos === 0)
    return isImmediateBehindSpacer(
      input,
      makeCurPos(curPos.ipId - 1, input[curPos.ipId - 1].text.length)
    );

  // Spacer and the curPos is not at the end of the spacer
  if (isSpacer(input[curPos.ipId]) && curPos.curPos === input[curPos.ipId].text.length) return true;

  return false;
}

/** Returns a new cursor position immediately behind the next spacer
 */
export function calculateNewCurPosAfterSpacer(input: InputChain, curPos: CursorPos): CursorPos {
  if (input[curPos.ipId] === undefined) return curPos;

  // Cursor at end of text of current IP (= within proposal text) => check successor
  if (input[curPos.ipId].text.length <= curPos.curPos && isSpacer(input[curPos.ipId + 1]))
    return calculateNewCurPosAfterSpacer(
      input,
      makeCurPos(curPos.ipId + 1, input[curPos.ipId + 1].text.length)
    );

  // Cursor within a spacer => try last position of this InputPart
  if (isSpacer(input[curPos.ipId]) && curPos.curPos < input[curPos.ipId].text.length)
    return calculateNewCurPosAfterSpacer(
      input,
      makeCurPos(curPos.ipId, input[curPos.ipId].text.length)
    );

  return curPos;
}

/** Returns a new cursor position immediately behind the next non-spacer
 */
export function calculateNewCurAfterLastNonSpacer(input: InputChain, curPos: CursorPos): CursorPos {
  if (input[curPos.ipId] === undefined) return curPos;

  let ipId = curPos.ipId;
  if (!isSpacer(input[ipId])) {
    if (curPos.curPos === 0 && input[ipId].text.length > 0 && ipId > 0) ipId--;
    else return curPos;
  }

  // Find previous non-spacer
  while (ipId > 0 && isSpacer(input[ipId])) {
    ipId--;
  }

  // Returns cursor at the end of the found item
  return makeCurPos(ipId, input[ipId].text.length);
}

/** Returns a new cursor position before the next non-spacer
 */
export function calculateNewCurBeforeNextNonSpacer(
  input: InputChain,
  curPos: CursorPos
): CursorPos {
  if (input[curPos.ipId] === undefined) return curPos;

  let ipId = curPos.ipId;
  if (!isSpacer(input[ipId])) {
    if (
      curPos.curPos >= input[ipId].text.length &&
      input[ipId].text.length > 0 &&
      ipId < input.length - 1
    )
      ipId++;
    else return curPos;
  }

  // Find next non-spacer
  while (ipId < input.length && isSpacer(input[ipId])) {
    ipId++;
  }

  if (ipId !== 0 && ipId === input.length) return makeCurPos(ipId - 1, input[ipId - 1].text.length);

  // Returns cursor at the begin of the found item
  return makeCurPos(ipId, 0);
}

/** Returns a new cursor position at the next position (to the right) requiring input (i.e. inputRequired() returns true)
 */
export function calculateNewCurAtNextInputRequired(
  input: InputChain,
  curPos: CursorPos | undefined
): CursorPos | undefined {
  if (curPos === undefined || input[curPos.ipId] === undefined) return curPos;

  let ipId = curPos.ipId;
  while (ipId < input.length && !inputRequired(input[ipId])) {
    ipId++;
  }

  if (input.length <= ipId) return curPos;

  return makeCurPos(ipId, input[ipId].text.length);
}
