import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import {
  ChecklistItemOverlay,
  ChecklistSectionOverlay,
  ChecklistViewModelType,
  ConfirmChecklistPath,
  SetChecklistPath,
} from '@sqior/viewmodels/checklist';
import { PatientOverviewPath } from '@sqior/viewmodels/patient';
import ChecklistControl from './checklist-control/checklist-control';

export function UIChecklistPlugin(context: FactoryContext & StateOverlayContext) {
  context.factory.add(ChecklistViewModelType, ChecklistControl);
  context.stateOverlays.add(SetChecklistPath, ChecklistItemOverlay, PatientOverviewPath);
  context.stateOverlays.add(ConfirmChecklistPath, ChecklistSectionOverlay, PatientOverviewPath);
}
