import { styled, Switch, SwitchProps } from '@mui/material';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    color: '#ffffff',
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      '& + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 0.3,
  },
}));

export function SwitchControl(props: SwitchProps) {
  return <MaterialUISwitch {...props} />;
}

export default SwitchControl;
