import { ZIndex } from '@sqior/react/utils';
import styles from './popup-page.module.css';

/* eslint-disable-next-line */
export interface PopupPageProps {
  children: React.ReactNode;
  className?: string;
  zIndex?: ZIndex;
}

export function PopupPage(props: PopupPageProps) {
  return (
    <div className={`${styles['container']} ${props.className}`} style={{ zIndex: props.zIndex }}>
      {props.children}
    </div>
  );
}

export default PopupPage;
