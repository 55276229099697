import { useEffect, useState } from 'react';
import styles from './pin-number.module.css';
import { useTimer } from '@sqior/react/state';
import { addSeconds } from '@sqior/js/data';

export interface PinNumberProps {
  num?: number;
}

export function PinNumber(props: PinNumberProps) {
  const { timer } = useTimer();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
    return timer.schedule(() => {
      setShow(false);
    }, addSeconds(1));
  }, [props.num, setShow, timer]);
  return (
    <div className={styles['container']}>
      {props.num && <div className={styles['number']}>{show ? props.num : '*'}</div>}
    </div>
  );
}

export default PinNumber;
