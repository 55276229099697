import { FocusEvent, useContext, useRef } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { ContainerWithShadow } from '@sqior/react/uibase';

import { ConversationContext } from '../conversation-context';
import InputPanel from '../input-panel/input-panel';
import Thread from '../thread/thread';
import InputProposalSelection, {
  InputProposalSelectionIF,
} from '../input-proposal-selection/input-proposal-selection';

import styles from './conversation-frame.module.css';
import { useInputControlLogic } from '../input-control/input-control-logic';
import { CommandCommunicationPath, ReadConfirmationTypes } from '@sqior/viewmodels/communication';

/* eslint-disable-next-line */
export interface ConversationFrameProps {
  onPhoto: () => void;
}

export function ConversationFrame(props: ConversationFrameProps) {
  const dispatcher = useContext(OperationContext);
  const ic = useInputControlLogic(dispatcher);
  const icLogic = ic.icLogic;

  const proposalRef = useRef<InputProposalSelectionIF>(null);
  icLogic.setReferenceToProposalSelection(proposalRef);

  function handleBlur(e: FocusEvent) {
    // Cancel input if user clicks outside of conversation frame
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // e.relatedTarget points to the element getting the focus => check whether outside of conversation frame
      icLogic.cancel();
    }
  }

  return (
    <ConversationContext.Provider value={ic.icLogic}>
      <div className={styles['conversation-frame']} onBlur={handleBlur}>
        <ContainerWithShadow className={styles['thread-area']}>
          <Thread
            stateBasePath={CommandCommunicationPath}
            autoMarkReadAs={ReadConfirmationTypes.Implicit}
          />
        </ContainerWithShadow>
        <InputPanel icLogic={icLogic} onPhoto={props.onPhoto} />
        {ic.editActive && (
          <InputProposalSelection
            className={styles['input-proposal-selection']}
            ref={proposalRef}
            enableKeyboardNavigation={true}
            part={ic.closesInputPart}
            itemSelected={(inputPart, text) => {
              icLogic?.insertSuggestion(inputPart, text);
            }}
          />
        )}
      </div>
    </ConversationContext.Provider>
  );
}

export default ConversationFrame;
