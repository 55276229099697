import { useEffect, useState } from 'react';
import styles from './foldable-text.module.css';

/* eslint-disable-next-line */
export interface FoldableTextProps {
  short?: string;
  long?: string;
  shortClassName: string;
  longClassName: string;
}

export function FoldableText(props: FoldableTextProps) {
  const [showLong, setShowLong] = useState(false);
  useEffect(() => {
    if (!props.short && !showLong) setShowLong(true);
    if (!props.long && showLong) setShowLong(false);
  }, [props, showLong]);

  const switchPossible = props.short && props.long && props.short !== props.long;
  return (
    <div
      className={`${styles['container']} ${showLong ? props.longClassName : props.shortClassName}`}
      onClick={() => {
        if (showLong && props.short) setShowLong(false);
        if (!showLong && props.long) setShowLong(true);
      }}
    >
      <span>{showLong ? props.long : props.short}</span>
      {switchPossible && !showLong && <span className={styles['dots']}>...</span>}
    </div>
  );
}

export default FoldableText;
