import { Bytes, hasBinaries, transformPrimitives, visit, writeMagic } from '@sqior/js/data';

/* Core message type */

export type Message = {
  type: string;
};

/* Composed message */

export type ComposedMessage = Message & { msgs: Message[] };
export const ComposedMessageType = 'composed';

export function composeMessages(...msgs: Message[]): ComposedMessage {
  return { type: ComposedMessageType, msgs: [...msgs] };
}

/* Definition of a message handler */
export interface MessageHandler<ResultType = void, Types extends unknown[] = []> {
  handle(msg: Message, ...params: Types): Promise<ResultType[]>;
}
export type MessageFunc<
  MessageType extends Message = Message,
  ResultType = void,
  Types extends unknown[] = []
> = (msg: MessageType, ...params: Types) => Promise<ResultType[]>;

/* Splitting messages into non-binary and binary parts */

export const BinaryMagic = writeMagic('binary');

export function splitMessage(msg: Message): [Message, Bytes[]] {
  const binaries: Bytes[] = [];
  /* Replace all binaries with a magic string and return them as a separate array */
  if (hasBinaries(msg))
    msg = transformPrimitives(msg, (value) => {
      if (value instanceof Bytes) {
        binaries.push(value);
        return BinaryMagic;
      } else return value;
    }) as Message;
  return [msg, binaries];
}

export class MessageRecombination {
  constructor() {
    this.msg = { type: '' };
    this.binaries = [];
    this.remainingBinaries = 0;
  }

  handle(obj: Message | Bytes) {
    /* Check if this is a string - this should always come first */
    if (!(obj instanceof Bytes)) {
      /* Warn if there were binaries outstanding */
      if (this.remainingBinaries > 0)
        throw new Error('Meta data received on web socket but binary data expected');
      /* Inspect message for binaries contained */
      this.msg = obj;
      this.remainingBinaries = 0;
      visit(this.msg, (value) => {
        if (value === BinaryMagic) this.remainingBinaries++;
      });
      /* Emit directly if no binaries contained - otherwise this needs to be stored until all binaries have arrived */
      if (this.remainingBinaries === 0) return this.msg;
    } else {
      /* Warn if there are no binaries outstanding */
      if (this.remainingBinaries === 0)
        throw new Error('Binary data received on web socket while meta data was expected');
      /* Add to list */
      this.binaries.push(obj);
      this.remainingBinaries--;
      /* If this was the last outstanding binary, recombine the message */
      if (this.remainingBinaries === 0) {
        let i = 0;
        this.msg = transformPrimitives(this.msg, (value) => {
          if (value === BinaryMagic) return this.binaries[i++];
          else return value;
        }) as Message;
        this.binaries = [];
        return this.msg;
      }
    }

    return undefined;
  }

  reset() {
    this.binaries = [];
    this.remainingBinaries = 0;
  }

  private msg: Message;
  private binaries: Bytes[];
  private remainingBinaries;
}
