import styles from './container-with-shadow.module.css';

/* eslint-disable-next-line */
export interface ContainerWithShadowProps {
  className?: string;
  children: React.ReactElement;
  noBottomShadow?: boolean;
  noTopShadow?: boolean;
  style?: React.CSSProperties;
}

export function ContainerWithShadow(props: ContainerWithShadowProps) {
  return (
    <div className={`${styles['container']} ${props.className}`} style={props.style}>
      {props.children}
      {!props.noTopShadow && <div className={styles['shadow-top']}></div>}
      {!props.noBottomShadow && <div className={styles['shadow-bottom']}></div>}
    </div>
  );
}

export default ContainerWithShadow;
