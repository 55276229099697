import styles from './contact-colleague-page.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { ClosablePage } from '@sqior/react/uibase';

export function ContactColleaguePage(props: FactoryProps) {
  // const patOverview = useDynamicState<PatientOverview>(PatientOverviewPath, {
  //   info: { entityType: '', name: '' },
  // });
  //
  // const content = patOverview.content;
  // const team = content?.team;
  return (
    <ClosablePage
      onClose={() => {
        props.onClose?.();
      }}
    >
      <div className={styles['daku']}>
        {/*{arraySafeLength(team) > 0 && <TeamHeader team={team} displayTeam={true} />}*/}
      </div>
    </ClosablePage>
  );
}

export default ContactColleaguePage;
