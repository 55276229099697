import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import styles from './app-version-update-page.module.css';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { AppVersionUpdateData, ConfirmAppVersionUpdate } from '@sqior/viewmodels/app';
import { now } from '@sqior/js/data';
import { ReactComponent as UpdateIcon } from './system-update.svg';
import { ZIndex } from '@sqior/react/utils';

/* eslint-disable-next-line */
export interface AppVersionUpdatePageProps {
  data: AppVersionUpdateData;
}

/** App informing on  */
export function AppVersionUpdatePage(props: AppVersionUpdatePageProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  return (
    <div className={styles['container']} style={{ zIndex: ZIndex.AppUpdatePage }}>
      <UpdateIcon className={styles['icon']} />
      <div className={styles['description']}>{textDict.get('new_app_version')}</div>
      <div className={styles['assistance']}>{props.data.description}</div>
      <div className={styles['instruction']}>{props.data.instruction}</div>
      {!props.data.force && (
        <HighlightButton
          additionalClassName={styles['button']}
          onClick={() => {
            dispatcher.start(ConfirmAppVersionUpdate(now()));
          }}
        >
          {textDict.get('later')}
        </HighlightButton>
      )}
      <div className={styles['bottom']} />
    </div>
  );
}

export default AppVersionUpdatePage;
