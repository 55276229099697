import styles from './react-sqior-ui.module.css';

export function ReactSqiorUi() {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactSqiorUi!</h1>
    </div>
  );
}

export default ReactSqiorUi;
