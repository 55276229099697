import { useContext, createContext, ReactNode, FC, useState, useCallback } from 'react';
import darkTheme from './DarkTheme';
import { ContentColor, Theme } from './theme.types';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { createContentColorMap } from './createContentColorMap';

const ThemeContext = createContext<Theme>(darkTheme);

export type ThemeVariant = 'light' | 'dark';

interface ThemeToggleContextProps {
  toggleTheme: () => void;
  currentVariant: ThemeVariant;
}

const ThemeToggleContext = createContext<ThemeToggleContextProps | undefined>(undefined);

export const useThemeToggle = (): ThemeToggleContextProps => {
  const context = useContext(ThemeToggleContext);
  if (!context) {
    throw new Error('useThemeToggle must be used within a ThemeToggleProvider');
  }
  return context;
};

interface UseThemeReturn {
  theme: Theme;
  getContentColor: (color?: ColorCodes) => ContentColor;
}

export const useTheme = (): UseThemeReturn => {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  const getContentColor = useCallback(
    (colorCode?: ColorCodes): ContentColor => {
      const contentColorsMap = createContentColorMap(theme);
      if (!colorCode) return contentColorsMap[ColorCodes.Red];
      const found = contentColorsMap[colorCode];
      if (!found) {
        return contentColorsMap[ColorCodes.Red];
      }
      return found;
    },
    [theme]
  );

  return {
    theme,
    getContentColor,
  };
};

interface ThemeProviderProps {
  children: ReactNode;
}

interface ThemeProviderProps {
  children: ReactNode;
  initialVariant?: ThemeVariant;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children, initialVariant = 'dark' }) => {
  const [variant, setVariant] = useState<ThemeVariant>(initialVariant); // Default to 'dark'

  const toggleTheme = useCallback(() => {
    setVariant((prev) => (prev === 'dark' ? 'light' : 'dark'));
  }, [setVariant]);

  const currentTheme = variant === 'dark' ? darkTheme : darkTheme;

  return (
    <ThemeContext.Provider value={currentTheme}>
      <ThemeToggleContext.Provider value={{ toggleTheme, currentVariant: variant }}>
        {children}
      </ThemeToggleContext.Provider>
    </ThemeContext.Provider>
  );
};
