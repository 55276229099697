import styles from './sqior-clock-progress.module.css';
import { FC, memo, useEffect, useRef, useState } from 'react';

const ACTIVE_COLOR = '#1BADE4';
const CROSS_SIZE = 2;

export interface SqiorClockProgressProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number; // 0-4
  crossColor?: string;
  fontSize?: number;
}

export const SqiorClockProgress: FC<SqiorClockProgressProps> = ({
  value,
  crossColor,
  fontSize,
  ...rest
}) => {
  if (value === undefined) return null;
  return (
    <div className={styles['container']} {...rest}>
      <RightTopBorder active={value >= 1} />
      <RightBottomBorder active={value >= 2} />
      <LeftBottomBorder active={value >= 3} />
      <LeftTopBorder active={value >= 4} />
      <Cross crossColor={crossColor} />
      <DisplayData number={value} fontSize={fontSize} />
    </div>
  );
};

export default memo(SqiorClockProgress);

export const LeftTopBorder: FC<{ active?: boolean }> = ({ active }) => {
  return <div className={styles['left-top-border']} style={injectActiveColor(active)} />;
};

export const RightTopBorder: FC<{ active?: boolean }> = ({ active }) => {
  return <div className={styles['right-top-border']} style={injectActiveColor(active)} />;
};

export const RightBottomBorder: FC<{ active?: boolean }> = ({ active }) => {
  return <div className={styles['right-bottom-border']} style={injectActiveColor(active)} />;
};

export const LeftBottomBorder: FC<{ active?: boolean }> = ({ active }) => {
  return <div className={styles['left-bottom-border']} style={injectActiveColor(active)} />;
};

const injectActiveColor = (active?: boolean): React.CSSProperties => {
  return active ? ({ '--border-color': ACTIVE_COLOR } as React.CSSProperties) : {};
};

const Cross: FC<{ crossColor?: string }> = ({ crossColor }) => {
  const crossRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={crossRef} className={styles['cross']}>
      <span
        className={styles['horizontal']}
        style={{ backgroundColor: crossColor, width: CROSS_SIZE }}
      />
      <span
        className={styles['vertical']}
        style={{ backgroundColor: crossColor, height: CROSS_SIZE }}
      />
    </div>
  );
};

const DisplayData: FC<{ number?: number; fontSize?: number }> = ({ number, fontSize }) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={divRef}
      className={styles['display-data']}
      style={{ fontSize: fontSize ? fontSize : 11 }}
    >
      {number}
    </div>
  );
};
