import { StopListening } from '@sqior/js/event';
import { CacheState, CacheStateType } from './cache-state';

export class CombinedCacheState extends CacheState {
  /** Combines a set of cache states */
  static combine(...states: (CacheState | undefined)[]) {
    const mutableStates: CacheState[] = [];
    /* Determine the cache type for this */
    let cacheType = CacheStateType.Valid;
    for (const cs of states)
      if (!cs) continue;
      else if (!cs.valid) {
        cs.decRef();
        cacheType = CacheStateType.Invalid;
      } else if (cs.closed && cacheType !== CacheStateType.Invalid) {
        mutableStates.push(cs);
        cacheType = CacheStateType.Closable;
      } else if (cs.invalidated && cacheType !== CacheStateType.Invalid) {
        mutableStates.push(cs);
        if (cacheType !== CacheStateType.Closable) cacheType = CacheStateType.Dynamic;
      } else cs.decRef();
    /* Check the result */
    if (cacheType === CacheStateType.Valid) return undefined;
    if (cacheType !== CacheStateType.Invalid)
      if (mutableStates.length === 1) return mutableStates[0];
      else return new CombinedCacheState(cacheType, mutableStates);
    /* Remove all remaining refs */
    for (const cs of mutableStates) cs.decRef();
    return new CacheState(CacheStateType.Invalid);
  }

  private constructor(cacheType: CacheStateType, states: CacheState[]) {
    super(cacheType);
    /* Listen to all cache invalidations */
    for (const state of states)
      this.subStates.push({
        state,
        stop: state.invalidated?.on(() => {
          this.invalidate();
        }),
      });
  }

  private closeAll() {
    for (const cacheState of this.subStates) {
      cacheState.stop?.();
      cacheState.state.decRef();
    }
    this.subStates = [];
  }

  protected override close() {
    this.closeAll();
    super.close();
  }

  override invalidate() {
    this.closeAll();
    super.invalidate();
  }

  private subStates: { state: CacheState; stop?: StopListening }[] = [];
}
