import { ReleaseTreatmentPath, TreatmentPathItemViewModel } from '@sqior/viewmodels/treatment-path';
import styles from './treatment-path.module.css';
import TreatmentPathItem from '../treatment-path-item/treatment-path-item';
import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { Entity } from '@sqior/js/entity';

export interface TreatmentPathProps {
  patient: Entity;
  path: TreatmentPathItemViewModel[];
  changed: boolean;
}

export function TreatmentPath(props: TreatmentPathProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  return (
    <div className={styles['container']}>
      {props.path.map((item, index) => (
        <TreatmentPathItem
          key={index}
          model={item}
          topLine={index > 0}
          bottomLine={index + 1 < props.path.length}
        />
      ))}
      <div className={styles['button']}>
        <HighlightButton
          disabled={!props.changed}
          onClick={() => {
            dispatcher.start(ReleaseTreatmentPath(props.patient));
          }}
        >
          {textDict.get('grant_permission')}
        </HighlightButton>
      </div>
    </div>
  );
}

export default TreatmentPath;
