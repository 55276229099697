import { TokenHandler } from './token-handler';
import { UserInfo } from './user-info';

export type HttpAuthHeader = { authorization?: string };
export type BaseAuthServicesContext = { authServices?: IAuthServices };

export enum TokenValidResult {
  Valid = 'valid',
  InValid = 'invalid',
  MissingToken = 'missing',
  Expired = 'expired',
  MissingScope = 'missing-scope',
  InternalError = 'internal error',
}

export interface IAuthServices {
  tokenValid(token: string): Promise<TokenValidResult>;
  validateTokenHeader(header: HttpAuthHeader): Promise<TokenValidResult>;
  getUserInfo(token: string): Promise<UserInfo | undefined>;
  getTokenHandler(expCallback: () => void): TokenHandler;
}

export abstract class BearerTokenAuthServices implements IAuthServices {
  abstract tokenValid(token: string): Promise<TokenValidResult>;
  abstract getUserInfo(token: string): Promise<UserInfo | undefined>;
  abstract getTokenHandler(expCallback: () => void): TokenHandler;

  async validateTokenHeader(header: HttpAuthHeader): Promise<TokenValidResult> {
    const token = BearerTokenAuthServices.extractTokenFromHeader(header);
    if (token !== undefined) {
      return await this.tokenValid(token);
    }
    return TokenValidResult.MissingToken;
  }

  static extractTokenFromHeader(header: HttpAuthHeader): string | undefined {
    const authHeader = header.authorization;
    if (authHeader?.startsWith('Bearer ')) {
      const token = authHeader?.substring('Bearer '.length, authHeader?.length);
      return token;
    }
    return undefined;
  }
}
