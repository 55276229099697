import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

export function useScrollPosition() {
  const contentRef = useRef<Scrollbars>(null);

  const [freeze, setFreeze] = useState(true);

  useEffect(() => {
    if (!freeze) return;

    setTimeout(() => {
      setFreeze(false);
    }, 300);
  }, []);

  const scrollToPosition = (element: HTMLDivElement) => {
    setFreeze(true);
    if (!contentRef.current) return;

    const container = contentRef.current;
    if (!container) return;

    setTimeout(() => {
      const elementRect = element.getBoundingClientRect();
      const { clientHeight, scrollTop, top } = container.getValues();

      // Calculate the element's top position relative to the container's top
      const elementOffset = elementRect.top - top;

      // Calculate the scroll offset to center the element
      const scrollOffset =
        elementOffset + scrollTop - clientHeight / 2 + elementRect.height / 2 - 75;

      container.scrollTop(scrollOffset);

      setTimeout(() => {
        setFreeze(false);
      }, 300);
    }, 100);
  };

  return { contentRef, scrollToPosition, freeze };
}
