import { FactoryProps } from '@sqior/react/factory';
import styles from './EmergencyClassificationInfo.module.css';
import {
  EmergencyClassificationInfoVM,
  EmergencyClassificationVM,
} from '@sqior/viewmodels/orworkflow';

export type EmergencyClassificationInfoProps = FactoryProps<EmergencyClassificationInfoVM>;

export function EmergencyClassificationInfo(props: EmergencyClassificationInfoProps) {
  const style = getEmergencyTextStyles(props.data);

  return (
    <div className={styles['container']} style={style}>
      {props.data.category.toUpperCase()}
    </div>
  );
}

export default EmergencyClassificationInfo;
const EMERGENCY_BASE_COLOR = '245, 93, 93';

const getEmergencyTextStyles = (emergencyClassification: EmergencyClassificationVM | undefined) => {
  if (!emergencyClassification) return undefined;
  return {
    color: getEmergencyColorWithOpacity(emergencyClassification.opacity),
  };
};

const getEmergencyColorWithOpacity = (opacity: undefined | number = 1): string => {
  return `rgba(${EMERGENCY_BASE_COLOR}, ${opacity})`;
};
