import { useEffect } from 'react';

type EscapeHandler = () => void;

export const useEscape = (onEscape: EscapeHandler) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        onEscape();
      }
    };

    // Add event listener for 'keydown'
    document.addEventListener('keydown', handleKeydown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [onEscape]);
};

export default useEscape;
