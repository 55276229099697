import { AnimationProvider, UIGlobalStateProvider } from '@sqior/react/state';
import styles from './main-frame.module.css';

/* eslint-disable-next-line */
export interface MainFrameProps {
  children: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export function MainFrame(props: MainFrameProps) {
  return (
    <AnimationProvider>
      <div className={styles['main-frame']}>
        <div className={styles['children']}>{props.children}</div>
      </div>
    </AnimationProvider>
  );
}

export default MainFrame;
