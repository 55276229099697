import { Operation } from '@sqior/js/operation';
import { PatientOverview } from '@sqior/viewmodels/patient';
import { ORWorkflowIncidentData } from './orworkflow-incident-vm';
import { ValueInspectionStateOverlay } from '@sqior/js/state-operation';
import { HierarchicalMultiSelection, SelectionEntities } from '@sqior/viewmodels/input';
import { shallowClone } from '@sqior/js/data';

export function ORWorkflowIncidentOverlay(op: Operation) {
  return ValueInspectionStateOverlay<ORWorkflowIncidentData, PatientOverview>(
    op,
    { info: { entityType: '', name: '' } },
    (overview, op) => {
      /* Check type of content, disable if no longer a multi-selection */
      if (overview.content?.entityType !== SelectionEntities.ChallengeHierarchicalMultiSelection)
        return true;
      /* Try to find the incident */
      const multiSelEnt = overview.content as HierarchicalMultiSelection;
      for (const group of multiSelEnt.groups)
        for (const entry of group.options)
          if (entry.value['incident'] === op.incident) return entry.selected === op.selected;
      return true;
    },
    (overview, op) => {
      if (overview.content?.entityType !== SelectionEntities.ChallengeHierarchicalMultiSelection)
        return overview;
      /* Try to find the incident */
      overview = shallowClone(overview);
      const multiSelEnt = (overview.content = shallowClone(
        overview.content as HierarchicalMultiSelection
      ));
      const groups = (multiSelEnt.groups = [...multiSelEnt.groups]);
      for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        const options = groups[groupIndex].options;
        for (let optionsIndex = 0; optionsIndex < options.length; optionsIndex++) {
          const entry = options[optionsIndex];
          if (entry.value['incident'] === op.incident) {
            if (entry.selected === op.selected) return overview;
            const newGroup = (groups[groupIndex] = shallowClone(groups[groupIndex]));
            const newOptions = (newGroup.options = [...options]);
            const newEntry = (newOptions[optionsIndex] = shallowClone(entry));
            newEntry.selected = op.selected;
          }
        }
      }
      return overview;
    }
  );
}
