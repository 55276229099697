import { Emitter } from '@sqior/js/event';
import { useStateOnce } from '@sqior/react/hooks';
import { FacingMode } from '@sqior/react/qrcode';
import { HighlightButton, TitlePage, useTextResources } from '@sqior/react/uibase';
import { useRef } from 'react';
import QRVideoControl from '../qrvideo-control/qrvideo-control';
import styles from './camera-page.module.css';

export interface CameraPageProps {
  title: string;
  defaultFacing: FacingMode;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  onClose: () => void;
}

export function CameraPage(props: CameraPageProps) {
  const textDict = useTextResources();
  const [takePhoto] = useStateOnce(() => {
    return new Emitter();
  });
  const facing = useRef(props.defaultFacing);
  const acquired = useRef(false);

  return (
    <TitlePage title={props.title} onClose={props.onClose} className={styles['page']}>
      <QRVideoControl
        onClose={props.onClose}
        takePhoto={takePhoto}
        onPhoto={props.onPhoto}
        facing={facing.current}
        onFacing={(f) => {
          facing.current = f;
        }}
      />
      <div className={styles['bottombar']}>
        <HighlightButton
          disabled={acquired.current}
          onClick={() => {
            acquired.current = true;
            setTimeout(() => {
              takePhoto.emit();
            }, 10);
          }}
        >
          {textDict.get(acquired.current ? 'processing_image' : 'take_photo')}
        </HighlightButton>
      </div>
    </TitlePage>
  );
}

export default CameraPage;
