import {
  IntervalHistogramChart,
  MetricCardStats,
  ScalarStatsPerGroupChart,
  SliderVM,
} from './types';
import { ProcessGraph } from './process-graph';

export type WorkflowAnalyticsDashboardSelectedStatsVM = {
  details?: {
    edgeName: string;
    duration?: string;
    count: number;
    countLabel: string;
    percentage?: number;
  };
  statsPerGroup?: ScalarStatsPerGroupChart;
  history?: MetricCardStats;
  histogram?: IntervalHistogramChart;
};

export type WorkflowAnalyticsDashboardVM = {
  // Controls
  edgeSlider: SliderVM;
  // UI Misc
  isCalculating?: boolean;
  // Data
  count?: number; // Number of documents
  graph?: ProcessGraph;
  selectedEdgeId: string[];
  selectedNodeId: string[];
  selectedStats?: WorkflowAnalyticsDashboardSelectedStatsVM;
};

export type WorkflowAnalyticsDashboardStateOperationData = {
  addUnknownNodes?: boolean;
  filterEdgesBelowCount?: number;
};

export enum WorkflowAnalyticsDashboardOpSubPaths {
  SelectEdge = 'SelectEdge',
  SelectNode = 'SelectNode',
  SelectTimeRange = 'SelectTimeRange',
  SelectSpecialty = 'SelectSpecialty',
  SelectWard = 'SelectWard',
  SelectRoom = 'SelectRoom',
  SelectIncident = 'SelectIncident',
  SelectFilter = 'SelectFilter',
  SelectDashboard = 'SelectDashboard',
}
