import { classes } from '@sqior/react/utils';
import { KeyboardEvent } from 'react';
import styles from './expandable-textarea.module.css';
import { newlineToBr } from '../utils';

/* eslint-disable-next-line */
export interface ExpandableTextareaProps {
  className?: string;
  classNameEdit?: string;
  value: string;
  onInput: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: KeyboardEvent) => void;
}

/* The textarea from HTML is not able to auto-increase depending on the text entered. Thus, the trick is to render a replicate in the same way as the textarea but expands in height.
 */
export function ExpandableTextarea(props: ExpandableTextareaProps) {
  function onInputChanged(e: React.FormEvent<HTMLTextAreaElement>) {
    props.onInput(e);
  }
  function onKeyDown(e: KeyboardEvent) {
    props.onKeyDown(e);
  }

  return (
    <div className={classes(props.className, styles['container'])}>
      <div className={classes(props.classNameEdit, styles['common'], styles['replica'])}>
        {newlineToBr(props.value)}
      </div>
      <textarea
        rows={1}
        onInput={onInputChanged}
        value={props.value}
        className={classes(props.classNameEdit, styles['common'], styles['edit'])}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

export default ExpandableTextarea;
