import { classes } from '@sqior/react/utils';
import { FC } from 'react';
import styles from './button.module.css';

/* eslint-disable-next-line */
export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  additionalClassName?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

export const Button: FC<ButtonProps> = ({ additionalClassName, className, children, ...rest }) => {
  return (
    <button className={classes(className, additionalClassName, styles['button'])} {...rest}>
      {children}
    </button>
  );
};

export default Button;
