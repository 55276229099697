import { AuthConfig, UserInfoType } from '@sqior/js/authbase';
import { OAuthProvider } from './oauth-provider';
import { OidcClient } from './oidc-client';

export class OpenIdProvider extends OAuthProvider<OidcClient> {
  override isLoggedIn(user: string): boolean {
    return user === (this.client?.idToken ? this.user : '');
  }
  override get user(): string | undefined {
    return this.client?.user;
  }

  override createClient(config: AuthConfig, appUrl: string): OidcClient {
    return new OidcClient({
      issuer: config.sqiorAuthBaseUrl,
      client_id: config.sqiorAuthClientId,
      // extraAuthUrlParams: config.sqiorAuthRealm,
      appUrl: appUrl,
    });
  }

  override async initializeClient(token: string, refreshToken: string): Promise<boolean> {
    return this.client?.init(token, refreshToken, this.getScopesAsString()) ?? false;
  }

  override get typeName() {
    return 'OpenId';
  }

  override get userInfo(): UserInfoType {
    const userProfile = this.client?.userClaims.claims;
    if (userProfile) {
      const name = userProfile.name ?? `${userProfile.given_name} ${userProfile.family_name}`;
      return {
        name: name,
        picture: userProfile.picture,
        userId: userProfile.sub,
      };
    }
    return {};
  }
}
