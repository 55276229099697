import { Value, ValueOrNothing } from '@sqior/js/data';
import { Message } from '@sqior/js/message';
import { OperationStateJSON } from '@sqior/js/operation';

export enum OperationMessageType {
  Request = 'op.request',
  Progress = 'op.progress',
  State = 'op.state',
  Stream = 'op.stream',
}

export interface OperationRequestMessage extends Message {
  opType: string;
  id: number;
  path: string;
  data?: Value;
}

export interface OperationProgressMessage extends Message {
  id: number;
  progress: number;
}

export interface OperationStateMessage extends Message {
  id: number;
  data: OperationStateJSON;
}

export interface OperationStreamMessage extends Message {
  id: number;
  data: ValueOrNothing;
}
