import { LogLevel } from './log-data';
import { LogTextData, TextLogBackend } from './text-log-backend';

export enum LogColor {
  Grey = '\x1b[30;1m',
  Red = '\x1b[31m',
  StrongRed = '\x1b[31;1m',
  Green = '\x1b[32m',
  Yellow = '\x1b[33m',
  BrightYellow = '\x1b[33;1m',
  Blue = '\x1b[34;1m',
  Magenta = '\x1b[35m',
  BrightMagenta = '\x1b[35;1m',
  Cyan = '\x1b[36m',
  BrightCyan = '\x1b[36;1m',
}

export type ConsoleLogOptions = { colorize?: boolean };

export class ConsoleLogger extends TextLogBackend {
  constructor(options: ConsoleLogOptions = {}) {
    super();
    this.options = options;
  }
  logText(msg: LogTextData) {
    const text = this.buildLogText(msg);
    if (text.length > 0) console.log(text);
  }

  protected buildLogText(msg: LogTextData) {
    if (msg.text.length === 0) return msg.text;
    let text = '';
    switch (msg.level) {
      case LogLevel.Fatal:
        text = this.colorize('FATAL:', LogColor.StrongRed) + ' ' + msg.text;
        break;
      case LogLevel.Critical:
        text = this.colorize('ERROR:', LogColor.Red) + ' ' + msg.text;
        break;
      case LogLevel.Warning:
        text = this.colorize('WARN:', LogColor.BrightYellow) + ' ' + msg.text;
        break;
      case LogLevel.Info:
        text = msg.text;
        break;
      case LogLevel.Debug:
        text = this.colorize('-- ' + msg.text, LogColor.Grey);
        break;
      case LogLevel.Trace:
        text = this.colorize('---- ' + msg.text, LogColor.Grey);
        break;
    }
    return text;
  }

  static colorizeText(text: string, color: LogColor) {
    return color + text + '\x1b[0m';
  }

  static decolorizeText(text: string) {
    return text.replace(
      // eslint-disable-next-line no-control-regex
      /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
      ''
    );
  }

  private colorize(text: string, color: LogColor) {
    if (this.options.colorize) return ConsoleLogger.colorizeText(text, color);
    return text;
  }

  private options: ConsoleLogOptions;
}
