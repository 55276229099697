import { OperationSpec, OperationType } from '@sqior/js/operation';

export type TimeRange = {
  id: string;
  label: string;
  start: number;
  end: number;
};

export type TimeRangeSelectionVM = {
  options: TimeRange[];
  selectedRange: {
    start?: number;
    end?: number;
  };
  selectedId?: string;
};

export type SelectTimeRangeSelection = {
  selectedRange?: {
    start?: number;
    end?: number;
  };
  selectedId?: string;
};

export function SelectTimeRange(
  path: string,
  selection: string | { start?: number; end?: number }
): OperationSpec<SelectTimeRangeSelection> {
  const data: SelectTimeRangeSelection = {};
  if (typeof selection === 'string') data.selectedId = selection;
  else {
    data.selectedRange = {};
    if (selection.start !== undefined) data.selectedRange.start = selection.start;
    if (selection.end !== undefined) data.selectedRange.end = selection.end;
  }
  return {
    type: OperationType.Add,
    path: path,
    data: data,
  };
}
