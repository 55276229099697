import {
  WorkflowPathModel,
  WorkflowPathStepLabelType,
  WorkflowPathStepModel,
  WorkflowPathStepState,
} from '@sqior/viewmodels/workflow';
import WorkflowPathStep from '../workflow-path-step/workflow-path-step';
import styles from './workflow-path.module.css';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface WorkflowPathProps {
  path: WorkflowPathModel;
  className?: string;
  scrollToPosition?: (element: HTMLDivElement) => void;
}

function checkTopLine(steps: WorkflowPathStepModel[], index: number) {
  /* Check if there are only buttons on top */
  let allButtons = true;
  for (let i = index - 1; i >= 0; i--)
    if (
      steps[i].label.type === WorkflowPathStepLabelType.Text ||
      steps[i].state === WorkflowPathStepState.Todo
    ) {
      allButtons = false;
      break;
    }
  if (allButtons) return false;
  /* Check if there are only buttons on bottom */
  allButtons = true;
  for (let i = index; i < steps.length; i++)
    if (
      steps[i].state === WorkflowPathStepState.Todo ||
      (steps[i].state !== WorkflowPathStepState.Comment &&
        steps[i].label.type === WorkflowPathStepLabelType.Text)
    ) {
      allButtons = false;
      break;
    }
  if (allButtons) return false;
  /* Check if there is a problem directly before or only separated by comments */
  for (let i = index - 1; i >= 0; i--)
    if (steps[i].state === WorkflowPathStepState.Problem) return false;
    else if (steps[i].state !== WorkflowPathStepState.Comment) break;
  return index + 1 < steps.length || steps[index].state !== WorkflowPathStepState.Comment;
}
function checkActiveTop(steps: WorkflowPathStepModel[], index: number) {
  for (let i = index - 1; i >= 0; i--)
    if (steps[i].state === WorkflowPathStepState.Todo) return false;
    else if (steps[i].state === WorkflowPathStepState.Done) return true;
  return false;
}
function checkBottomLine(steps: WorkflowPathStepModel[], index: number) {
  /* Check if there are only buttons on bottom */
  let allButtons = true;
  for (let i = index + 1; i < steps.length; i++)
    if (
      steps[i].state === WorkflowPathStepState.Todo ||
      (steps[i].state !== WorkflowPathStepState.Comment &&
        steps[i].label.type === WorkflowPathStepLabelType.Text)
    ) {
      allButtons = false;
      break;
    }
  if (allButtons) return false;
  return (
    index + 1 < steps.length &&
    steps[index].state !== WorkflowPathStepState.Problem &&
    (index + 2 < steps.length || steps[index + 1].state !== WorkflowPathStepState.Comment)
  );
}

export function WorkflowPath(props: WorkflowPathProps) {
  const [fold, setFold] = useState<Record<string, boolean>>({});
  const steps: WorkflowPathStepModel[] = [];
  /* Filter away pot. folded steps */
  let filter = false;
  for (let stepIndex = 0; stepIndex < props.path.steps.length; stepIndex++) {
    const step = props.path.steps[stepIndex];
    /* Check for foldable elements if a step is done */
    if (step.state === WorkflowPathStepState.Done) {
      filter = step.foldable ? fold[step.id] ?? true : false;
      steps.push(step);
      continue;
    } else if (step.state === WorkflowPathStepState.Todo) filter = false;
    /* Take over step unless it is supposed to be filtered */
    if (!filter || step.label.type === WorkflowPathStepLabelType.Button) steps.push(step);
  }
  /* Add spacer step where required */
  for (let index = 0; index + 1 < steps.length; index++)
    if (
      steps[index + 1].label.type === WorkflowPathStepLabelType.Button &&
      steps[index + 1].group !== steps[index].group &&
      steps[index].label.type !== WorkflowPathStepLabelType.Button
    ) {
      steps.splice(index + 1, 0, {
        id: 'spacer' + index,
        group: '',
        state: WorkflowPathStepState.Comment,
        label: { type: WorkflowPathStepLabelType.Text, text: '' },
      });
      index++;
    }
  return (
    <div className={`${styles['container']} ${props.className}`}>
      {steps.map((item, index) => (
        <WorkflowPathStep
          scrollToPosition={props.scrollToPosition}
          key={item.id}
          model={item}
          topLine={checkTopLine(steps, index)}
          activeTop={checkActiveTop(steps, index)}
          bottomLine={checkBottomLine(steps, index)}
          timestamp={props.path.timestamp}
          folded={item.foldable ? fold[item.id] ?? true : undefined}
          setFold={(f) => {
            setFold({ ...fold, [item.id]: f });
          }}
        />
      ))}
    </div>
  );
}

export default WorkflowPath;
