import styles from './messenger-header-action-buttons.module.css';
import HighlightButton from '../highlight-button/highlight-button';

/* eslint-disable-next-line */
export interface MessengerHeaderActionButtonsProps {
  onClose?: () => void;
  onConfirm?: () => void;
  confirmDisabled?: boolean;
  closeLabel?: string;
  confirmLabel?: string;
}

export function MessengerHeaderActionButtons({
  onClose,
  onConfirm,
  confirmDisabled,
  closeLabel = 'Abbrechen',
  confirmLabel = 'Weiter',
}: MessengerHeaderActionButtonsProps) {
  return (
    <div className={styles['header']}>
      <div>
        <HighlightButton
          secondary
          additionalClassName={styles['header-button']}
          onClick={() => onClose?.()}
          style={{
            padding: '5px 10px',
          }}
        >
          {closeLabel}
        </HighlightButton>
      </div>

      <div>
        <HighlightButton
          disabled={confirmDisabled}
          additionalClassName={styles['header-button']}
          onClick={() => onConfirm?.()}
          style={{
            padding: '5px 10px',
          }}
        >
          {confirmLabel}
        </HighlightButton>
      </div>
    </div>
  );
}

export default MessengerHeaderActionButtons;
