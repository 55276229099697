import { LanguageTextResourceMap } from '@sqior/js/language';
import {
  ConsoleLogger,
  ErrorReportingMode,
  LogLevel,
  ProxyLogger,
  SetLogger,
  StdLogger,
} from '@sqior/js/log';
import { Dispatcher, DispatcherContext } from '@sqior/js/operation';
import { PluginContext, Plugins } from '@sqior/js/plugin';
import { State } from '@sqior/js/state';
import { StateOverlayContext, StateOverlayMap } from '@sqior/js/state-operation';
import { Factory, FactoryContext } from '@sqior/react/factory';
import { InterweaveExtContext, InterweaveExtFactory } from '@sqior/react/uibase';
import { LogPath, LogState, NativeDeviceLogger, StorageLogRetainer } from '@sqior/web/log';
import { ViewportState } from '@sqior/web/utils';

/** Creates the default context object for UI applications */

export function createUIAppContext(
  webSocketName: string,
  clientTextResources: LanguageTextResourceMap
) {
  const context: PluginContext &
    DispatcherContext &
    FactoryContext &
    StateOverlayContext &
    InterweaveExtContext & { clientTextResources: LanguageTextResourceMap } = {
    plugins: new Plugins(),
    dispatcher: new Dispatcher(),
    factory: new Factory(),
    interweaveFactory: new InterweaveExtFactory(),
    stateOverlays: new StateOverlayMap(),
    clientTextResources,
  };

  /* Keep logs in memory and in WebStorage until they can be send out */
  const logRetainer = new StorageLogRetainer();
  /* Construct proxy logger, logging to console, to the native device and to a state */
  const logState = new LogState();
  SetLogger(
    new StdLogger(
      { level: LogLevel.Debug, validate: false, errorReportingMode: ErrorReportingMode.Log },
      new ProxyLogger([new ConsoleLogger(), logRetainer, new NativeDeviceLogger(), logState])
    )
  );

  /* Define app state */
  const appState = new State();
  appState.map('viewport', new ViewportState());
  /* Log to a state */
  appState.map(LogPath, logState.state);

  return { ...context, appState, logRetainer, webSocketName };
}
