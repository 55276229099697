import { InformProjectionVM, mainInfoContains } from '@sqior/viewmodels/communication';
import MainInfoItemEmpty from '../main-info-item-empty/main-info-item-empty';
import './main-info.css';
import { ComponentFactory } from '@sqior/react/factory';
import { useContext } from 'react';

/* eslint-disable-next-line */
export interface MainInfoProps {
  items: InformProjectionVM[];
}

export function MainInfo(props: MainInfoProps) {
  const FactoryComponent = useContext(ComponentFactory);

  const mainInfoItems = [];
  for (const item of props.items) {
    if (!mainInfoContains(item)) continue;

    mainInfoItems.push(<FactoryComponent key={item.id} data={item} />);
  }

  if (mainInfoItems.length === 0) mainInfoItems.push(<MainInfoItemEmpty key={'manual1'} />);

  return <div className={'main-info-container'}>{mainInfoItems}</div>;
}

export default MainInfo;
