import { writeMagic } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';

export class Temporary {
  /* Returns the temporary object associated with the value */
  static get(value: string) {
    return this.temps[value];
  }

  /* Check if the provided string is consistent with a temporary value */
  static is(value: string) {
    return this.get(value) !== undefined;
  }

  /* Constructor creates a new temporary value and registers it */
  constructor() {
    this.tempValue = writeMagic('temp', (Temporary.number++).toString());
    this.final = new Emitter<[string | undefined]>();
    Temporary.temps[this.tempValue] = this;
  }

  /* Returns the current value */
  get current() {
    return this.finalValue ? this.finalValue : this.tempValue;
  }

  /* Sets the final value for the temporary */
  set(final: string) {
    delete Temporary.temps[this.tempValue];
    this.finalValue = final;
    this.final.emit(final);
  }

  /* Cancels the temporary - it will never get final */
  cancel() {
    delete Temporary.temps[this.tempValue];
    this.final.emit(undefined);
  }

  readonly tempValue: string;
  readonly final: Emitter<[string | undefined]>;
  finalValue?: string;

  private static temps: Record<string, Temporary> = {};
  private static number = 1;
}
