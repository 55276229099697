import { useLayoutEffect, useState } from 'react';

interface MousePosition {
  x: number;
  y: number;
}

interface MousePositionDelta extends MousePosition {
  dx: number;
  dy: number;
}

interface UseMousePositionOptions {
  includeTouch: boolean;
}

export const useMousePosition = ({ includeTouch }: UseMousePositionOptions): MousePositionDelta => {
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });
  const [mousePositionDelta, setMousePositionDelta] = useState<MousePositionDelta>({
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
  });

  useLayoutEffect(() => {
    const updateMousePosition = (ev: globalThis.MouseEvent) => {
      handleEvent(ev.clientX, ev.clientY);
    };

    const updateTouchPosition = (ev: TouchEvent) => {
      const touch = ev.touches[0];
      handleEvent(touch.clientX, touch.clientY);
    };

    const handleEvent = (x: number, y: number) => {
      const dx = x - mousePosition.x;
      const dy = y - mousePosition.y;
      setMousePositionDelta({ x, y, dx, dy });
      setMousePosition({ x, y });
    };

    window.addEventListener('mousemove', updateMousePosition);
    if (includeTouch) {
      window.addEventListener('touchmove', updateTouchPosition);
    }

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      if (includeTouch) {
        window.removeEventListener('touchmove', updateTouchPosition);
      }
    };
  }, [includeTouch, mousePosition]);

  return mousePositionDelta;
};

export default useMousePosition;
