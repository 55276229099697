import { State } from '@sqior/js/state';
import { LanguageTextResourceManager } from './language-text-resource-manager';
import { LanguageCode } from './text-resource-map';

export class TextResourceState {
  constructor(textResources = new LanguageTextResourceManager()) {
    this.textResources = textResources;
    this.state = new State();
  }

  setLanguage(lang: LanguageCode) {
    this.state.set({
      lang: lang,
      resources: this.textResources.getMap(lang),
    });
  }

  readonly textResources: LanguageTextResourceManager;
  readonly state: State;
}
