import {
  ChecklistOptionSentiment,
  ChecklistParagraphViewModel,
  ChecklistSetData,
} from '@sqior/viewmodels/checklist';
import ChecklistItemControl from '../checklist-item-control/checklist-item-control';
import styles from './checklist-paragraph-control.module.css';
import { useEffect, useState } from 'react';
import { SvgIconExpandLess, SvgIconExpandMore } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { AnimatePresence, motion } from 'framer-motion';

export type ChecklistParagraphControlProps = {
  data: ChecklistParagraphViewModel;
  select: (item: number, data: ChecklistSetData) => void;
  first: boolean;
  readOnly: boolean;
  completed: boolean;
  initFold?: boolean;
  fold?: boolean;
  setFold?: (state: boolean) => void;
};

export function ChecklistParagraphControl(props: ChecklistParagraphControlProps) {
  /* Check if there are uncompleted items, in this no folding is allowed */
  const allOK = !props.data.items.find((item) => {
    for (const option of item.options)
      if (option.checked) return option.sentiment === ChecklistOptionSentiment.Negative;
    return true;
  });

  const checkedCount = props.data.items.filter((item) => {
    return item.options.find((opt) => {
      return opt.checked;
    });
  }).length;

  const negative = props.data.items.find((item) => {
    for (const option of item.options)
      if (option.checked && option.sentiment === ChecklistOptionSentiment.Negative) return true;
    return false;
  });
  /* Paragraph is folded by default if it is completed */
  const [localFold, setLocalFold] = useState(props.initFold ?? props.completed);

  const fold = props.fold ?? localFold;
  const setFold = props.setFold ?? setLocalFold;

  useEffect(() => {
    if (props.completed) setFold(true);
  }, [props.completed, setFold]);
  return (
    <div className={styles['container']}>
      <div className={styles['header-outer-container']}>
        <div
          className={styles['header-container']}
          onClick={() => {
            setFold(!fold);
          }}
        >
          <div className={styles['header-text-container']}>
            <span
              className={classes(
                styles['header'],
                allOK && fold
                  ? styles['completed-text']
                  : negative && fold
                  ? styles['challenged-text']
                  : undefined
              )}
            >
              {props.data.header}
            </span>
            {!props.completed && (
              <span className={styles['header-count']}>
                {'(' + checkedCount + '/' + props.data.items.length + ')'}
              </span>
            )}
          </div>
          {fold ? (
            <SvgIconExpandMore className={styles['more-button']} />
          ) : (
            <SvgIconExpandLess className={styles['less-button']} />
          )}
        </div>
        {!fold && <div className={styles['shadow-bottom']} />}
      </div>
      <AnimatePresence>
        {!fold &&
          props.data.items.map((item, index) => (
            <motion.div
              animate={{
                opacity: 1,
                y: 0,
              }}
              initial={{
                opacity: 0,
                y: -10,
              }}
              exit={{
                opacity: 0,
                y: -10,
              }}
              key={index}
            >
              <ChecklistItemControl
                data={item}
                readOnly={props.readOnly}
                select={(data) => {
                  if (!props.readOnly) {
                    if (fold) setFold(false);
                    props.select(index, data);
                  }
                }}
              />
            </motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
}

export default ChecklistParagraphControl;
