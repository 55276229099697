import { useContext, useEffect } from 'react';
import styles from './confirm-login-control.module.css';
import { EntityHeader } from '@sqior/js/entity';
import { AuthConfirmMessageType } from '../auth-interop';
import { AuthContext } from '../auth-context';
import { Logger } from '@sqior/js/log';

export interface ConfirmLoginControlProps {
  confirm: (token: string) => void;
}

export function ConfirmLoginControl(props: ConfirmLoginControlProps) {
  const authContext = useContext(AuthContext);
  /* Listen for messages from the sub-frame confirming the identity */
  const userId = authContext.provider.userInfo.userId;
  useEffect(() => {
    const evListener = (ev: MessageEvent<EntityHeader & { token: string; userId: string }>) => {
      /* Make sure the message originates from our own iFrame */
      if (ev.origin !== window.location.origin) return;
      /* Check the message type */
      if (ev.data.entityType === AuthConfirmMessageType) {
        /* Check if the confirmed user is identical to the logged in user, otherwise reload as the cookies have changed! */
        if (userId !== ev.data.userId) {
          Logger.info('Reloading because user identity changed during confirmation');
          window.location.reload();
        }
        /* Emit the confirmation function, if applicable and deactivate the pop-up */
        Logger.info('User identity was reconfirmed');
        props.confirm(ev.data.token);
      }
    };
    window.addEventListener('message', evListener);
    return () => {
      window.removeEventListener('message', evListener);
    };
  }, [props, userId]);
  return (
    <iframe
      src={window.location.href}
      title="Identität bestätitigen"
      className={styles['confirm-iframe']}
    ></iframe>
  );
}

export default ConfirmLoginControl;
