import SvgButton from '../svg-button/svg-button';
import { ReactComponent as Logo } from './button-check.svg';

/* eslint-disable-next-line */
export interface ButtonCheckProps {
  onClick?: () => void;
  className?: string;
}

export function ButtonCheck(props: ButtonCheckProps) {
  return (
    <SvgButton className={props.className} onClick={props.onClick}>
      <Logo fill="#ffffff" viewBox="0 0 40 40" />
    </SvgButton>
  );
}

export default ButtonCheck;
