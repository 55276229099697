import { useOutsideAlerter } from '@sqior/react/hooks';
import { useEffect, useRef, useState } from 'react';
import styles from './context-menu.module.css';

/* eslint-disable-next-line */
export type Position = { x: number; y: number };
export interface ContextMenuProps {
  position?: Position; // Position where to display (global coordinates)
  children: React.ReactNode;
  hideContextMenu: () => void;
}

type RenderPosition = { top?: string; bottom?: string; left?: string; right?: string };

export function ContextMenu(props: ContextMenuProps): JSX.Element {
  const refContextMenu = useRef<HTMLElement>(null);
  useOutsideAlerter(refContextMenu, () => {
    props.hideContextMenu();
  });

  const [renderPos, setRenderPos] = useState<object | undefined>(undefined);
  // Transform coordinates to local coordinates
  useEffect(() => {
    let position = props.position;
    if (position) {
      const upperHalf = position.y < window.innerHeight / 2;
      const leftHalf = position.x < window.innerWidth / 2;
      const clientRect = refContextMenu.current?.getBoundingClientRect();
      if (clientRect) {
        position = {
          x: position.x - clientRect.left,
          y: position.y - clientRect.top,
        };

        const renderPosition: RenderPosition = {};
        if (upperHalf) renderPosition.top = `${position.y}px`;
        else renderPosition.bottom = `${position.y}px`;
        if (leftHalf) renderPosition.left = `${position.x}px`;
        else renderPosition.right = `${-position.x}px`;

        setRenderPos(renderPosition);
      }
    }
  }, [props.position, refContextMenu]);

  return (
    <span ref={refContextMenu} className={styles['context-menu-anker']} tabIndex={1}>
      <div
        className={styles['context-menu-content']}
        style={renderPos ? renderPos : { top: 0, left: 0 }}
      >
        {props.children}
      </div>
    </span>
  );
}

export default ContextMenu;
