import { FactoryProps } from '@sqior/react/factory';
import { BedAllocationWardListItemEntity } from '@sqior/viewmodels/occupancy';
import styles from './bed-allocation-ward-list-item.module.css';
import PillView from '../pill-view/pill-view';

export type BedAllocationWardListItemProps = FactoryProps<BedAllocationWardListItemEntity>;

export function BedAllocationWardListItem(props: BedAllocationWardListItemProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['label']}>{props.data.label}</div>
      <div className={styles['beds']}>
        {props.data.beds.map((bed) => (
          <PillView key={bed.id} bed={bed} />
        ))}
      </div>
    </div>
  );
}

export default BedAllocationWardListItem;
