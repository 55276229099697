import { OperationHandler } from './handler';
import { Operation, OperationState } from './operation';
import { OperationEmitter } from './operation-emitter';

export class TemporaryQueue extends OperationEmitter implements OperationHandler {
  constructor() {
    super();
    this.ops = [];
  }

  handle(op: Operation, path = '') {
    /* Check if this can be emitted immediately, this is the case if the content
       of the operation is already final and there is no temporary one in the queue */
    if (this.ops.length === 0 && op.contentFinal) this.operation.emit(op, path);
    else {
      /* Register for content status changes */
      op.contentChange.on((final) => {
        if (final) this.flush();
      });

      /* Register for status changes to be notified if the operation fails because
         a temporary was cancelled */
      op.stateChange.on((state) => {
        if (state === OperationState.Failed) {
          this.ops = this.ops.filter((o) => {
            return o[0] !== op;
          });
          this.flush();
        }
      });

      /* Remember */
      this.ops.push([op, path]);
    }
  }

  private flush() {
    /* Check if there are one or more operations that can be emitted */
    while (this.ops.length > 0 && this.ops[0][0].contentFinal) {
      const item = this.ops.shift();
      if (item) this.operation.emit(item[0], item[1]);
    }
  }

  private ops: [Operation, string][];
}
