import { useDynamicState } from '@sqior/react/state';
import { LocationData } from '@sqior/viewmodels/location';
import {
  ActiveLocationPath,
  AddLocation,
  AvailableLocationsPath,
  RemoveLocation,
} from '@sqior/viewmodels/user';
import ActiveItemsSelection from '../active-items-selection/active-items-selection';

/* eslint-disable-next-line */
export interface ActiveLocationSelectionProps {
  description: string;
}

export function ActiveLocationSelection(props: ActiveLocationSelectionProps) {
  const activeLocations = useDynamicState<LocationData>(ActiveLocationPath, []);
  const availableLocations = useDynamicState<LocationData>(AvailableLocationsPath, []);

  return (
    <ActiveItemsSelection
      activeItems={activeLocations}
      availableItems={availableLocations}
      addOperation={AddLocation}
      removeOperation={RemoveLocation}
      title={'qr_room_title'}
      description={props.description}
      defaultSelectionText={'qr_room_add_room'}
    />
  );
}

export default ActiveLocationSelection;
