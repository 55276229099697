import styles from './orworkflow-list-item.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { InterweaveExt } from '@sqior/react/uibase';
import { memo, useContext } from 'react';
import { ORWorkflowListItemBase } from '@sqior/viewmodels/orworkflow';
import { ItemState } from '@sqior/viewmodels/visual';
import ORWorkflowIndicatorControl from '../orworkflow-indicator-control/orworkflow-indicator-control';
import { useSearchMatch } from '@sqior/react/hooks';
import { useUIGlobalState } from '@sqior/react/state';
import { motion } from 'framer-motion';

export type ORWorkflowListItemProps = FactoryProps<ORWorkflowListItemBase>;

export const ORWorkflowListItem = memo(({ data, onClose, statePath }: ORWorkflowListItemProps) => {
  const {
    emergencyClassification,
    prioGroup,
    patientState,
    patient,
    description,
    diagnosis,
    select,
    step,
    indicator,
    location,
    searchKeys,
  } = data;

  const {
    UIGlobalState: { searchQuery },
  } = useUIGlobalState();
  const selected = useSearchMatch({ searchKeys, searchQuery });

  const dispatcher = useContext(OperationContext);
  const emergencyCategory = emergencyClassification?.category;

  const onClick = () => {
    if (select) dispatcher.start(select);
  };

  const getOpacity = () => {
    if (searchQuery.length === 0) {
      return 1;
    } else if (selected) {
      return 1;
    } else {
      return 0.5;
    }
  };

  return (
    <motion.div
      layout="position"
      className={styles['main-container']}
      animate={{
        borderColor: selected ? '#1cade4' : 'rgba(0, 0, 0, 0)',
        opacity: getOpacity(),
      }}
      style={{
        borderRadius: '10px',
        border: '1px solid rgba(0, 0, 0, 0)',
      }}
    >
      <div className={styles['container']} onClick={onClick}>
        <div className={styles['patient-container']}>
          {emergencyCategory && (
            <span className={styles['emergency']}>
              <b>{emergencyCategory.toUpperCase()}</b>
            </span>
          )}
          {prioGroup && (
            <span
              className={styles[prioGroup.state === ItemState.Challenged ? 'challenge' : 'normal']}
            >
              {prioGroup.label}
            </span>
          )}
          <InterweaveExt
            className={
              styles[
                patientState === ItemState.Challenged
                  ? 'challenge'
                  : patientState === ItemState.Highlighted
                  ? 'highlight'
                  : patientState === ItemState.Greyed
                  ? 'greyed'
                  : patientState === ItemState.Hot
                  ? 'hot'
                  : 'normal'
              ]
            }
            content={patient}
          />
        </div>
        {step && <InterweaveExt content={step} />}
        {diagnosis && <div>{diagnosis}</div>}
        {description && <div>{description}</div>}
        {location && <InterweaveExt content={location} />}
      </div>
      <div className={styles['icon-state']}>
        {indicator && <ORWorkflowIndicatorControl indicator={indicator} />}
      </div>
    </motion.div>
  );
});

export default ORWorkflowListItem;
