import styles from './device-list.module.css';
import { RemovePairing, UserDeviceListVM } from '@sqior/viewmodels/app';
import { v4 as uuidv4 } from 'uuid';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { TextResourceAccess } from '@sqior/js/language';
import { HighlightButton, SqiorChip, SvgIphone } from '@sqior/react/uibase';
import { format } from 'date-fns';

export interface DeviceListProps {
  data: UserDeviceListVM;
  textResources: TextResourceAccess;
}

const DEVICE_ICON = 30;

const STATUS_COLORS = {
  active: {
    color: 'rgba(66,208,63,0.8)',
    backgroundColor: 'rgba(66,208,63,0.2)',
  },
  inactive: {
    color: 'rgba(255,192,0,0.8)',
    backgroundColor: 'rgba(255,192,0,0.1)',
  },
};

export function DeviceList({ data, textResources }: DeviceListProps) {
  const dispatcher = useContext(OperationContext);

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>{textResources.get('paired_devices')}</div>
      <div className={styles['devices-container']}>
        {data.devices.map((device) => (
          <div key={uuidv4()} className={styles['device']}>
            <div className={styles['flex-row']}>
              <div className={styles['gray']}>
                <SvgIphone style={{ width: DEVICE_ICON, height: DEVICE_ICON }} />
              </div>

              <div className={styles['infos']}>
                <div className={styles['name-container']}>
                  <div className={styles['device-name']}>{device.customDeviceName}</div>

                  <div>
                    <SqiorChip
                      fontSize={12}
                      label={
                        device.active ? textResources.get('active') : textResources.get('inactive')
                      }
                      {...STATUS_COLORS[device.active ? 'active' : 'inactive']}
                    />
                  </div>
                </div>
                <div className={styles['device-generic']}>{device.genericDeviceName}</div>

                <div className={styles['sub-title']}>{`${textResources.get(
                  'device_last_connect'
                )}: ${format(new Date(device.lastConnected), 'dd.MM.yyyy, H:mm')}`}</div>
                {!device.active && (
                  <div className={styles['reconnect-device']}>
                    {textResources.get('device_reconnect_necessary')}
                  </div>
                )}
              </div>
            </div>
            <HighlightButton
              secondary
              onClick={() => {
                dispatcher.start(RemovePairing(device.id));
              }}
            >
              {textResources.get('remove_paired_device')}
            </HighlightButton>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DeviceList;
