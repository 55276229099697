import styles from './server-info-page.module.css';
import { ClosablePage, useTextResources } from '@sqior/react/uibase';
import { FactoryProps } from '@sqior/react/factory';
import { DateTime } from 'luxon';
import { useDynamicState } from '@sqior/react/state';
import { SqiorNativeAppVersion, SqiorNativeAppVersionType } from '@sqior/react/uiapp';
import { DemoModePath } from '@sqior/viewmodels/app';
import { useContext } from 'react';
import { VersionInfoContext } from '@sqior/react/utils';
import { extractDomainWithPort } from '@sqior/js/url';

export function ServerInfoPage(props: FactoryProps) {
  const textDict = useTextResources();
  const versionInfoClientContext = useContext(VersionInfoContext);

  const versionServer = useDynamicState<string>('system/version/server', '');
  const versionNativeClient = SqiorNativeAppVersion as SqiorNativeAppVersionType;
  const demoMode = useDynamicState<boolean>(DemoModePath, false);

  const versionWebClient = versionInfoClientContext?.version;
  const serverTime = useDynamicState<number>('system/server-time', 0);
  const deployment = window.location.href.split('?')[0]; //+ "extralang/eslabel/";
  return (
    <ClosablePage
      onClose={() => props.onClose?.(true, undefined)}
      title={<div className={styles['title']}>{textDict.get('server_information')}</div>}
    >
      <div className={styles['container']}>
        <table className={styles['support-info-table']}>
          <tbody>
            {versionServer && (
              <tr className={styles['version-info-line']}>
                <td className={styles['version-info-name']}>
                  {textDict.get('support_infos_server_version')}
                </td>
                <td className={styles['version-info-value']}>
                  {versionServer}
                  {demoMode && ' (Demo)'}
                </td>
              </tr>
            )}
            {versionWebClient && (
              <tr className={styles['version-info-line']}>
                <td className={styles['version-info-name']}>
                  {textDict.get('support_infos_wep_version')}
                </td>
                <td className={styles['version-info-value']}>{versionWebClient}</td>
              </tr>
            )}
            {versionNativeClient && (
              <tr className={styles['version-info-line']}>
                <td className={styles['version-info-name']}>
                  {textDict.get('support_infos_native_version')}
                </td>
                <td className={styles['version-info-value']}>{versionNativeClient.version}</td>
              </tr>
            )}

            {deployment && (
              <tr className={styles['version-info-line']}>
                <td className={styles['version-info-name']}>
                  {textDict.get('support_infos_deployment_url')}
                </td>
                <td className={styles['version-info-value']}>
                  {extractDomainWithPort(deployment)}
                </td>
              </tr>
            )}
            {versionServer && (
              <tr className={styles['version-info-line']}>
                <td className={styles['version-info-name']}>
                  {textDict.get('support_infos_server_time')}
                </td>
                <td className={styles['version-info-value']}>
                  {DateTime.fromMillis(serverTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </ClosablePage>
  );
}

export default ServerInfoPage;
