import { OperationContext } from '@sqior/react/operation';
import { useDynamicState, useDynamicStateRaw } from '@sqior/react/state';
import {
  InputControl,
  ProfilePicture,
  SelectControl,
  SelectModel,
  SqiorPage,
  SvgIconPerson,
  SwitchControl,
  useTextResources,
} from '@sqior/react/uibase';
import { VersionInfoContext, classes } from '@sqior/react/utils';
import { LocationData } from '@sqior/viewmodels/location';
import {
  AvailableDefaultLocationsPath,
  DefaultLocationPath,
  DeviceDynamicRolePath,
  DeviceDynamicRolesViewModel,
  DeviceFixedRolesPath,
  PermanentDeviceSettingsEditMode,
  PermanentDeviceSettingsEditModePath,
  FixedPushDevicesPath,
  FixedPushDevicesType,
  FixedRolesViewModel,
  PhoneNumberSettingPath,
  PhoneNumberVM,
  PhoneType,
  PinCodePath,
  ProfilePicturePath,
  SelectDeviceDynamicRole,
  SelectFixedPushDeviceRole,
  SelectFixedRoles,
  SetDefaultLocation,
  SetPhoneNumber,
  UserSettingsEnableSlogans,
  UserSettingsPath,
  UserSettingsVM,
} from '@sqior/viewmodels/user';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ReactComponent as LockClosedIcon } from './lock_closed.svg';
import { ReactComponent as LockOpenIcon } from './lock_open.svg';
import { ReactComponent as PINIcon } from './key.svg';
import styles from './settings-page.module.css';
import CameraPage from '../camera-page/camera-page';
import { FacingMode } from '@sqior/react/qrcode';
import MugshotPage from '../mugshot-page/mugshot-page';
import { FactoryProps, factoryGetProp } from '@sqior/react/factory';
import { PinEntryPage } from '@sqior/react/uiapp';
import { URLEntity } from '@sqior/viewmodels/visual';

enum SubPage {
  None,
  Photo,
  PIN,
}

export function SettingsPage(props: FactoryProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  const versionInfo = useContext(VersionInfoContext);

  const availableLocations = useDynamicState<LocationData>(AvailableDefaultLocationsPath, []);
  const defaultLocation = useDynamicState<string>(DefaultLocationPath, '');
  const fixDeviceSettingEditMode = useDynamicState<PermanentDeviceSettingsEditMode>(
    PermanentDeviceSettingsEditModePath,
    PermanentDeviceSettingsEditMode.None
  );
  const userSettings = useDynamicState<UserSettingsVM>(UserSettingsPath, {});
  const locModel: SelectModel = {
    items: [
      textDict.get('no_fixed_location'),
      ...availableLocations.map((item) => {
        return item.text;
      }),
    ],
    selected:
      availableLocations.findIndex((item) => {
        return item.id === defaultLocation;
      }) + 1,
  };

  const deviceFixedRoles = useDynamicState<FixedRolesViewModel>(DeviceFixedRolesPath, {
    items: [],
  });
  const selectedFixedRoles = deviceFixedRoles.items.findIndex((item) => {
    return item.id === deviceFixedRoles.selected;
  });
  const rolesModel: SelectModel = {
    items: [
      textDict.get('no_fixed_roles'),
      ...deviceFixedRoles.items.map((item) => {
        return item.label;
      }),
    ],
    selected: selectedFixedRoles + 1,
  };

  const deviceDynamicRoles = useDynamicState<DeviceDynamicRolesViewModel>(DeviceDynamicRolePath, {
    items: [],
  });
  const selectedDeviceDynamicRole = deviceDynamicRoles.items.findIndex((item) => {
    return item.id === deviceDynamicRoles.selected;
  });
  const deviceDynamicRolesModel: SelectModel = {
    items: [
      textDict.get('no_dynamic_role'),
      ...deviceDynamicRoles.items.map((item) => {
        return item.label;
      }),
    ],
    selected: selectedDeviceDynamicRole + 1,
  };

  const deviceFixedPushRoles = useDynamicState<FixedPushDevicesType>(FixedPushDevicesPath, {
    roles: [],
    selected: '',
  });
  const selectedFixedPushRoles = deviceFixedPushRoles.roles.findIndex((role) => {
    return role.id === deviceFixedPushRoles.selected;
  });
  const pushRolesModel: SelectModel = {
    items: [
      textDict.get('no_fixed_push_roles'),
      ...deviceFixedPushRoles.roles.map((role) => {
        return role.text;
      }),
    ],
    selected: selectedFixedPushRoles + 1,
  };

  const phoneState = useDynamicState<PhoneNumberVM>(PhoneNumberSettingPath, {
    device: '',
    user: '',
    deviceEnabled: false,
  });
  function onPhoneNumberChanged(type: PhoneType, e: ChangeEvent<HTMLInputElement>) {
    const phone = e.target.value;
    dispatcher.start(SetPhoneNumber(type, phone));
  }
  const profilePicture = useDynamicStateRaw<URLEntity>(ProfilePicturePath);
  const pinCode = useDynamicStateRaw<number>(PinCodePath);

  /* The permanent settings are protected by a lock, so that they are not manipulated unintentionally */
  const [lock, setLock] = useState(true);
  // In Admin Mode, unlock permanent settings per default
  useEffect(() => {
    if (fixDeviceSettingEditMode === PermanentDeviceSettingsEditMode.Admin) setLock(false);
  }, [fixDeviceSettingEditMode]);
  /* Mug shot page state */
  const [subPage, setSubPage] = useState(SubPage.None);
  /* Acquired photo */
  const [photo, setPhoto] = useState<HTMLCanvasElement>();

  /* Currently disabled: user phone number
        <div className={styles['entry']}>
          <div>{textDict.get('setting_phonenumber_user')}</div>
          <InputControl
            value={phoneState.user}
            placeholder="+49 123 456789"
            type="tel"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onPhoneNumberChanged(PhoneType.User, e)}
          />
        </div>
  */

  return (
    <SqiorPage onClose={() => props.onClose?.(true, undefined)}>
      <div className={classes(styles['container'], factoryGetProp('className', props))}>
        <div className={styles['title']}>{textDict.get('settings')}</div>

        <div className={styles['priority-container']}>
          <div>{textDict.get('settings_slogan_title')}</div>
          <div>{textDict.get('test')}</div>
          <SwitchControl
            checked={!userSettings?.disableSlogans}
            onChange={() => {
              dispatcher.start(UserSettingsEnableSlogans(userSettings?.disableSlogans === true));
            }}
          />
        </div>
        <div
          className={styles['profile-container']}
          onClick={() => {
            setSubPage(SubPage.Photo);
          }}
        >
          {profilePicture && (
            <ProfilePicture className={styles['profile']} url={profilePicture.url} />
          )}
          {!profilePicture && <SvgIconPerson className={styles['lock-icon']} />}
          <div className={styles['lock-text']}>
            {textDict.get(profilePicture ? 'change_profile_picture' : 'take_profile_picture')}
          </div>
        </div>
        {pinCode !== undefined && (
          <div
            className={styles['profile-container']}
            onClick={() => {
              setSubPage(SubPage.PIN);
            }}
          >
            <PINIcon className={styles['lock-icon']} />
            <div className={styles['lock-text']}>
              {textDict.get(pinCode ? 'pin_change' : 'pin_definition')}
            </div>
          </div>
        )}
        <br />
        {(availableLocations.length > 0 ||
          deviceFixedRoles.items.length > 0 ||
          deviceFixedPushRoles.roles.length > 0 ||
          deviceDynamicRoles.items.length > 0 ||
          (versionInfo?.mobileClient && phoneState.deviceEnabled)) &&
          fixDeviceSettingEditMode !== PermanentDeviceSettingsEditMode.None && (
            <div className={styles['permanent-container']}>
              <div
                className={styles['lock-container']}
                onClick={() => {
                  setLock(!lock);
                }}
              >
                {lock && <LockClosedIcon className={styles['lock-icon']} />}
                {!lock && <LockOpenIcon className={styles['lock-icon']} />}
                <div className={styles[lock ? 'lock-text' : 'unlocked-text']}>
                  {textDict.get('permanent_settings')}
                  {fixDeviceSettingEditMode === PermanentDeviceSettingsEditMode.Admin &&
                    ` (${textDict.get('permanent_settings_admin')})`}
                </div>
              </div>
              {availableLocations.length > 0 && (
                <div className={styles[lock ? 'locked-entry' : 'entry']}>
                  <div>{textDict.get('fixed_room_assignment')}</div>
                  <SelectControl
                    disabled={lock}
                    data={locModel}
                    onSelect={(item) => {
                      dispatcher.start(
                        SetDefaultLocation(item > 0 ? availableLocations[item - 1].id || '' : '')
                      );
                    }}
                  />
                </div>
              )}
              {deviceFixedRoles.items.length > 0 && (
                <div className={styles[lock ? 'locked-entry' : 'entry']}>
                  <div>{textDict.get('fixed_roles_assignment')}</div>
                  <SelectControl
                    disabled={lock}
                    data={rolesModel}
                    onSelect={(item) => {
                      dispatcher.start(
                        SelectFixedRoles(item > 0 ? deviceFixedRoles.items[item - 1].id : '')
                      );
                    }}
                  />
                </div>
              )}
              {deviceDynamicRoles.items.length > 0 && (
                <div className={styles[lock ? 'locked-entry' : 'entry']}>
                  <div>{textDict.get('dynamic_role_assignment')}</div>
                  <SelectControl
                    disabled={lock}
                    data={deviceDynamicRolesModel}
                    onSelect={(item) => {
                      dispatcher.start(
                        SelectDeviceDynamicRole(
                          item > 0 ? deviceDynamicRoles.items[item - 1].id : ''
                        )
                      );
                    }}
                  />
                </div>
              )}
              {deviceFixedPushRoles.roles.length > 0 && (
                <div className={styles[lock ? 'locked-entry' : 'entry']}>
                  <div>{textDict.get('fixed_push_roles_assignment')}</div>
                  <SelectControl
                    disabled={lock}
                    data={pushRolesModel}
                    onSelect={(item) => {
                      dispatcher.start(
                        SelectFixedPushDeviceRole(
                          item > 0 ? deviceFixedPushRoles.roles[item - 1].id : ''
                        )
                      );
                    }}
                  />
                </div>
              )}
              {versionInfo?.mobileClient && phoneState.deviceEnabled && (
                <div className={styles[lock ? 'locked-entry' : 'entry']}>
                  <div>{textDict.get('setting_phonenumber_device')}</div>
                  <InputControl
                    disabled={lock}
                    value={phoneState.device}
                    placeholder="+49 123 456789"
                    type="tel"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onPhoneNumberChanged(PhoneType.Device, e)
                    }
                  />
                </div>
              )}
            </div>
          )}
      </div>
      {subPage === SubPage.Photo && (
        <CameraPage
          title={textDict.get('take_profile_picture')}
          defaultFacing={FacingMode.User}
          onPhoto={(photo) => {
            setPhoto(photo);
            setSubPage(SubPage.None);
          }}
          onClose={() => {
            setSubPage(SubPage.None);
          }}
        />
      )}
      {photo && (
        <MugshotPage
          photo={photo}
          onClose={() => {
            setPhoto(undefined);
          }}
        />
      )}
      {subPage === SubPage.PIN && (
        <PinEntryPage
          onClose={() => {
            setSubPage(SubPage.None);
          }}
        />
      )}
    </SqiorPage>
  );
}

export default SettingsPage;
