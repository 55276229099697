import { UserInfo } from '@sqior/react/uiauth';
import { AppMenu } from '@sqior/react/uibase';
import { ActiveLocation } from '../active-location/active-location';
import { useDynamicState } from '@sqior/react/state';
import { hasLocationRole, LocationData } from '@sqior/viewmodels/location';
import { ReactComponent as LocationIcon } from './location.svg';
import { ReactComponent as UserIcon } from './badge.svg';
import styles from './top-bar.module.css';
import { useIsMobile } from '@sqior/react/hooks';

/* eslint-disable-next-line */
export interface TopBarProps {
  onAbout: () => void;
  onUser: () => void;
  onLocation: () => void;
}

export function TopBar(props: TopBarProps) {
  const isMobile = useIsMobile();
  const activeLocation = useDynamicState<LocationData>('active-location', []);
  const showLocations = hasLocationRole(activeLocation);

  return (
    <div className={styles['container']}>
      <div className={styles[showLocations ? 'info-container-w-loc' : 'info-container-wo-loc']}>
        <UserIcon className={styles['icon-user']} viewBox="0 0 40 40" onClick={props.onUser} />
        <UserInfo className={styles['info-user']} onClick={props.onUser} />
        {showLocations && (
          <>
            <LocationIcon
              className={styles['icon-location']}
              viewBox="0 0 40 40"
              onClick={props.onLocation}
            />
            <ActiveLocation className={styles['info-location']} onClick={props.onLocation} />
          </>
        )}
      </div>
      <div className={styles['menu-button-container']}>
        {isMobile && <AppMenu onClick={props.onAbout} />}
      </div>
    </div>
  );
}

export default TopBar;
