import styles from './patient-info.module.css';
import PatientHeader from '../patient-header/patient-header';
import React from 'react';
import { FactoryProps } from '@sqior/react/factory';
import { PatientInfo as PatientInfoType } from '@sqior/viewmodels/patient';

export type PatientInfoProps = FactoryProps<PatientInfoType>;

export function PatientInfo(props: PatientInfoProps) {
  return <PatientHeader {...props.data} />;
}

export default PatientInfo;
