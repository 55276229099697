import styles from './on-mount-effect.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface OnMountEffectProps {}

export function OnMountEffect(props: OnMountEffectProps) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);
  return (
    <AnimatePresence>{loading && <motion.div className={styles['loading']} />}</AnimatePresence>
  );
}

export default OnMountEffect;
