import React from 'react';

export function newlineToBr(str: string) {
  return str.split('\n').map(function (item, idx, arr) {
    return (
      <React.Fragment key={idx}>
        {item}
        {idx < arr.length && <br />}
      </React.Fragment>
    );
  });
}
