import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    grayed: Palette['primary'];
    white: Palette['primary'];
  }

  interface PaletteOptions {
    grayed: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    grayed: true;
    white: true;
  }
}

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1cade4',
      dark: '#177daa',
    },
    secondary: {
      main: '#f9c80e',
    },
    grayed: {
      main: '#ffffff7f',
    },
    background: {
      paper: '#090f23',
    },
    white: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },

  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          borderColor: 'rgba(48, 56, 89, 1)',
        },
      },
    },
  },
});
