import { ensureArray } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';

export type PluginContext = { plugins: Plugins };

export type PluginInitializer<Context> = (context: Context, priv: Record<string, unknown>) => void;

export class Plugins {
  constructor() {
    this.plugins = new Map<unknown, Record<string, unknown>>();
  }

  require<Context>(init: PluginInitializer<Context>, context: Context): void {
    /* Check if this is already initialized - if yes, do not initialize twice */
    if (this.plugins.has(init)) return;
    /* Register this initializer, this also breaks circles in require chains */
    const pc: Record<string, unknown> = {};
    this.plugins.set(init, pc);
    /* Call initialization function */
    init(context, pc);
  }

  requireAll<Context>(plugins: PluginInitializer<Context>[], context: Context) {
    for (const plugin of plugins) this.require(plugin, context);
  }

  select<Context>(
    options: Record<string, PluginInitializer<Context> | PluginInitializer<Context>[]>,
    sel: string,
    context: Context
  ) {
    const plugins = options[sel];
    if (plugins) this.requireAll(ensureArray(plugins), context);
    else Logger.warn(['Unknown plug-in selection option - provided:', sel]);
  }

  private plugins: Map<unknown, Record<string, unknown>>;
}
