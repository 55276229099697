import { LogRetainer, RetainedLogData } from '@sqior/js/log';
import { LogConfirmData, LogConfirmType, LogMessageData, LogMessageType } from './log-message';
import { BiChannel } from './bi-channel';

/** Sends log messages over a channel */
export class LogSender {
  constructor(channel: BiChannel, retainer = new LogRetainer()) {
    this.channel = channel;
    this.retainer = retainer;
    /* Listen to the retainer to send out logs immediately, it the channel is open */
    this.retainer.added.on((log) => {
      this.log(log);
    });
    /* Listen to confirmation messages */
    this.channel.in.on<LogConfirmData>(LogConfirmType, (msg) => {
      this.retainer.purge(msg.sequenceNumber);
    });
    /* Send out retained logs on opening the channel, if applicable */
    this.channel.onOpen(() => {
      if (this.retainer.logs.length)
        this.channel.out.send<LogMessageData>({
          type: LogMessageType,
          data: this.retainer.logs,
        });
    });
  }

  private log(data: RetainedLogData) {
    /* Send immediately if channel is open */
    if (this.channel.allOpen)
      this.channel.out.send<LogMessageData>({
        type: LogMessageType,
        data: [data],
      });
  }

  private channel: BiChannel;
  readonly retainer: LogRetainer;
}
