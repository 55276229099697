import { WorkflowPath } from '@sqior/react/uiworkflow';
import styles from './orworkflow-area.module.css';
import { ORWorkflowOverview } from '@sqior/viewmodels/orworkflow';
import WorkflowBaseArea from '../workflow-base-area/workflow-base-area';
import { useScrollPosition } from '@sqior/react/hooks';

export interface ORWorkflowAreaProps {
  data: ORWorkflowOverview;
}

export function ORWorkflowArea(props: ORWorkflowAreaProps) {
  const { relatedId, actions, workflow, caveInfos, infos } = props.data;
  const { contentRef, scrollToPosition, freeze } = useScrollPosition();

  return (
    <WorkflowBaseArea
      ref={contentRef}
      relatedId={relatedId}
      caveInfos={caveInfos}
      actions={actions}
      infos={infos}
      freeze={freeze}
    >
      {workflow && (
        <WorkflowPath
          path={workflow}
          className={styles['content-workflow']}
          scrollToPosition={scrollToPosition}
        />
      )}
    </WorkflowBaseArea>
  );
}

export default ORWorkflowArea;
