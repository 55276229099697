import { ClosablePage, ContainerWithShadow } from '@sqior/react/uibase';
import styles from './patient-page.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { ComponentFactory, factoryGetProp, FactoryProps } from '@sqior/react/factory';
import { PatientOverview, PatientOverviewPath } from '@sqior/viewmodels/patient';
import { SelectionPageContext, SelectionStack, useSelectionStack } from '@sqior/react/uiselection';
import PatientHeader from '../patient-header/patient-header';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { UndoInfoToast } from '@sqior/react/uiconversation';
import { ValueObject } from '@sqior/js/data';
import { useDynamicState } from '@sqior/react/state';

/* eslint-disable-next-line */
export type PatientPageProps = EntityHeader & {};

export function PatientPage(props: FactoryProps<PatientPageProps>) {
  const patOverview = useDynamicState<PatientOverview>(PatientOverviewPath, {
    info: { entityType: '', name: '' },
  });

  const [selection, setSelection] = useState<[Entity, (ok: boolean, data?: ValueObject) => void][]>(
    []
  );
  const FactoryComponent = useContext(ComponentFactory);

  /* Reset selection if underlying patient changes */
  useEffect(() => {
    setSelection([]);
  }, [patOverview.info.id]);

  const selectionStack = useSelectionStack();

  return (
    <ClosablePage
      relativeHeader
      withBorder
      headerBackgroundColor="secondary"
      className={factoryGetProp<string>('className', props)}
      onClose={() => {
        props.onClose?.();
      }}
    >
      <PatientHeader {...patOverview.info} />
      <SelectionPageContext.Provider
        value={(menu, onClose) => {
          selectionStack[0].add({ data: menu, cb: onClose });
        }}
      >
        <div className={styles['container']}>
          <ContainerWithShadow className={styles['workflow-shadow']}>
            <div className={styles['scrollable']}>
              {patOverview.content !== undefined ? (
                <FactoryComponent data={patOverview.content} />
              ) : (
                <div />
              )}
            </div>
          </ContainerWithShadow>
        </div>
        {selectionStack[1].length > 0 && (
          <div className={styles['menu']}>
            <SelectionStack data={selectionStack} />
          </div>
        )}
        {selection.length === 0 && <UndoInfoToast className={styles['undo-info-toast']} />}
      </SelectionPageContext.Provider>
    </ClosablePage>
  );
}

export default PatientPage;
