import styles from './sqior-chip.module.css';
import React, { FC, ReactNode } from 'react';

export interface SqiorChipProps {
  label: string;
  backgroundColor?: string;
  color?: string;
  icon?: ReactNode;
  fontSize?: number;
}

export const SqiorChip: FC<SqiorChipProps> = ({
  label,
  backgroundColor,
  icon,
  color,
  fontSize,
}) => {
  return (
    <div
      className={styles['container']}
      style={{
        backgroundColor,
        fontSize,
      }}
    >
      {icon}
      <div style={{ color }}>{label}</div>
    </div>
  );
};

export default SqiorChip;
