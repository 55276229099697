import styles from './patient-qrcode-page.module.css';
import { QRCodeContent } from '@sqior/viewmodels/patient';
import { FactoryProps } from '@sqior/react/factory';
import Barcode from 'react-barcode';
import { memo } from 'react';

export type PatientQRCodePageProps = FactoryProps<QRCodeContent>;

export function PatientQRCodePage({ data: { code } }: PatientQRCodePageProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <Barcode format="CODE128" value={withZero(code)} width={3} />
      </div>
    </div>
  );
}

export default memo(PatientQRCodePage);

const withZero = (value: string) => {
  const length = value.length;
  const ZERO = '0';
  if (length % 2 === 0) return value;
  return ZERO + value;
};
