export type SectionListType<T> = {
  header: string;
  items: T[];
};

export function createSectionList<T extends Record<K, any>, K extends keyof T>(
  items: T[],
  groupByKey: K
): SectionListType<T>[] {
  const groupedItems: Record<string, T[]> = {};

  // Group items by the specified key
  items.forEach((item) => {
    const keyValue = String(item[groupByKey]); // Convert key value to string to use as an object key
    if (!groupedItems[keyValue]) {
      groupedItems[keyValue] = [];
    }
    groupedItems[keyValue].push(item);
  });

  // Transform the grouped items into the desired output structure
  return Object.keys(groupedItems).map((header) => ({
    header,
    items: groupedItems[header],
  }));
}
