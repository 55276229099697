import { Closable } from '@sqior/js/async';
import { Logger } from '@sqior/js/log';
import { CacheState, CacheStateType } from './cache-state';
import { StopListening } from '@sqior/js/event';
import { Value } from '@sqior/js/data';

/** Reevaluates a function every time the cache invalidates */
export class Reevaluation implements Closable {
  constructor(calc: (as: AbortSignal) => Promise<CacheState | undefined>) {
    this.calc = calc;
    this.stop = new AbortController();
    this.start();
  }

  async close() {
    /* Mark to stop */
    this.stop.abort();
    /* Wait for any remaining user */
    if (this.usage)
      try {
        await this.usage;
      } catch (e) {
        /* Ignore exception during closing */
      } finally {
        // Set to undefined so that this a subsequent call of close() does not have any effect
        this.usage = undefined;
      }

    /* Stop listening and decrease reference count if necessary */
    this.stopListening?.();
    // Set to undefined so that this a subsequent call of close() does not have any effect
    this.stopListening = undefined;
  }

  private start() {
    if (this.stop.signal.aborted) return;
    this.usage = this.calc(this.stop.signal).then((cs?: CacheState) => {
      /* Listen to invalidation of the cache state, if applicable */
      if (cs?.invalidated) {
        const off = cs.invalidated.on(() => {
          /* Make sure to not call stop listening */
          this.stopListening = undefined;
          /* Decrement use count */
          cs.decRef();
          setTimeout(() => {
            this.start();
          }, 0); // start calculation delayed so that all caches are consistently invalidated
        });
        /* Register function to stop listening for invalidation and decreasing the reference count */
        this.stopListening = () => {
          off();
          cs.decRef();
        };
      } else {
        /* Decrement use count directly */
        cs?.decRef();
        /* Immediately re-evaluate if already invalid */
        if (cs?.valid === false) this.start();
      }
    });
    this.usage.catch((e) => {
      Logger.warn([
        'Exception during reevaluation, value will not be reevaluated longer - Exception: ',
        Logger.exception(e),
      ]);
    });
  }

  private calc: (as: AbortSignal) => Promise<CacheState | undefined>;
  private stop: AbortController;
  private usage?: Promise<void>;
  private stopListening?: StopListening;
}

/** Reevaluates a function every time the cache invalidates and provides the result to a consumer */
export class CurrentValue<Type extends Value> implements Closable {
  constructor(
    calc: () =>
      | [Type | undefined, CacheState | undefined]
      | Promise<[Type | undefined, CacheState | undefined]>,
    use: (value: Type | undefined) => Promise<void>,
    options: { initialUsage?: boolean } = {}
  ) {
    this.usage = options.initialUsage ?? true;
    this.reevaluation = new Reevaluation(async (as) => {
      /* Check if this is in use, if not simply return undefined and wait for usage */
      if (!this.usage) {
        /* Lazy creation of cache state */
        this.usedCache = new CacheState(CacheStateType.Dynamic);
        /* Set undefined value */
        await use(undefined);
        return this.usedCache;
      }
      /* Calculate value */
      const res = await calc();
      /* Use value */
      if (!as.aborted)
        try {
          await use(res[0]);
        } catch (e) {
          Logger.warn(
            ['Exception on processing current value - Exception:', Logger.exception(e)],
            ['Exception when processing:', res[0], ' - Exception: ', Logger.exception(e)]
          );
        }
      return res[1];
    });
  }

  async close() {
    await this.reevaluation.close();
  }

  /** Inform about the current usage state */
  set used(state: boolean) {
    if (state === this.usage) return;
    this.usage = state;
    /* Invalidate the cache to recalculate the value - this is only done on the transition from unused to used in 
       order to keep the calculated valid value around until it gets invalidated */
    if (state && this.usedCache) this.usedCache.invalidate();
  }

  private reevaluation: Reevaluation;
  private usage: boolean;
  private usedCache?: CacheState;
}
