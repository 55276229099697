import { now } from '@sqior/js/data';
import { OperationContext } from '@sqior/react/operation';
import {
  InformProjectionVM,
  MarkRead,
  ReadConfirmationTypes,
  isMyReadConfirmationTypeAtLeast,
} from '@sqior/viewmodels/communication';
import { useContext, useEffect } from 'react';

const markedItems = new Set<string>(); // Store marked read items in an Set to avoid double marking read because of react updates
export function useAutoMarkRead(
  informs: InformProjectionVM[],
  confirmationType: ReadConfirmationTypes | undefined
) {
  const dispatcher = useContext(OperationContext);
  useEffect(() => {
    const itemsToMarkRead: string[] = [];
    if (confirmationType === undefined) return;
    for (const i of informs) {
      if (!isMyReadConfirmationTypeAtLeast(i, confirmationType)) {
        itemsToMarkRead.push(i.id);
        if (i.additionalIds) itemsToMarkRead.push(...i.additionalIds);
      }
    }

    if (itemsToMarkRead.length > 0) {
      const effectiveItemsToMarkRead: string[] = [];
      for (const i of itemsToMarkRead) {
        const key = i + '-' + confirmationType;
        if (!markedItems.has(key)) {
          markedItems.add(key);
          effectiveItemsToMarkRead.push(i);
        }
      }

      if (effectiveItemsToMarkRead.length > 0)
        dispatcher.start(
          MarkRead({
            id: effectiveItemsToMarkRead,
            confirmationType: confirmationType,
            timestamp: now(),
          })
        );
    }
  }, [informs, dispatcher, confirmationType]);
}
