import { Entity, EntityHeader, IdEntity } from '@sqior/js/entity';
import { SearchKeys, ItemState, ListViewItem } from '@sqior/viewmodels/visual';
import { PatientInfo, SpecialtyVM, WorkflowInfoVM } from '@sqior/viewmodels/patient';
import { SelectionControlBaseEntity } from '@sqior/viewmodels/input';
import { OccupancyWorkflowOverview } from './occupancy-overview';

/* Parameterless entity representing the parameters of a bed allocation request to be put in */

export enum BedAllocationRequestParametersInputMode {
  Create = 'create',
  Change = 'change',
}
export const BedAllocationRequestParametersInputType = 'BedAllocationRequestParametersInput';
export type BedAllocationRequestParametersInput = Entity & {
  clinics: [string, Entity][];
  selectedClinic?: number;
  isolation?: boolean;
  monitoring?: boolean;
  scoreDesc: string[];
  selectedScore?: number;
  mode: string;
  priority?: boolean;
};

/* Entity representing the parameters for a bed allocation request */

export const BedAllocationRequestParametersEntity = 'BedAllocationRequestParameters';
export const BedAllocationRequestParametersInfoType = 'BedAllocationRequestParametersInfo';

export type BedAllocationRequestParametersBase = EntityHeader & {
  isolation?: boolean;
  monitoring?: boolean;
  nursingComplexity?: number;
  priority?: boolean;
};
export type BedAllocationRequestParameters = BedAllocationRequestParametersBase & {
  clinic?: Entity;
};
export type BedAllocationRequestParametersInfoEntity = BedAllocationRequestParametersBase & {
  source?: string;
  clinic?: string;
  clinicVM?: SpecialtyVM;
  insuranceInfo?: string;
  notes?: string;
};

/* Entity representing the input allowing for selecting a patient ward */

export const BedAllocationTargetWardInputType = 'BedAllocationTargetWardInput';

export type BedAllocationTargetWard = {
  name: string;
  id: Entity;
  text: string;
  challenge: boolean;
};
export type BedAllocationTargetWardInput = SelectionControlBaseEntity & {
  info: PatientInfo;
  diagnosis?: string;
  requirements: BedAllocationRequestParametersInfoEntity;
  wards: BedAllocationTargetWard[];
  defaultWard: string;
};

/* Bed allocation workflow overview */

export const BedAllocationOverviewType = 'BedAllocationOverview';

export type BedAllocationOverviewEntity = OccupancyWorkflowOverview;

/** Bed allocation parameter info  */
export const BedAllocationRequirementsInfoVMType = 'BedAllocationRequirementsInfoVM';
export type BedAllocationRequirementsInfoVM = WorkflowInfoVM & {
  isolation?: boolean;
  monitoring?: boolean;
  nursingComplexity?: number;
  priority?: boolean;
  clinicVM?: SpecialtyVM;
};
export function makeBedAllocationRequirementsInfoVM(
  requirements: BedAllocationRequestParametersBase,
  clinicVM: SpecialtyVM | undefined,
  alwaysVisibile: boolean
): BedAllocationRequirementsInfoVM {
  const ret: BedAllocationRequirementsInfoVM = {
    entityType: BedAllocationRequirementsInfoVMType,
    type: 'long',
    alwaysVisibile: alwaysVisibile,
  };
  if (requirements.isolation) ret.isolation = true;
  if (requirements.monitoring) ret.monitoring = true;
  if (requirements.nursingComplexity) ret.nursingComplexity = requirements.nursingComplexity;
  if (requirements.priority) ret.priority = true;
  if (clinicVM) ret.clinicVM = clinicVM;
  return ret;
}
export function bedAllocationRequirementsInfoVMEmpty(
  requirements: BedAllocationRequestParametersBase
) {
  return !(
    requirements.isolation ||
    requirements.monitoring ||
    requirements.nursingComplexity ||
    requirements.priority
  );
}

/* Bed allocation list item */

export const BedAllocationListItemType = 'BedAllocationListItem';

export type OrderType = {
  room: string;
  name: string;
  priority: number;
  requestedTS?: number | undefined;
};

export type BedAllocationListItemEntity<IdType = IdEntity> = ListViewItem<IdType> &
  SearchKeys & {
    patient: string;
    targetWard?: string;
    diagnosis?: string;
    requirements?: string;
    requestSource?: string;
    order: OrderType;
    state: ItemState;
    select: Entity;
    priority: boolean;
  };
