import { Bytes } from './binary';
import { PrimitiveValue, Value, ValueObject, ValueOrNothing } from './value';

/* Transforms a value and returns a modified copy if necessary or the value itself if unchanged */

export function transform(input: Value, trans: (value: Value) => [ValueOrNothing, boolean]) {
  /* Perform transformation */
  const [value, recurse] = trans(input);
  if (
    value == undefined ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value instanceof Bytes ||
    !recurse
  )
    return value;
  if (value instanceof Array) {
    /* Check if this is an array that must be traversed, only create new array if modified */
    let res: Value[] | undefined;
    for (let i = 0; i < value.length; i++) {
      const arrVal = value[i];
      const transVal = transform(arrVal, trans);
      if (arrVal === transVal && !res) continue;
      if (!res) res = value.slice(0, i);
      if (transVal !== undefined) res.push(transVal);
    }
    return res ?? value;
  }
  /* Traverse all keys, only create new object if necessary */
  let res: ValueObject | undefined;
  for (const key in value) {
    const objVal = value[key];
    const transVal = transform(objVal, trans);
    if (objVal === transVal && !res) continue;
    if (!res) {
      res = {};
      for (const ck in value) {
        if (ck === key) break;
        res[ck] = value[ck];
      }
    }
    if (transVal !== undefined) res[key] = transVal;
  }
  return res ?? value;
}

/* Transforms primitive values inside a value and returns a modified copy if necessary or the value itself if unchanged */

export function transformPrimitives(
  value: Value,
  trans: (value: PrimitiveValue) => ValueOrNothing
) {
  return transform(value, (value) => {
    /* Call function on primitives */
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      value instanceof Bytes
    )
      return [trans(value), false];
    /* Otherwise recurse */
    return [value, true];
  });
}
