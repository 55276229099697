import { CacheCleanup } from './cache-cleanup';

export class CacheAccess {
  constructor(man: CacheCleanup, callback: () => void) {
    this.clear = callback;
    this.manager = man;
    this.lastAccessGeneration = man.currentGeneration;
    this.enlistGeneration = man.add(this);
  }

  get last() {
    return this.lastAccessGeneration;
  }

  notify() {
    this.lastAccessGeneration = this.manager.currentGeneration;
  }

  reset() {
    this.manager.remove(this);
  }

  emit() {
    this.clear();
  }

  private manager: CacheCleanup;
  enlistGeneration: number;
  private lastAccessGeneration: number;
  private clear: () => void;
}
