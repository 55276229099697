import { Bytes, now } from '@sqior/js/data';
import { DeleteMedia, UploadMedia } from '@sqior/js/media';
import { AddOperation, ContentTypes, isFinal, OperationState } from '@sqior/js/operation';
import { UID } from '@sqior/js/uid';
import { OperationContext } from '@sqior/react/operation';
import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { useContext, useEffect, useState } from 'react';
import styles from './photo-page.module.css';

/* eslint-disable-next-line */
export interface PhotoPageProps {
  photo: HTMLCanvasElement;
  onClose: () => void;
}

export function PhotoPage(props: PhotoPageProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  const [id, setId] = useState<UID | undefined>(undefined);
  const [perc, setPerc] = useState(0);
  /* Perform upload */
  useEffect(() => {
    let informPerc: ((perc: number) => void) | undefined = setPerc;
    let informState: ((id: UID) => void) | undefined = setId;
    props.photo.toBlob((blob) => {
      if (blob) {
        console.log(blob.size);
        const uploadUp = dispatcher.start(
          UploadMedia(now(), ContentTypes.JPEG, new Bytes(blob)),
          undefined,
          (perc) => {
            if (informPerc) informPerc(perc);
          }
        ) as AddOperation;
        uploadUp.stateChange.on((state) => {
          if (informState) {
            if (state === OperationState.Completed && uploadUp.finalId)
              informState(uploadUp.finalId);
            else if (isFinal(state)) informState('');
          } else if (uploadUp.finalId) dispatcher.start(DeleteMedia(uploadUp.finalId));
        });
      } else if (informState) informState('');
    }, ContentTypes.JPEG);
    return () => {
      informPerc = undefined;
      informState = undefined;
    };
  }, [props, dispatcher]);

  return (
    <div className={styles['container']}>
      <img src={props.photo.toDataURL()} alt="" className={styles['image']} />
      {id === undefined && <div>{'Hochgeladen: ' + perc.toString() + '%'}</div>}
      {id === '' && <div>Datei konnte nicht hochgeladen werden!</div>}
      <div className={styles['bottombar']}>
        {id && id.length > 0 && (
          <HighlightButton
            onClick={() => {
              props.onClose();
            }}
          >
            Übernehmen
          </HighlightButton>
        )}
        <HighlightButton
          onClick={() => {
            if (id && id.length > 0) dispatcher.start(DeleteMedia(id));
            props.onClose();
          }}
        >
          {textDict.get('cancel')}
        </HighlightButton>
      </div>
    </div>
  );
}

export default PhotoPage;
