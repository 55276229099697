import { EntityHeader } from '@sqior/js/entity';
import { ClockTimestamp } from '@sqior/js/data';

/** Input of a timestamp */
export const TimestampInputControlType = 'TimestampInputControl';
export type TimestampInputVM = EntityHeader & {
  label: string;
  offset: ClockTimestamp;
  min: ClockTimestamp;
  max: ClockTimestamp;
  textResult?: boolean;
};
