import { BiChannel } from '@sqior/js/message';
import {
  StateReplaceMessage,
  StateUpdateMessage,
  StateMessageType,
  StateUseMessage,
} from './state-message';
import { State } from '@sqior/js/state';
import { ValuePatch } from '@sqior/js/data';
import { StopListening } from '@sqior/js/event';

export class StateReceiver {
  constructor(channel: BiChannel, state = new State(), resetOnClose = false) {
    this.channel = channel;
    this.state = state;

    /* Connect to the closing signal of the incoming data, reset the state in this case */
    if (resetOnClose)
      channel.onClose(() => {
        for (const pathData of this.paths) {
          this.state.setSub(pathData[0], undefined);
          if (pathData[1].stopUseListening) pathData[1].stopUseListening();
        }
        this.paths.clear();
      });
    /* Connect to the state message providing a new state */
    channel.in.on<StateReplaceMessage>(StateMessageType.ReplaceState, (message) => {
      /* Set all received values at their respective paths */
      const newPaths = new Set<string>();
      for (const subState of message.states) {
        const state = this.state.subState(subState.path);
        state.set(subState.value, subState.timestamp);
        /* Check if this is a new path */
        state.onDemand = subState.onDemand ?? false;
        this.initPath(subState.path, state, true);
        /* Remember that this path was set (used to reset all states not contained) */
        newPaths.add(subState.path);
      }

      /* Eliminate all values that were set by this so far and which
         are no longer found in the new state that replaces it */
      for (const pathData of this.paths)
        if (!newPaths.has(pathData[0])) {
          this.state.setSub(pathData[0], undefined);
          if (pathData[1].stopUseListening) pathData[1].stopUseListening();
          this.paths.delete(pathData[0]);
        }
    });

    /* Connect to the state message providing a state update */
    channel.in.on<StateUpdateMessage>(StateMessageType.UpdateState, (message) => {
      /* Send back confirmation */
      channel.out.send({ type: StateMessageType.ConfirmState, path: message.path });

      /* Set new value */
      const state = this.state.subState(message.path);
      if (message.patch !== undefined)
        state.changeRaw((value) => {
          return ValuePatch.patch(value, message.patch);
        }, message.timestamp);
      else state.set(undefined, message.timestamp);

      /* Check if this is a new path */
      if (message.onDemand !== undefined) state.onDemand = message.onDemand;
      this.initPath(message.path, state, false);
    });
  }

  /** Initializes internal structures for a path and its state */
  private initPath(path: string, state: State, initUse: boolean) {
    const usedChangeHandler = (active: boolean) => {
      /* Send a usage changes message, if connected */
      if (this.channel.out.isOpen) {
        this.channel.out.send<StateUseMessage>({
          type: StateMessageType.UpdateUse,
          path: path,
          used: active,
        });
      }
    };
    /* Create path data, if applicable */
    const pathData = this.paths.get(path);
    if (!pathData)
      this.paths.set(
        path,
        state.onDemand
          ? {
              stopUseListening: state.onUseChanged(usedChangeHandler),
            }
          : {}
      );
    else if (state.onDemand) {
      if (!pathData.stopUseListening)
        pathData.stopUseListening = state.onUseChanged(usedChangeHandler);
      else if (initUse && state.used && this.channel.out.isOpen) {
        /* Re-initialize the use state */
        this.channel.out.send<StateUseMessage>({
          type: StateMessageType.UpdateUse,
          path: path,
          used: true,
        });
      }
    } else if (pathData.stopUseListening) {
      pathData.stopUseListening();
      delete pathData.stopUseListening;
    }
  }

  private channel: BiChannel;
  readonly state: State;
  private paths = new Map<string, { stopUseListening?: StopListening }>();
}
