import { Interweave, InterweaveProps, Node, NodeConfig, TransformCallback } from 'interweave';
import React, { useContext } from 'react';

// Context for use with plugins
export type InterweaveExtContext = { interweaveFactory: InterweaveExtFactory };

// Element of InterweaveExtFactory
export type InterweaveExtFactoryElement = (props: any) => JSX.Element; // eslint-disable-line @typescript-eslint/no-explicit-any
/** Factory for elements that shall be supported by InterweaveExt
 *  (provide via React context InterweaveExtFactoryContext)
 */

export class InterweaveExtFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register(tagName: string, elem: (props: any) => JSX.Element) {
    this.registry[tagName.toLowerCase()] = elem;
  }

  get(nodeName: string) {
    return this.registry[nodeName];
  }

  private registry: Record<string, InterweaveExtFactoryElement> = {};
}

function replaceWithRegistered(
  node: HTMLElement,
  children: Node[],
  factory: InterweaveExtFactory | undefined
): React.ReactNode {
  const nodeName = node.tagName.toLowerCase();
  const elem = factory?.get(nodeName);
  if (elem !== undefined) {
    const rs: Record<string, string | Node[]> = {};
    if (children !== undefined) {
      rs['children'] = children;
    }
    for (let i = 0; i < node.attributes.length; i++) {
      const attr = node.attributes.item(i);
      if (attr?.name && attr?.value) {
        rs[attr?.name] = attr?.value;
      }
    }
    return React.createElement(elem, rs);
  }

  return undefined; // Skip everything else
}

function composeTransform(
  otherTransform: TransformCallback | undefined | null,
  factory: InterweaveExtFactory | undefined
) {
  return (node: HTMLElement, children: Node[], config: NodeConfig): React.ReactNode => {
    let ret: React.ReactNode | undefined | null = undefined;
    if (otherTransform !== undefined && otherTransform !== null) {
      ret = otherTransform(node, children, config);
    }
    if (ret === undefined) {
      ret = replaceWithRegistered(node, children, factory);
    }
    return ret;
  };
}

/** Component which uses Interweav to render safely HTML,
 *
 * In addition to pure Interweave registered elementes provided via React
 * context InterweaveExtFactoryContext are substitutet as well.
 */
export function InterweaveExt(props: InterweaveProps) {
  const iwFactory = useContext(InterweaveExtFactoryContext);
  const effectiveProps = {
    ...props,
    ...{ transform: composeTransform(props.transform, iwFactory) },
  };
  return <Interweave {...effectiveProps} />;
}

export const InterweaveExtFactoryContext = React.createContext<InterweaveExtFactory | undefined>(
  undefined
);

export default InterweaveExt;
