import { FactoryProps } from '@sqior/react/factory';
import {
  Checkbox,
  ContainerWithShadow,
  HighlightButton,
  OnOffSwitch,
  SelectControl,
  SelectModel,
  useTextResources,
} from '@sqior/react/uibase';
import { SelectionAreaProps, SelectionPageContext } from '@sqior/react/uiselection';
import {
  TransportOrderInfectiousScale,
  TransportOrderInfectiousState,
  TransportOrderInput,
  TransportOrderLARE,
  TransportOrderParameters,
  TransportOrderParametersType,
} from '@sqior/viewmodels/patient';
import { useContext, useState } from 'react';
import styles from './transport-order-input-control.module.css';
import { TextField } from '@mui/material';
import { Entity } from '@sqior/js/entity';
import { ReactComponent as SearchIcon } from './search.svg';
import { addMinutes, isEqual, now } from '@sqior/js/data';
import { TimePicker } from '@mui/x-date-pickers';

type TransportOrderLocationInputInternal = { label: string; select?: Entity };
export type TransportOrderInputControlProps = FactoryProps<TransportOrderInput> &
  SelectionAreaProps;

export function TransportOrderInputControl(props: TransportOrderInputControlProps) {
  const textDict = useTextResources();
  const selContext = useContext(SelectionPageContext);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [comment, setComment] = useState('');
  /* Transfer the input data to a state as we are able to add options via the search function */
  const [fromData, setFromData] = useState<TransportOrderLocationInputInternal[]>(
    props.data.from.length ? props.data.from : [{ label: textDict.get('no_selection') }]
  );
  const [toData, setToData] = useState<TransportOrderLocationInputInternal[]>(
    props.data.to.length ? props.data.to : [{ label: textDict.get('no_selection') }]
  );
  const fromSelect: SelectModel = {
    items: fromData.map((loc) => {
      return loc.label;
    }),
    selected: from,
  };
  const toSelect: SelectModel = {
    items: toData.map((loc) => {
      return loc.label;
    }),
    selected: to,
  };
  const [prio, setPrio] = useState(true);
  const [infectious, setInfectious] = useState<TransportOrderInfectiousState | undefined>(
    props.data.infectiousDefault
  );
  /* Time range for pick-up time */
  const [startTime] = useState(new Date());
  const [entTime] = useState(() => {
    const now = new Date();
    now.setHours(23);
    now.setMinutes(59);
    return now;
  });
  /* Pick up time */
  const [pickUp, setPickUp] = useState<Date | undefined>();
  const onReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };
  return (
    <ContainerWithShadow className={styles['container']}>
      <div className={styles['inner-container']}>
        <div className={styles['header-container']}>
          <div className={styles['title']}>{textDict.get('pick_up_at')}</div>
          <div className={styles['location-container']}>
            <SelectControl
              className={styles['location']}
              data={fromSelect}
              onSelect={(value) => {
                setFrom(value);
              }}
            />
            {props.data.options && (
              <SearchIcon
                className={styles['search']}
                onClick={() => {
                  selContext(
                    {
                      entityType: 'TransportLocationSelection',
                      title: textDict.get('search_transport_start'),
                    },
                    (success, data) => {
                      if (success && data) {
                        const selected = data as TransportOrderLocationInputInternal;
                        /* Check if this entry is already contained - in this case just select it, otherwise add it to the front */
                        const existingIndex = fromData.findIndex((fd) => {
                          return isEqual(fd.select, selected.select);
                        });
                        if (existingIndex >= 0) setFrom(existingIndex);
                        else {
                          setFromData([selected].concat(fromData));
                          setFrom(0);
                        }
                      }
                    }
                  );
                }}
              />
            )}
          </div>
        </div>
        <div className={styles['header-container']}>
          <div className={styles['title']}>{textDict.get('transport_to')}</div>
          <div className={styles['location-container']}>
            <SelectControl
              className={styles['location']}
              data={toSelect}
              onSelect={(value) => {
                setTo(value);
              }}
            />
            {props.data.options && (
              <SearchIcon
                className={styles['search']}
                onClick={() => {
                  selContext(
                    {
                      entityType: 'TransportLocationSelection',
                      title: textDict.get('search_transport_target'),
                    },
                    (success, data) => {
                      if (success && data) {
                        const selected = data as TransportOrderLocationInputInternal;
                        /* Check if this entry is already contained - in this case just select it, otherwise add it to the front */
                        const existingIndex = toData.findIndex((fd) => {
                          return isEqual(fd.select, selected.select);
                        });
                        if (existingIndex >= 0) setTo(existingIndex);
                        else {
                          setToData([selected].concat(toData));
                          setTo(0);
                        }
                      }
                    }
                  );
                }}
              />
            )}
          </div>
        </div>
        {props.data.options && (
          <div className={styles['option-container']}>
            <div className={styles['option-title']}>{textDict.get('transport_priority')}</div>
            <OnOffSwitch
              active={prio}
              onChanged={(active) => {
                setPrio(active);
                setPickUp(!active ? new Date(addMinutes(10, now())) : undefined);
              }}
            />
          </div>
        )}
        {props.data.options && pickUp && (
          <div className={styles['option-container']}>
            <div className={styles['option-title']}>{textDict.get('timestamp')}</div>
            <TimePicker
              label={textDict.get('time_at')}
              value={pickUp}
              minTime={startTime}
              maxTime={entTime}
              onChange={(value) => {
                if (value) setPickUp(value);
              }}
              sx={{
                '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'yellow',
                  },
              }}
            />
          </div>
        )}

        {props.data.infectiousScale === TransportOrderInfectiousScale.YesNo && (
          <div className={styles['option-container']}>
            <div className={styles['option-title']}>{textDict.get('infectious')}</div>
            <OnOffSwitch
              active={!!infectious}
              onChanged={(active) => {
                setInfectious(active);
              }}
            />
          </div>
        )}
        {props.data.infectiousScale === TransportOrderInfectiousScale.LARE && (
          <div className={styles['option-container']}>
            <div className={styles['option-title-lare']}>LARE</div>
            {['A', 'B', 'C', 'D'].map((item) => (
              <Checkbox
                checked={infectious === 'LARE-' + item}
                label={item}
                onChanged={(checked) =>
                  setInfectious(checked ? (('LARE-' + item) as TransportOrderLARE) : false)
                }
              />
            ))}
          </div>
        )}
        {props.data.options && (
          <TextField
            variant="outlined"
            value={comment}
            onChange={onReasonChange}
            label="Kommentar [optional]"
            multiline
            maxRows={4}
          />
        )}
        <div className={styles['button-container']}>
          <HighlightButton
            disabled={
              !fromData[from]?.select ||
              !toData[to]?.select ||
              isEqual(fromData[from]?.select, toData[to]?.select) ||
              (!infectious && props.data.infectiousScale === TransportOrderInfectiousScale.LARE)
            }
            onClick={() => {
              const fromSelect = fromData[from]?.select;
              const toSelect = toData[to]?.select;
              if (!fromSelect || !toSelect) return;
              const params: TransportOrderParameters = {
                entityType: TransportOrderParametersType,
                from: fromSelect,
                to: toSelect,
                priority: prio ? 5 : 4,
                infectious: infectious ?? false,
                comment,
              };
              if (pickUp) params.pickUpTimestamp = pickUp.getTime();
              if (props.data.related) params.related = props.data.related;
              if (props.onSelection) props.onSelection({ entity: params });
            }}
          >
            {textDict.get('order')}
          </HighlightButton>
        </div>
      </div>
    </ContainerWithShadow>
  );
}

export default TransportOrderInputControl;
