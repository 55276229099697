import {
  getSelectionOptionMixIn,
  SelectionFromOptions,
  SelectionInput,
} from '@sqior/viewmodels/input';
import { FactoryProps } from '@sqior/react/factory';
import { SelectionAreaProps } from '../selection-area';
import styles from './options-input-area.module.css';
import { ValueObject } from '@sqior/js/data';
import { useState } from 'react';

type OptionsInputAreaProps = FactoryProps<SelectionFromOptions> & SelectionAreaProps;

export function OptionsInputArea(props: OptionsInputAreaProps) {
  const [curData, setCurData] = useState<SelectionFromOptions | undefined>();
  const data = curData ?? props.data;

  return (
    <div className={styles['container']}>
      <OptionsInput
        options={data.options}
        onClick={(v) => props.onSelection?.(v)}
        newSelection={(selection) => setCurData(selection)}
      />
    </div>
  );
}

export type OptionsInputParams = {
  options: SelectionInput[];
  onClick: (option: ValueObject, completeOption?: SelectionInput) => void;
  newSelection?: (options: SelectionFromOptions) => void;
};
export function OptionsInput(props: OptionsInputParams) {
  return (
    <>
      {props.options.map((option, index) => (
        <button
          className={styles[index > 0 ? 'option' : 'option-top']}
          onClick={() => {
            if (option.selection && props.newSelection) {
              // Display next selection level
              if (option.selection.options.length === 1) {
                // except there is just one item to select => auto-select
                props.onClick(getSelectionOptionMixIn(option.selection.options[0]), option);
              } else {
                props.newSelection(option.selection);
              }
            } else {
              props.onClick(getSelectionOptionMixIn(option), option);
            }
          }}
          key={option.visual}
        >
          <div className={styles['option-text']}>{option.visual}</div>
        </button>
      ))}
    </>
  );
}

export default OptionsInputArea;
