import { useState, useEffect } from 'react';

export interface UseZoomLevelProps {
  onChange?: (zoomLevel: number) => void;
}

export const useZoomLevel = ({ onChange }: UseZoomLevelProps) => {
  const [zoomLevel, setZoomLevel] = useState<number>(round(window.devicePixelRatio));

  useEffect(() => {
    const updateZoomLevel = () => {
      const newZoomLevel = round(window.devicePixelRatio);
      setZoomLevel(newZoomLevel);
      onChange?.(newZoomLevel);
    };

    window.addEventListener('resize', updateZoomLevel);
    return () => {
      window.removeEventListener('resize', updateZoomLevel);
    };
  }, []);

  return zoomLevel;
};

export default useZoomLevel;

const round = (value: number) => Math.round(value * 100) / 100;
