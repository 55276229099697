import { StateOverlayContext } from '@sqior/js/state-operation';
import {
  AppVersionOverlay,
  AppVersionUpdateConfirmationPath,
  AppVersionUpdatePath,
} from '@sqior/viewmodels/app';
import { PhoneNumberEntryOverlay, PhoneNumberEntryPath } from '@sqior/viewmodels/device';

export function UIAppPlugin(context: StateOverlayContext) {
  /* Register overlays */
  context.stateOverlays.add(
    AppVersionUpdateConfirmationPath,
    AppVersionOverlay,
    AppVersionUpdatePath
  );
  context.stateOverlays.add(PhoneNumberEntryPath, PhoneNumberEntryOverlay, PhoneNumberEntryPath);
}
