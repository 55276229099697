import { AddOperation, OperationFactory, OperationState } from '@sqior/js/operation';
import { QRScannerOperationControl } from '@sqior/react/qrcode';
import { PairPhone, UserDevice } from '@sqior/viewmodels/app';
import styles from './qr-scanner-pairing-page.module.css';
import { SqiorNativeAppVersion, SqiorNativeAppVersionType } from '@sqior/react/uiapp';
import { lastElement } from '@sqior/js/data';

export type QrPairingPageProperties = {
  tokenCallback: (s: string) => void;
};

function createUserDevice(info: SqiorNativeAppVersionType): UserDevice {
  const userDevice: UserDevice = {};
  if (info?.model) userDevice.model = info.model;
  if (info?.manufacturer) userDevice.manufacturer = info.manufacturer;
  if (info?.deviceName) userDevice.deviceName = info.deviceName;
  return userDevice;
}

export function QrPairingPage(props: QrPairingPageProperties) {
  function operationCreator(code: string): { op: AddOperation; path: string } {
    const device = createUserDevice(SqiorNativeAppVersion as SqiorNativeAppVersionType);
    /* Determine public customer name from path */
    const paths = window.location.pathname.split('/').filter((p) => {
      return !!p;
    });
    const opSpec = PairPhone(code, lastElement(paths) ?? '', device);
    const op = OperationFactory.create(opSpec.type, opSpec.data) as AddOperation;
    op.stateChange.on((state) => operationCallback(state, op));
    return { op, path: opSpec.path };
  }

  function operationCallback(state: OperationState, addOperation: AddOperation) {
    if (state === OperationState.Completed && addOperation.finalId)
      props.tokenCallback(addOperation.finalId); // saving token to storage
  }

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>QR-Code scannen</div>
      <QRScannerOperationControl operationCreator={operationCreator} />
      <div className={styles['description']}>
        Melde dich bei sqior auf deinem Desktop oder Diensttelefon an und wähle Menü → verknüpfte
        Geräte → Neues Gerät hinzufügen.
        <br />
        Scanne den QR-Code, um dein Gerät zu verknüpfen.
      </div>
    </div>
  );
}

export default QrPairingPage;
