import axios from 'axios';
import { OrgPackages } from './license-info';

export async function fetchLicenses(
  baseUrl: string,
  licenseFiles: string[] = [
    'licenses/license-license-checker.json',
    'licenses/license-manually-added.json',
  ]
): Promise<OrgPackages | undefined> {
  async function getLicense(path: string) {
    try {
      const rsp = await axios.get(path);
      return rsp.data as OrgPackages;
    } catch (e) {
      console.error(`Cannot read license information from: ${path}`, e);
      return undefined;
    }
  }

  let licenses: OrgPackages = {};
  for (const path of licenseFiles) {
    const newLicenses = await getLicense(baseUrl + path);
    licenses = { ...licenses, ...newLicenses };
  }

  return licenses;
}
