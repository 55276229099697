export enum Comparison {
  Less = -1,
  Equal = 0,
  Greater = 1,
}

export function compareProperty(
  first: Record<string, unknown>,
  second: Record<string, unknown>,
  key: string
) {
  const firstValue = first[key] as string | number | boolean;
  const secondValue = second[key] as string | number | boolean;
  if (firstValue < secondValue || (firstValue === undefined && secondValue !== undefined))
    return Comparison.Less;
  else if (firstValue > secondValue || (firstValue !== undefined && secondValue === undefined))
    return Comparison.Greater;
  else return Comparison.Equal;
}

/* Comparison predicate for the sort method */

export function ascendingSortPredicate<Type>(a: Type, b: Type) {
  if (a < b) return -1;
  else if (a > b) return 1;
  else return 0;
}
export function descendingSortPredicate<Type>(a: Type, b: Type) {
  if (a < b) return 1;
  else if (a > b) return -1;
  else return 0;
}
