import styles from './metric-card-header.module.css';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SIcon } from '@sqior/react/uibase';
import { Icons } from '@sqior/viewmodels/visual';
import { ReactNode } from 'react';

export interface MetricCardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle?: string;
  legend?: React.ReactNode;
  hideSettings?: boolean;
  link?: boolean;
  onSettingsClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltip?: ReactNode;
}

export function MetricCardHeader({
  title,
  link,
  subTitle,
  hideSettings,
  legend,
  onSettingsClick,
  tooltip,
  ...rest
}: MetricCardHeaderProps) {
  return (
    <div className={styles['title-container']} {...rest}>
      {/*<SqiorTooltip title={tooltip}>*/}
      <div>
        <div className={styles['title-row']}>
          <div className={styles['title']}>{title}</div>
          {link && <SIcon icon={Icons.ForwardArrow} size={15} />}
        </div>

        {subTitle && <div className={styles['sub-title']}>{subTitle}</div>}
      </div>
      {/*</SqiorTooltip>*/}

      {onSettingsClick && (
        <div className={styles['legend-more-button']}>
          <div className={styles['legend-container']}>{legend}</div>

          <div className={styles['more-icon']}>
            <IconButton onClick={onSettingsClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default MetricCardHeader;
