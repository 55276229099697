import styles from './sqior-bed-line-view.module.css';
import React, { FC } from 'react';

export interface SqiorBedLineViewProps {
  backgroundColor: string;
  visibility?: boolean;
}

export const SqiorBedLineView: FC<SqiorBedLineViewProps> = ({ backgroundColor, visibility }) => {
  if (visibility === false) return null;
  return (
    <div
      className={styles['container']}
      style={{
        backgroundColor,
      }}
    />
  );
};

export default SqiorBedLineView;
