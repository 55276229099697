import { Emitter } from '@sqior/js/event';
import { AddOperation } from '@sqior/js/operation';
import { FacingMode, QRScannerOperationControl } from '@sqior/react/qrcode';

/* eslint-disable-next-line */
export interface QRVideoControlProps {
  onClose: () => void;
  takePhoto?: Emitter;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  facing?: FacingMode;
  onFacing?: (facing: FacingMode) => void;
}

export function QRVideoControl(props: QRVideoControlProps) {
  return (
    <QRScannerOperationControl
      takePhoto={props.takePhoto}
      onPhoto={props.onPhoto}
      facing={props.facing}
      onFacing={props.onFacing}
      onClose={props.onClose}
      operationCreator={(code) => {
        return {
          op: new AddOperation(code),
          path: 'qr-code',
        };
      }}
    />
  );
}

export default QRVideoControl;
