import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

export enum AppStyle {
  Messenger,
  Combined,
  Dashboard,
  ParallelPhoneDemo,
  Analytics,
}

interface AppStyleContextType {
  appStyle: AppStyle;
  setAppStyle: Dispatch<SetStateAction<AppStyle>>;
}

const AppStyleContext = createContext<AppStyleContextType | undefined>(undefined);

interface AppStyleProviderProps {
  children: ReactNode;
}

const params = new URLSearchParams(window.location.search);

export const AppStyleProvider: React.FC<AppStyleProviderProps> = ({ children }) => {
  const kiosk = params.get('kiosk');
  const [appStyle, setAppStyle] = useState<AppStyle>(decideAppStyle({ kiosk: !!kiosk }));

  useEffect(() => {
    function onChange() {
      const appSt = decideAppStyle({
        kiosk: !!kiosk,
        currStyle: appStyle,
      });

      setAppStyle(appSt);
    }

    window.addEventListener('resize', onChange);
    if (window.screen.orientation !== undefined)
      window.screen.orientation.addEventListener('orientationchange', onChange);
    else if (window.orientation !== undefined)
      window.addEventListener('orientationchange', onChange);

    return () => {
      if (window.screen.orientation !== undefined)
        window.screen.orientation.removeEventListener('orientationchange', onChange);
      else if (window.orientation !== undefined)
        window.removeEventListener('orientationchange', onChange);
      window.removeEventListener('resize', onChange);
    };
  }, [kiosk, appStyle]);

  return (
    <AppStyleContext.Provider value={{ appStyle, setAppStyle }}>
      {children}
    </AppStyleContext.Provider>
  );
};

// Custom hook to use the AppStyleContext
export const useAppStyle = (): AppStyleContextType => {
  const context = useContext(AppStyleContext);
  if (context === undefined) {
    throw new Error('useAppStyle must be used within an AppStyleProvider');
  }
  return context;
};

interface AppStyleProps {
  kiosk: boolean;
  currStyle?: AppStyle;
}

/** Selects the desired layout of the application */
export function decideAppStyle({ kiosk, currStyle }: AppStyleProps): AppStyle {
  if (kiosk) return AppStyle.Dashboard;

  if (window.innerWidth > 600) {
    if (currStyle === AppStyle.Analytics) return AppStyle.Analytics;
    if (currStyle === undefined || currStyle === AppStyle.Messenger) return AppStyle.Combined;
    else return currStyle;
  }

  return AppStyle.Messenger;
}
