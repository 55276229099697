import { FactoryProps } from '@sqior/react/factory';
import styles from './TransportLocationSelectionControl.module.css';
import {
  TransportLocationSearch,
  TransportLocationSearchPath,
  TransportLocationSearchResult,
  TransportLocationSelection,
} from '@sqior/viewmodels/patient';
import { ContainerWithShadow, TitlePage, useTextResources } from '@sqior/react/uibase';
import { InputControl } from '@sqior/react/uibase';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { StreamOperation, isFinal } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import { useDynamicState } from '@sqior/react/state';
import { ReactComponent as TransportLocationIcon } from './transport-location.svg';

export type TransportLocationSelectionControlProps = FactoryProps<TransportLocationSelection>;

export function TransportLocationSelectionControl(props: TransportLocationSelectionControlProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  const [search, setSearch] = useState('');
  const results = useDynamicState<TransportLocationSearchResult[]>(TransportLocationSearchPath, []);
  /* Stream operation for searching the locations */
  const [streamOp, setStreamOp] = useState<StreamOperation>();
  /* Stop pot. running operation when this gets destroyed */
  useEffect(() => {
    if (streamOp) {
      return () => {
        streamOp.close();
      };
    } else return;
  }, [streamOp]);
  /* Starts a search */
  const doSearch = (text: string) => {
    /* Set the search state */
    setSearch(text);
    /* Start a new stream operation if there is no active one */
    if (!streamOp || isFinal(streamOp.state)) {
      const op = dispatcher.start(TransportLocationSearch) as StreamOperation;
      op.send(text);
      setStreamOp(op);
    } else streamOp.send(text);
  };
  return (
    <TitlePage
      title={props.data.title}
      onClose={() => {
        props.onClose?.(false);
      }}
    >
      <div className={styles['container']}>
        <InputControl
          className={styles['input']}
          value={search}
          placeholder={textDict.get('input_search_term')}
          autoFocus={true}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            doSearch(ev.currentTarget.value);
          }}
        />
        <ContainerWithShadow className={styles['result-container']}>
          <div className={styles['inner-result-container']}>
            {results.map((res) => (
              <div
                key={res.id}
                className={styles['result']}
                onClick={() => {
                  props.onClose?.(true, res);
                }}
              >
                <TransportLocationIcon className={styles['icon']} />
                <div className={styles['result-text']}>{res.label}</div>
              </div>
            ))}
          </div>
        </ContainerWithShadow>
      </div>
    </TitlePage>
  );
}

export default TransportLocationSelectionControl;
