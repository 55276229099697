import { Logger } from '@sqior/js/log';
import { WebSocketIF, MessageEvent } from '@sqior/js/wsbase';

/** Implementation of WebSocketIF for WebSocket implementation in web-browsers (JSDOM)
 */
export class WebSocketImpl implements WebSocketIF {
  constructor(url: string) {
    this.socket = new WebSocket(url);
    this.socket.onopen = (ev) => {
      console.log('websocketimpl onopen', JSON.stringify(ev));
      this.onopen?.();
    };
    this.socket.onerror = (ev) => {
      console.log(['Websocket reported error:', JSON.stringify(ev)]);
      this.onerror?.();
    };
    this.socket.onclose = (ev) => {
      Logger.debug(['Websocket closed with code:', ev.code, ' - reason:', ev.reason]);
      this.onclose?.();
    };
    this.socket.onmessage = (ev) => {
      this.onmessage?.({ data: ev.data });
    };
  }

  close(code?: number, data?: string): void {
    this.socket.close(code, data);
  }

  send(data: any): void {
    this.socket.send(data);
  }

  onopen?: () => void;
  onerror?: () => void;
  onclose?: () => void;
  onmessage?: (event: MessageEvent) => void;

  socket: WebSocket;
}
