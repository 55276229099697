import { ClockTimestamp } from '@sqior/js/data';
import { Entity, EntityHeader, IdEntity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { ConfirmCredentials } from '@sqior/viewmodels/user';
import { URLEntity } from '@sqior/viewmodels/visual';

export enum ChecklistOptionSentiment {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export type ChecklistOptionViewModel = {
  text: string;
  sentiment: ChecklistOptionSentiment;
  checked: boolean;
  feedbackText?: string;
  selection?: Entity;
  timestamp?: ClockTimestamp;
};

export enum ChecklistItemBlocking {
  Always = 'always', // Negative responses will block the release
  Comment = 'comment', // Negative responses will only block the release if there is no comment provided
  Never = 'never', // Negative responses will not block the release
}

export type ChecklistAttachmentVM<IdType = IdEntity, URLType = URLEntity> = {
  ref: IdType;
  download: URLType;
};

export type ChecklistItemViewModel<IdType = IdEntity, URLType = URLEntity> = {
  text: string;
  options: ChecklistOptionViewModel[];
  blocking: ChecklistItemBlocking;
  comment?: string;
  attachments: ChecklistAttachmentVM<IdType, URLType>[];
};

export type ChecklistParagraphViewModel<IdType = IdEntity, URLType = URLEntity> = {
  header: string;
  items: ChecklistItemViewModel<IdType, URLType>[];
};

export enum ChecklistConfirmRole {
  None = 'none',
  Confirm = 'confirm',
  Prep = 'prep',
  Substitute = 'substitute',
  Undo = 'undo',
}
export type ChecklistSectionCommentVM = {
  text: string;
  user: Entity;
  userName: string;
  timestamp: ClockTimestamp;
  undoPossible: boolean;
};
export type ChecklistSectionViewModel<IdType = IdEntity, URLType = URLEntity> = {
  title?: string;
  paragraphs: ChecklistParagraphViewModel<IdType, URLType>[];
  attachments: ChecklistAttachmentVM<IdType, URLType>[];
  comments: ChecklistSectionCommentVM[];
  completed: boolean;
  confirmPossible: boolean;
  prepped: boolean;
  confirmRole: ChecklistConfirmRole;
  confirmTitle?: string;
  confirmText?: string;
  user?: string;
  timestamp?: ClockTimestamp;
};

export const ChecklistViewModelType = 'ChecklistViewModel';

export type ChecklistViewModel<IdType = IdEntity, URLType = URLEntity> = EntityHeader & {
  ref: Entity;
  title?: string;
  sections: ChecklistSectionViewModel<IdType, URLType>[];
  documentURL?: URLType;
};

/* Command setting a check of the check-list */

export const ChecklistPath = 'checklist';
export const SetChecklistSubPath = 'set';
export const SetChecklistPath = joinPath(ChecklistPath, SetChecklistSubPath);

export type SetChecklistPhotoType = string;
export type ChecklistSetData =
  | { option: number; check: boolean; value?: Entity }
  | { comment: string }
  | { photos: SetChecklistPhotoType[] };
export type ChecklistSetInfoBase = {
  ref: Entity; // Reference to workflow
  section: number; // Index of the section containing the item to set
  paragraph: number; // Index of the paragraph inside the section containing the item to set
  item: number; // Index of the item in the paragraph to set
};

export type ChecklistSetInfo = ChecklistSetInfoBase & {
  option: number; // Index of the item option to set
  check: boolean; // Flag whether to set or reset the item
  value?: Entity; // Optional value associated with option
};

export function SetChecklistItem(
  ref: Entity,
  section: number,
  paragraph: number,
  item: number,
  option: number,
  check: boolean,
  value?: Entity
): OperationSpec<ChecklistSetInfo> {
  const res: OperationSpec<ChecklistSetInfo> = {
    path: SetChecklistPath,
    type: OperationType.Add,
    data: {
      ref,
      section,
      paragraph,
      item,
      option,
      check,
    },
  };
  if (value) res.data.value = value;
  return res;
}

/* Command setting the comment of a checklist item */

export const SetChecklistCommentSubPath = 'comment';
export const SetChecklistCommentPath = joinPath(ChecklistPath, SetChecklistCommentSubPath);

export type ChecklistSetCommentInfo = ChecklistSetInfoBase & {
  comment: string; // Comment text to set
};

export function SetChecklistItemComment(
  ref: Entity,
  section: number,
  paragraph: number,
  item: number,
  comment: string
): OperationSpec<ChecklistSetCommentInfo> {
  return {
    path: SetChecklistCommentPath,
    type: OperationType.Add,
    data: {
      ref,
      section,
      paragraph,
      item,
      comment,
    },
  };
}

/* Command setting the photos of a checklist item */

export const SetChecklistPhotosSubPath = 'photos';
export const SetChecklistPhotosPath = joinPath(ChecklistPath, SetChecklistPhotosSubPath);

export type ChecklistSetPhotoInfo = ChecklistSetInfoBase & {
  photos: SetChecklistPhotoType[]; // Media ID's of the photos
};

export function SetChecklistItemPhotos(
  ref: Entity,
  section: number,
  paragraph: number,
  item: number,
  photos: SetChecklistPhotoType[]
): OperationSpec<ChecklistSetPhotoInfo> {
  return {
    path: SetChecklistPhotosPath,
    type: OperationType.Add,
    data: {
      ref,
      section,
      paragraph,
      item,
      photos,
    },
  };
}

/** Section commands */

export type ChecklistSetSectionBase = {
  ref: Entity; // Reference to workflow
  section: number; // Index of the section to address
};

/* Command confirming a section of the checklist */

export const ConfirmChecklistSubPath = 'confirm';
export const ConfirmChecklistPath = joinPath(ChecklistPath, ConfirmChecklistSubPath);

export type ChecklistConfirmInfo = ChecklistSetSectionBase & {
  role: ChecklistConfirmRole; // Role confirming
  credentials?: ConfirmCredentials; // Credentials confirming the authenticity
};

export function ConfirmChecklistSection(
  ref: Entity,
  section: number,
  role: ChecklistConfirmRole,
  credentials?: ConfirmCredentials
): OperationSpec<ChecklistConfirmInfo> {
  const res: OperationSpec<ChecklistConfirmInfo> = {
    path: ConfirmChecklistPath,
    type: OperationType.Add,
    data: { ref, section, role },
  };
  if (credentials) res.data.credentials = credentials;
  return res;
}

/* Command undoing the confirmation of a section of the checklist */

export const UndoChecklistSubPath = 'undo';
export const UndoChecklistPath = joinPath(ChecklistPath, UndoChecklistSubPath);

export type ChecklistUndoInfo = ChecklistSetSectionBase & {
  prep: boolean; // Flag if a preparation or completion shall be undone
};

export function UndoChecklistSection(
  ref: Entity,
  section: number,
  prep: boolean
): OperationSpec<ChecklistUndoInfo> {
  return {
    path: UndoChecklistPath,
    type: OperationType.Add,
    data: { ref, section, prep },
  };
}

/* Command undoing a section comment of the checklist */

export const UndoChecklistCommentSubPath = 'undoComment';
export const UndoChecklistCommentPath = joinPath(ChecklistPath, UndoChecklistCommentSubPath);

export type ChecklistUndoCommentInfo = ChecklistSetSectionBase & {
  text: string; // Text of the entry to undo
};

export function UndoChecklistSectionComment(
  ref: Entity,
  section: number,
  text: string
): OperationSpec<ChecklistUndoCommentInfo> {
  return {
    path: UndoChecklistCommentPath,
    type: OperationType.Add,
    data: { ref, section, text },
  };
}

/** Command setting photos of a section */

export const SetChecklistSectionPhotosSubPath = 'section-photos';
export const SetChecklistSectionPhotosPath = joinPath(
  ChecklistPath,
  SetChecklistSectionPhotosSubPath
);

export type ChecklistSetSectionPhotos = ChecklistSetSectionBase & {
  photos: SetChecklistPhotoType[]; // Media ID's of the photos
};

export function SetChecklistSectionPhotos(
  ref: Entity,
  section: number,
  photos: SetChecklistPhotoType[]
): OperationSpec<ChecklistSetSectionPhotos> {
  return {
    path: SetChecklistSectionPhotosPath,
    type: OperationType.Add,
    data: {
      ref,
      section,
      photos,
    },
  };
}

/* Command setting the comment of a checklist item */

export const AddChecklistSectionCommentSubPath = 'section-comment';
export const AddChecklistCommentPath = joinPath(ChecklistPath, AddChecklistSectionCommentSubPath);

export type ChecklistAddSectionCommentInfo = ChecklistSetSectionBase & {
  comment: string; // Comment text to add
};

export function AddChecklistSectionComment(
  ref: Entity,
  section: number,
  comment: string
): OperationSpec<ChecklistAddSectionCommentInfo> {
  return {
    path: AddChecklistCommentPath,
    type: OperationType.Add,
    data: { ref, section, comment },
  };
}
