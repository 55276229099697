import {
  FileAttachment,
  FileAttachmentType,
  HighlightButton,
  ImageMimeType,
  SqiorFileUpload,
  SqiorFileUploadGallery,
  SvgComment,
  SvgIconSignature,
  useTextResources,
} from '@sqior/react/uibase';
import {
  ChecklistConfirmRole,
  ChecklistSectionViewModel,
  ChecklistSetData,
  SetChecklistPhotoType,
} from '@sqior/viewmodels/checklist';
import ChecklistParagraphControl from '../checklist-paragraph-control/checklist-paragraph-control';
import styles from './checklist-section-control.module.css';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { ConfigContext, getPrettyTimeOrDayString } from '@sqior/react/utils';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { ConfirmCredentials } from '@sqior/viewmodels/user';
import { ReactComponent as IconUndo } from './undo.svg';
import { ReactComponent as IconChecked } from './checked.svg';
import { SvgIconExpandLess, SvgIconExpandMore } from '@sqior/react/uibase';
import { SelectionEntities, SelectionMenuType } from '@sqior/viewmodels/input';
import { Emitter } from '@sqior/js/event';
import { Divider } from '@mui/material';

export type ChecklistSectionControlProps = {
  data: ChecklistSectionViewModel;
  select: (paragraph: number | undefined, item: number | undefined, data: ChecklistSetData) => void;
  confirm: (role: ChecklistConfirmRole, credentials?: ConfirmCredentials) => void;
  undo: (prep: boolean) => void;
  undoComment: (text: string) => void;
};

export function ChecklistSectionControl(props: ChecklistSectionControlProps) {
  const textDict = useTextResources();
  const configContext = useContext(ConfigContext);
  const selContext = useContext(SelectionPageContext);
  const confirmRole = props.data.confirmRole;
  const attachments = props.data.attachments;
  const [fold, setFold] = useState(
    props.data.completed || (confirmRole === ChecklistConfirmRole.Substitute && !attachments.length)
  );
  /* Photo trigger signal */
  const photoTrigger = useRef(new Emitter());
  /* Performs the upload if attachments get created */
  const [createdAttachments, setCreatedAttachments] = useState<FileAttachment[]>([]);
  const selectFunc = props.select;
  useEffect(() => {
    /* Set the attachment information */
    const photos: SetChecklistPhotoType[] = [];
    for (const ca of createdAttachments) if (ca.finalId) photos.push(ca.finalId);
    if (photos.length)
      selectFunc(undefined, undefined, {
        photos,
      });
  }, [createdAttachments, selectFunc]);
  /* Uploaded attachments */
  const uploadedAttachments: FileAttachment[] = props.data.attachments.map((am) => {
    return {
      id: am.ref.id,
      type: FileAttachmentType.image,
      mimeType: ImageMimeType.jpeg,
      imageURL: configContext.getEndpoint(am.download.url).toString(),
      blob: null,
    };
  });
  /* Fold if uploaded attachments are removed */
  useEffect(() => {
    if (!attachments.length && confirmRole === ChecklistConfirmRole.Substitute) setFold(true);
    else if (attachments.length && confirmRole === ChecklistConfirmRole.Substitute) {
      setFold(false);
      setCreatedAttachments([]);
    }
  }, [attachments, setFold, confirmRole]);
  return (
    <div className={styles['container']}>
      <div className={styles['title-paragraph']}>
        {props.data.title && <div className={styles['title']}>{props.data.title}</div>}
        {!createdAttachments.length && !uploadedAttachments.length && (
          <div className={styles['paragraph-container']}>
            {props.data.paragraphs.map((paragraph, index) => (
              <Fragment key={paragraph.header}>
                <ChecklistParagraphControl
                  data={paragraph}
                  first={!index && props.data.title !== undefined}
                  select={(item, data) => {
                    props.select(index, item, data);
                  }}
                  readOnly={
                    confirmRole !== ChecklistConfirmRole.Confirm &&
                    (confirmRole !== ChecklistConfirmRole.Prep || props.data.prepped)
                  }
                  completed={props.data.completed}
                  initFold={confirmRole === ChecklistConfirmRole.Substitute ? true : undefined}
                  fold={index + 1 >= props.data.paragraphs.length ? fold : undefined}
                  setFold={index + 1 >= props.data.paragraphs.length ? setFold : undefined}
                />
                {index + 1 < props.data.paragraphs.length && <div className={styles['divider']} />}
              </Fragment>
            ))}
          </div>
        )}
      </div>
      {(createdAttachments.length > 0 || uploadedAttachments.length > 0) && (
        <div className={styles['substitute-paragraph-container']}>
          <div
            className={styles['substitute-paragraph-header-container']}
            onClick={() => {
              setFold(!fold);
            }}
          >
            <div className={styles['substitute-paragraph-text-container']}>
              {props.data.paragraphs.map((paragraph, index) => (
                <div
                  key={index}
                  className={
                    styles[
                      fold && uploadedAttachments.length > 0
                        ? 'substitute-paragraph-completed-text'
                        : 'substitute-paragraph-text'
                    ]
                  }
                >
                  {paragraph.header}
                </div>
              ))}
            </div>
            {fold ? (
              <SvgIconExpandMore className={styles['more-button']} />
            ) : (
              <SvgIconExpandLess className={styles['less-button']} />
            )}
          </div>
          {!fold && <div className={styles['shadow-bottom']} />}
        </div>
      )}
      {confirmRole === ChecklistConfirmRole.Substitute && !uploadedAttachments.length && (
        <div
          className={styles['subtitute-take-photo-container']}
          onClick={() => {
            photoTrigger.current.emit();
          }}
        >
          <div className={styles['subtitute-take-photo-text']}>
            {textDict.get('scan_checklist')}
          </div>
          {!createdAttachments.length && (
            <SqiorFileUpload
              setAttachments={setCreatedAttachments}
              iconClassName={styles['camera-icon']}
              trigger={photoTrigger.current}
            />
          )}
          {createdAttachments.length > 0 && (
            <SqiorFileUploadGallery
              attachments={createdAttachments}
              removeAttachment={(id) => {
                /* Remove from created attachments */
                setCreatedAttachments(
                  createdAttachments.filter((ca) => {
                    return ca.id !== id;
                  })
                );
              }}
              containerClassName={styles['created-image-container']}
              itemWrapperClassName={styles['created-image']}
              itemClassName={styles['created-image']}
            />
          )}
        </div>
      )}
      {!!uploadedAttachments.length && !fold && (
        <SqiorFileUploadGallery
          attachments={uploadedAttachments}
          removeAttachment={(id) => {
            setCreatedAttachments([]);
            selectFunc(undefined, undefined, {
              photos: [],
            });
          }}
          containerClassName={styles['uploaded-image-container']}
          itemWrapperClassName={styles['uploaded-image-wrapper']}
          itemClassName={styles['uploaded-image']}
          readonly={props.data.completed}
        />
      )}
      {(confirmRole === ChecklistConfirmRole.Confirm ||
        confirmRole === ChecklistConfirmRole.Substitute ||
        (confirmRole === ChecklistConfirmRole.Prep && !props.data.prepped)) && (
        <div className={styles['confirm-container']}>
          {props.data.confirmTitle && (
            <div className={styles['confirm-title']}>{props.data.confirmTitle}</div>
          )}
          {props.data.confirmText && (
            <div
              className={styles[props.data.confirmPossible ? 'confirmActive' : 'confirmDisabled']}
            >
              {props.data.confirmText}
            </div>
          )}
          <div className={styles['confirm-button']}>
            <HighlightButton
              disabled={!props.data.confirmPossible}
              onClick={() => {
                if (confirmRole === ChecklistConfirmRole.Confirm)
                  selContext(
                    { entityType: 'ConfirmIdentityPage', title: 'Identität bestätigen' },
                    (ok, data) => {
                      if (ok) props.confirm(confirmRole, data as ConfirmCredentials);
                    }
                  );
                else props.confirm(confirmRole);
              }}
            >
              {textDict.get(
                confirmRole === ChecklistConfirmRole.Confirm
                  ? 'grant_permission'
                  : confirmRole === ChecklistConfirmRole.Substitute
                  ? 'confirm'
                  : 'forward_grant_permission'
              )}
            </HighlightButton>
          </div>
        </div>
      )}
      {props.data.completed && !fold && props.data.user && (
        <div className={styles['forensic']}>
          {!props.data.attachments.length && <SvgIconSignature />}
          {!!props.data.attachments.length && <IconChecked className={styles['checked-icon']} />}
          {props.data.user && <div>{props.data.user + ','}</div>}
          {props.data.timestamp && <div>{getPrettyTimeOrDayString(props.data.timestamp)}</div>}
          {confirmRole === ChecklistConfirmRole.Undo && (
            <IconUndo
              className={styles['undo']}
              onClick={() => {
                props.undo(false);
              }}
            />
          )}
          {confirmRole === ChecklistConfirmRole.None && (
            <ChecklistSectionAddCommentControl
              enter={(text) => {
                props.select(undefined, undefined, { comment: text });
              }}
            />
          )}
        </div>
      )}
      {!props.data.completed &&
        !fold &&
        props.data.prepped &&
        confirmRole !== ChecklistConfirmRole.Confirm && (
          <div className={styles['forensic']}>
            <div>{textDict.get('sent_for_release')}</div>
            {confirmRole === ChecklistConfirmRole.Undo && (
              <IconUndo
                className={styles['undo']}
                onClick={() => {
                  props.undo(true);
                }}
              />
            )}
            {confirmRole === ChecklistConfirmRole.None && (
              <ChecklistSectionAddCommentControl
                enter={(text) => {
                  props.select(undefined, undefined, { comment: text });
                }}
              />
            )}
          </div>
        )}
      {props.data.comments.length > 0 && !fold && (
        <div className={styles['comment-container']}>
          {props.data.comments.map((comment, index) => (
            <div key={index} className={styles['comment-inner-container']}>
              <div>
                <div>
                  <span>{comment.userName + ', '}</span>
                  <span>{getPrettyTimeOrDayString(comment.timestamp) + ': '}</span>
                </div>
                <span className={styles['comment-text']}>{comment.text}</span>
              </div>
              {comment.undoPossible && (
                <IconUndo
                  className={styles['undo']}
                  onClick={() => {
                    props.undoComment(comment.text);
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function ChecklistSectionAddCommentControl(props: { enter: (text: string) => void }) {
  const textDict = useTextResources();
  const selContext = useContext(SelectionPageContext);
  return (
    <button
      className={styles['comment-button']}
      onClick={() => {
        const selPage = {
          entityType: SelectionMenuType,
          title: textDict.get('enter_comment'),
          selection: {
            entityType: SelectionEntities.Text,
            text: '',
          },
        };
        selContext(selPage, (ok, data) => {
          if (ok && data) {
            const text = data['text'] as string;
            props.enter(text);
          }
        });
      }}
    >
      <SvgComment className={styles['comment-icon']} />
    </button>
  );
}

export default ChecklistSectionControl;
