import { OperationContext } from '@sqior/react/operation';
import { useDynamicStateRaw } from '@sqior/react/state';
import { DateSelectionVM, NextDay, PrevDay, SelectedDatePath } from '@sqior/viewmodels/time';
import { useContext } from 'react';
import { DateNavigator } from '@sqior/react/sqior-ui';

export function DateSelection() {
  const dispatcher = useContext(OperationContext);
  const dateVM = useDynamicStateRaw<DateSelectionVM>(SelectedDatePath);
  return dateVM ? (
    <DateNavigator
      day={dateVM.date.day}
      month={dateVM.date.month}
      year={dateVM.date.year}
      prevDisabled={!dateVM.prevDate}
      nextDisabled={!dateVM.nextDate}
      onNext={() => dispatcher.start(NextDay())}
      onPrev={() => dispatcher.start(PrevDay())}
    />
  ) : null;
}
