import { useCustomTimer, useDynamicState } from '@sqior/react/state';
import { Node } from 'interweave';
import { InterweaveExt } from '@sqior/react/uibase';
import { DateTime } from 'luxon';
import styles from './main-info-item-empty.module.css';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Slogan } from '@sqior/viewmodels/visual';

enum ConditionType {
  Weekday = 'weekday',
}

// Function to remove any pattern within {} including preceding space
const removeCondition = (text: string): string => {
  const conditionRegex = /\s?\{.*?\}/;
  return text.replace(conditionRegex, '');
};

/* eslint-disable-next-line */
export interface MainInfoItemEmptyProps {}

export function MainInfoItemEmpty(props: MainInfoItemEmptyProps) {
  const currentSlogan = useDynamicState<Slogan>('slogan', ['', '']);
  const conditionSlogan = transformConditionSlogan(currentSlogan);
  return (
    <div className={styles['container']}>
      <div className={styles['line1']}>
        <InterweaveExt content={conditionSlogan[0]} transform={sloganTransform} />
      </div>
      <div className={styles['line2']}>
        <InterweaveExt content={conditionSlogan[1]} transform={sloganTransform} />
      </div>
    </div>
  );
}

export default MainInfoItemEmpty;

const transformConditionSlogan = (slogan: Slogan): Slogan => {
  const hasWeekDayPattern = containsWeekdayPattern(slogan.join(''));
  if (hasWeekDayPattern) {
    const weekday = format(new Date(), 'EEEE', { locale: de });
    return slogan.map((line) => line.replace(`{${ConditionType.Weekday}}`, weekday)) as Slogan;
  }
  return slogan.map(removeCondition) as Slogan;
};

const containsWeekdayPattern = (text: string): boolean => {
  const patternRegex = /\{weekday}/;
  return patternRegex.test(text);
};

function sloganTransform(node: HTMLElement, children: Node[]): React.ReactNode {
  if (node.tagName.toLowerCase() === 'currenttime') {
    return <CurrentTime />;
  }
  return undefined; // Skip eveyrthing else
}

function CurrentTime() {
  useCustomTimer(1000);
  return <span>{DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)}</span>;
}
