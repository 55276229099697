import { FactoryProps } from '@sqior/react/factory';
import styles from './StdInfo.module.css';
import { StdInfoVM } from '@sqior/viewmodels/patient';
import { SIcon } from '@sqior/react/uibase';

export type StdInfoProps = FactoryProps<StdInfoVM>;

export function StdInfo(props: StdInfoProps) {
  const { text, iconType } = props.data;

  return (
    <div className={styles['container']}>
      {iconType ? (
        <div className={styles['icon-container']}>
          <SIcon icon={iconType} size={24} />
        </div>
      ) : (
        <div className={styles['empty']} />
      )}
      <div className={styles['text']}>{text}</div>
    </div>
  );
}

export default StdInfo;
