import { Entity } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';

export const ActiveLocationPath = 'active-location';
export const AvailableLocationsPath = 'available-locations';

export function AddLocation(locId: string): OperationSpec<string> {
  return { type: OperationType.Add, path: ActiveLocationPath, data: locId };
}
export function RemoveLocation(locId: string): OperationSpec<string> {
  return { type: OperationType.Delete, path: ActiveLocationPath, data: locId };
}

/* For testing only */

export const TestActiveLocationPath = 'test-active-location';

export function ForceAddLocation(loc: Entity): OperationSpec<Entity> {
  return { type: OperationType.Add, path: TestActiveLocationPath, data: loc };
}
