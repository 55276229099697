import { ensureArray } from '@sqior/js/data';
import { Emitter, StopListening } from '@sqior/js/event';
import { Message } from './message';

export type MessageListener<Type extends Message = Message> = (msg: Type) => void;
export type ChannelStateListener = () => void;
export enum MessageType {
  All = '*',
}

export class Channel {
  /* Checks if the channel is open */
  get isOpen() {
    return this.state;
  }
  /* Opens the channel */
  open() {
    /* Check if already open */
    if (this.isOpen) return;
    /* Set state */
    this.state = true;
    /* Inform listeners */
    this.opened.emit();
  }
  /* Closes the channel */
  close() {
    /* Check if already closed */
    if (!this.isOpen) return;
    /* Set state */
    this.state = false;
    /* Inform listeners */
    this.closed.emit();
  }
  /* Sets the open state of the channel */
  setOpen(o: boolean) {
    if (o) this.open();
    else this.close();
  }

  /* Sends a message on the channel, informs all listeners that have either subscribed to all messages or this particular one */
  send<Type extends Message>(msg: Type) {
    /* Inform all listeners that have subscribed to all messages */
    const allHandlers = this.handlers.get(MessageType.All);
    if (allHandlers) allHandlers.emit(msg);
    /* Inform all listeners that have subscribed to this particular message type */
    const specHandlers = this.handlers.get(msg.type);
    if (specHandlers) specHandlers.emit(msg);
  }

  /* Subscribes to one message type, many messages types or all messages with MessageType.All */
  on<Type extends Message = Message>(
    type: string | string[] = MessageType.All,
    list: MessageListener<Type>
  ): StopListening {
    /* Register the listener for the specified message types */
    const stops: StopListening[] = [];
    for (const t of ensureArray(type)) {
      let handlers = this.handlers.get(t);
      if (!handlers) this.handlers.set(t, (handlers = new Emitter<[Message]>()));
      stops.push(
        handlers.on((msg) => {
          list(msg as Type);
        })
      );
    }
    if (stops.length !== 1)
      return () => {
        for (const stop of stops) stop();
      };
    else return stops[0];
  }

  /* Subscribes to the opening of the channel */
  onOpen(list: ChannelStateListener) {
    /* Register the listener */
    const stopListening = this.opened.on(list);
    /* Inform the listener right away if the channel is already open */
    if (this.isOpen) list();
    return stopListening;
  }
  /* Subscribes to the closing of the channel */
  onClose(list: ChannelStateListener) {
    /* Register the listener */
    const stopListening = this.closed.on(list);
    /* Inform the listener right away if the channel is already open */
    if (!this.isOpen) list();
    return stopListening;
  }

  private handlers = new Map<string, Emitter<[Message]>>();
  readonly opened = new Emitter();
  readonly closed = new Emitter();
  private state = false;
}
