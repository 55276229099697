import { TemporaryText, TemporaryTextOption, TemporaryTextType } from '@sqior/viewmodels/input';
import { useTextResources } from '@sqior/react/uibase';
import React, {
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { InputPart } from '../input-control/input-control-processor';
import styles from './input-proposal-selection.module.css';

export type InputProposalSelectionIF = {
  onKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
  triggerSelectedItem: () => boolean;
};
export type InputProposalSelectionProps = {
  enableKeyboardNavigation?: boolean;
  part: InputPart | undefined;
  className?: string;
  itemSelected?: (inputPart: InputPart, value: string) => void;
  nothingSelected?: () => void;
};
export const InputProposalSelection = forwardRef<
  InputProposalSelectionIF,
  InputProposalSelectionProps
>((props, ref) => {
  // Provide a onKeyDown() handler to the parents
  useImperativeHandle(ref, () => ({
    onKeyDown: (e: KeyboardEvent<HTMLElement>) => {
      onLocalKeyDown(e);
    },
    triggerSelectedItem: () => {
      return triggerSelectedItem();
    },
  }));
  function getProposals(part: InputPart | undefined): TemporaryTextOption[] {
    if (part?.item === undefined || !Array.isArray(part.item.text)) return [];
    return part.item.text;
  }

  const textResources = useTextResources();
  // This state
  const [selectedItem, setSelectedItem] = useState(-1);
  const [lastProposals, setLastProposals] = useState<string>('');
  // Reset selection if proposed items changed
  useEffect(() => {
    const proposals = getProposals(props.part);
    const proposalText = proposals
      .map((item) => {
        return item.option;
      })
      .join('|');

    if (lastProposals !== proposalText) {
      setSelectedItem(-1);
      setLastProposals(proposalText);
    }
  }, [props.part, lastProposals]);

  const proposals = getProposals(props.part);
  function onLocalKeyDown(e: KeyboardEvent<HTMLElement>) {
    let handled = true;
    if (e.key === 'ArrowDown') {
      setSelectedItem((s) => (s < proposals.length - 1 ? s + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      setSelectedItem((s) => (s > 0 ? s - 1 : proposals.length - 1));
    } else if (e.key === 'Tab' || e.key === 'Enter') {
      if (!triggerSelectedItem()) handled = false;
    } else handled = false;

    if (handled) e.preventDefault();
  }

  function triggerSelectedItem() {
    const item = props.part?.item;
    if (item && props.part) {
      if (0 <= selectedItem && selectedItem < item.text.length) {
        props.itemSelected?.(
          props.part,
          item.text instanceof Array ? item.text[selectedItem].option : item.text
        );
        return true;
      } else if (selectedItem < 0 && item.text.length > 0 && item.text[0]) {
        // Item not explicitly selected
        if (item.type === TemporaryTextType.Suggestion) {
          // Select only in case this is a suggestion
          props.itemSelected?.(
            props.part,
            item.text instanceof Array ? item.text[0].option : item.text
          );
          return true;
        }
      }
    }
    return false;
  }

  function onItemClick(e: MouseEvent<HTMLLIElement>) {
    if (props.part) props.itemSelected?.(props.part, e.currentTarget.innerText);
  }

  let matchedString = '';
  if (props.part?.item) matchedString = (props.part.item as TemporaryText).match;
  matchedString = matchedString.toLowerCase();

  function highlightMatch(text: string) {
    const textLower = text.toLowerCase();

    const found = textLower.indexOf(matchedString);
    if (matchedString.length > 0 && found >= 0) {
      const front = text.substring(0, found);
      const middle = text.substring(found, found + matchedString.length);
      const last = text.substring(found + matchedString.length);

      return (
        <>
          {front && <span>{front}</span>}
          {middle && <span className={styles['highlight']}>{middle}</span>}
          {last && <span>{last}</span>}
        </>
      );
    }

    return text;
  }

  function onClickBackground(e: React.MouseEvent<HTMLElement>) {
    if (e.target === e.currentTarget) {
      // click on background div, not any childs
      console.log('nothing selected');
      props.nothingSelected?.();
    }
  }

  return (
    <div className={props.className} onClick={onClickBackground}>
      {proposals && (
        <ul className={styles['selections']} tabIndex={0} onKeyDown={onLocalKeyDown}>
          {proposals.map((value, index) => {
            // Flag the active suggestion with a class
            const classNameActive =
              props.enableKeyboardNavigation && index === selectedItem
                ? styles['selection-item-active']
                : '';
            return (
              <li
                className={`${styles['selection-item']} ${classNameActive}`}
                key={value.option}
                onClick={onItemClick}
              >
                {highlightMatch(value.option)}
              </li>
            );
          })}
        </ul>
      )}
      {props.part === undefined && (
        <div className={styles['selection-no-items-hint']}>{textResources.get('no_selection')}</div>
      )}
    </div>
  );
});

export default InputProposalSelection;
