import styles from './sqior-progress-bar.module.css';
import { FC } from 'react';
import { ColorCodes } from '@sqior/viewmodels/visual';

/* eslint-disable-next-line */
export interface SqiorProgressBarProps {
  maxLines: number;
  currentLine: number;
  lineColors?: (ColorCodes | undefined)[];
}

const PRIMARY_COLOR = 'rgba(237, 123, 232, 1)';
const SECONDARY_COLOR = 'rgba(237, 123, 232, 0.3)';

export const SqiorProgressBar: FC<SqiorProgressBarProps> = ({
  maxLines,
  currentLine,
  lineColors,
}) => {
  return (
    <div className={styles['container']}>
      {Array.from({ length: maxLines }, (_, i) => (
        <div
          key={i}
          className={styles['line']}
          style={{
            backgroundColor: getLineColor(i, currentLine, lineColors),
          }}
        />
      ))}
    </div>
  );
};

export default SqiorProgressBar;

const getLineColor = (
  currentLine: number,
  activeLine: number,
  lineColors?: (ColorCodes | undefined)[]
): string => {
  if (lineColors) {
    const color = lineColors[currentLine];
    if (color) {
      switch (color) {
        case ColorCodes.Blue:
          return '#1cade4';
      }
    }
  }

  if (currentLine < activeLine) {
    return PRIMARY_COLOR;
  }

  return SECONDARY_COLOR;
};
