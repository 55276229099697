import styles from './blocked-wrapper.module.css';

export interface BlockedWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export function BlockedWrapper({ children, ...rest }: BlockedWrapperProps) {
  return (
    <div className={styles['container']} {...rest}>
      {children}
    </div>
  );
}

export default BlockedWrapper;
