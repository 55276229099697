import styles from './chip.module.css';
import Typography from '../typography/typography';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { useChipColor } from './useChipColor';
import { ValueWithUnit } from '@sqior/viewmodels/analytics-dashboard';
import NumberFormatter from '../number-formatter/number-formatter';
import React from 'react';
import { SqiorTooltip } from '@sqior/react/uibase';

export interface ChipProps {
  value?: string;
  valueWithUnit?: ValueWithUnit;
  color?: ColorCodes;
  tooltip?: string;
}

export function Chip({ value, valueWithUnit, color, tooltip }: ChipProps) {
  const { backgroundColor, textColor, borderColor } = useChipColor(color);

  return (
    <SqiorTooltip title={tooltip}>
      <div
        className={styles['container']}
        style={{
          backgroundColor,
          color: textColor,
          border: `1px solid ${borderColor}`,
        }}
      >
        {value && (
          <Typography fontSize={12} primary>
            {value}
          </Typography>
        )}

        {valueWithUnit && (
          <NumberFormatter showSign fontSize={12} fontWeight={400} {...valueWithUnit} />
        )}
      </div>
    </SqiorTooltip>
  );
}

export default Chip;
