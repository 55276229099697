import React from 'react';
import styles from './app-toggle.module.css';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { useUIGlobalState } from '@sqior/react/state';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

/* eslint-disable-next-line */
export interface AppToggleProps {}

export function AppToggle(props: AppToggleProps) {
  const { UIGlobalState, setUIGlobalState } = useUIGlobalState();
  const toggleDrawer = () => {
    setUIGlobalState((prevState) => ({
      ...prevState,
      messengerOpen: !prevState.messengerOpen,
    }));
  };
  return (
    <div className={styles['container']}>
      <div className={styles['container-icon-svg']}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="400"
          viewBox="0 0 30 400"
          fill="none"
          fillOpacity={0.7}
        >
          <path
            d="M0 0H2.41379V124.898C2.41379 128.103 3.94963 131.113 6.54412 132.994L25.8697 147.006C28.4642 148.887 30 151.897 30 155.102V244.898C30 248.103 28.4642 251.113 25.8697 252.994L6.54412 267.006C3.94963 268.887 2.41379 271.897 2.41379 275.102V400H0V200V0Z"
            fill="#090F23"
          />
          <path
            d="M0.75 0.75H1.66379V124.898C1.66379 128.343 3.31482 131.58 6.10389 133.602L25.4295 147.613C27.8294 149.353 29.25 152.137 29.25 155.102V244.898C29.25 247.863 27.8294 250.647 25.4294 252.387L6.10389 266.398C3.31482 268.42 1.66379 271.657 1.66379 275.102V399.25H0.75V200V0.75Z"
            stroke="white"
            strokeOpacity="0.2"
            strokeWidth="1.7"
          />
          <rect y="130" width="2" height="140" fill="#090F23" />
        </svg>
      </div>
      <div className={styles['container-icon']} onClick={toggleDrawer}>
        <div
          style={{
            right: 14,
            position: 'absolute',
          }}
        >
          {UIGlobalState.messengerOpen ? (
            <NavigateBeforeRoundedIcon />
          ) : (
            <NavigateNextRoundedIcon />
          )}
        </div>
        <div
          style={{
            width: 1,
            height: '110%',
            backgroundColor: '#090f23',
            position: 'relative',
            right: 15.5,
          }}
        />
      </div>
    </div>
  );
}

export default AppToggle;
