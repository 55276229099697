import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const SqiorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: 'solid 1pt var(--fg-color-frame-dark)',
    borderRadius: '4px',
    padding: '15px',
    paddingRight: '20px',
    background: 'rgba(var(--bg-color-dark1-rgb), 0.8)',
    backdropFilter: 'blur(5px)',
    color: 'white',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(28, 173, 228)',
  },
}));

export default SqiorTooltip;
