import { EntityHeader } from '@sqior/js/entity';
import { AddressGroupInfoVM } from '@sqior/viewmodels/communication';
import { ValueObject } from '@sqior/js/data';
import { PatientOverview } from './patient-overview';

export const AddressListContentType = 'AddressListContentType';

export type AddressListContent = EntityHeader & {
  team?: AddressGroupInfoVM[];
  relatedId?: ValueObject;
};

export function getTeamFromOverview(overview?: PatientOverview) {
  return overview && overview.content ? (overview.content as AddressListContent).team : undefined;
}
