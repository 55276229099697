import { ProxyStateHandler } from './proxy-state-handler';
import { StateHandler, StateHandlerProvider } from './state-handler';

/* Allows to switch among different state handler types provided by a factory */
export class SwitchStateHandler extends ProxyStateHandler {
  constructor(provider: StateHandlerProvider) {
    super();
    this.provider = provider;
  }

  override async close() {
    /* Detach to make sure that current source is not closed twice */
    if (this.useListener) this.useListener();
    this.detach();
    for (const sh of this.stateHandlers.values()) await sh.close();
    this.stateHandlers.clear();
  }

  /** Sets a new type of state handler */
  async set(type: string) {
    /* Create if not already existing */
    let sh = this.stateHandlers.get(type);
    if (!sh) {
      sh = this.provider.getStateHandler(type);
      if (!sh) {
        this.reset();
        return false;
      }
      /* Remember this state handler to not use its state when switching from and back */
      this.stateHandlers.set(type, sh);
    } else if (sh === this.source) return true;
    /* Switch to this state handler */
    this.detach();
    await this.attach(sh);
    return true;
  }

  /** Resets the state handler */
  reset() {
    this.detach();
    this.state.set(undefined);
  }

  private provider: StateHandlerProvider;
  private stateHandlers = new Map<string, StateHandler>();
}
