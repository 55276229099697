import { AuthConfig } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import { joinUrlPath } from '@sqior/js/url';
import { AuthConfirmTriggerType, AuthContext } from '@sqior/react/uiauth';
import { ConfigContext } from '@sqior/react/utils';
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { KeycloakProvider } from '../keycloak-provider';
import { OAuthProviderBase } from '../oauth-provider-base';
import { OpenIdProvider } from '../openid-provider';

/* eslint-disable-next-line */
export interface OAuthWrapperProps {
  authProvider?: OAuthProviderBase;
  scopes?: string[];
  children: React.ReactNode;
}

export function OAuthWrapper(props: OAuthWrapperProps) {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState('');
  const configContext = useContext(ConfigContext);
  const authProviderRef = useRef<OAuthProviderBase | undefined>(props.authProvider);

  useEffect(() => {
    const configUrl = configContext.getEndpoint('config');
    axios
      .get(configUrl.href)
      .then((response) => {
        const config = response.data as AuthConfig;
        if (
          config.sqiorAuthBaseUrl &&
          config.sqiorAuthClientId &&
          config.sqiorAuthUrl &&
          config.baseUrlPrefix
        ) {
          const appUrl = joinUrlPath(window.location.origin, config.baseUrlPrefix);

          if (!authProviderRef.current) {
            authProviderRef.current = config.sqiorAuthRealm
              ? new KeycloakProvider()
              : new OpenIdProvider();
          }
          authProviderRef.current.init(config, appUrl);
          setInitialized(true);
        } else setError(`Missing mandatory parameters in: ${configUrl}`);
      })
      .catch((err) => {
        setError(`Error on loading: ${configUrl}`);
        console.error(err);
      });
  }, [configContext]);

  if (authProviderRef.current)
    return (
      <AuthContext.Provider
        value={{
          provider: authProviderRef.current,
          confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
        }}
      >
        {initialized && props.children}
        {error && error}
      </AuthContext.Provider>
    );
  return <div></div>;
}

export default OAuthWrapper;
