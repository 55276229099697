import { FirebaseConfig } from '@sqior/js/push-firebase-common';

export const firebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyC5LYoLKYS9ggyC28G-Q9kfY7O8f4lTghw',
  authDomain: 'sqior-digital-assistant.firebaseapp.com',
  projectId: 'sqior-digital-assistant',
  storageBucket: 'sqior-digital-assistant.appspot.com',
  messagingSenderId: '770883145478',
  appId: '1:770883145478:web:11d6acb07a5e40ad2d9887',
};

export const vapidKey =
  'BPYQpuP8k8TrsUtF9VkocrDdsXcjoQYB42SLGwRJEgjOOMqrT1otK8alUz33janCzKc8vQ30Q0EFPAGDEFoAo5I';
