import { LogicalTimestamp, Value } from '@sqior/js/data';
import { Operation, OperationStateJSON, OperationType } from './operation';
import { TemporaryObject } from './temporary-object';

export type DataResult = [string, LogicalTimestamp];

export class DataOperation extends Operation {
  constructor(type: OperationType, data: Value) {
    /* The item data may contain a temporary value, so observe it */
    const temp = new TemporaryObject(data);
    super(type, temp.final);
    this.temp = temp;
    if (!temp.final)
      this.temp.change.on((value) => {
        /* Inform pot. users about the change */
        if (value) this.contentChanged(this.temp.final);
        /* Set this to failed if the temporary was cancelled */ else if (!value) this.fail();
      });
  }

  get<Type extends Value = Value>() {
    return this.data as Type;
  }
  async result(): Promise<DataResult> {
    await this.completion();
    if (this.finalId !== undefined && this.completionTimestamp)
      return [this.finalId, this.completionTimestamp];
    else throw Error('ID or timestamp were not set');
  }

  completeAdd(id: string, timestamp: LogicalTimestamp) {
    this.finalId = id;
    this.complete(timestamp);
  }

  override toJSON() {
    return this.data;
  }
  override resultToJSON(): OperationStateJSON {
    const res = super.resultToJSON();
    if (this.finalId !== undefined) res['id'] = this.finalId;
    return res;
  }
  override completeFromJSON(msg: OperationStateJSON) {
    this.finalId = msg['id'] as string;
    super.completeFromJSON(msg);
  }

  get data() {
    return this.temp.current;
  }

  private temp: TemporaryObject;

  finalId?: string;
}
