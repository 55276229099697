import { HierarchicalSelection } from '@sqior/viewmodels/input';
import styles from './hierarchical-selection-control.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { SelectionAreaProps } from '../selection-area';

export type HierarchicalSelectionControlProps = FactoryProps<HierarchicalSelection> &
  SelectionAreaProps;

export function HierarchicalSelectionControl(props: HierarchicalSelectionControlProps) {
  return (
    <div className={styles['container']}>
      {props.data.groups.map((group) => (
        <div id={group.label}>
          <div className={styles['group']}>{group.label}</div>
          <div className={styles['option-container']}>
            {group.options.map((option) => (
              <div id={option.label}>
                <div
                  className={styles['option']}
                  onClick={() => {
                    props.onSelection?.(option.value);
                  }}
                >
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default HierarchicalSelectionControl;
