import styles from './typography.module.css';
import { TypographyType } from './typography.types';
import { HTMLAttributes, JSX } from 'react';
import { useTheme } from '@sqior/react/theme';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  children: JSX.Element | string;
  type?: TypographyType;
  primary?: boolean;
  secondary?: boolean;
  className?: string;
  monoSpace?: boolean;
  fontSize?: number;
  cardSecondary?: boolean;
  link?: boolean;
  color?: string | 'primary' | 'secondary';
  error?: boolean;
  fontWeight?: number;
}

const DEFAULT_COMPONENT: TypographyType = 'p';

export function Typography({
  children,
  type = DEFAULT_COMPONENT,
  primary,
  secondary,
  monoSpace,
  className,
  cardSecondary,
  fontSize,
  link,
  error,
  color,
  fontWeight,
  ...rest
}: TypographyProps) {
  const {
    theme: {
      colors: {
        primary: {
          sqiorBlue: { value: primaryValue },
        },
        text: {
          secondary: { value: secondaryValue },
        },
      },
    },
  } = useTheme();

  const Component = type;
  const primaryClass = primary ? styles['primary'] : '';
  const cardSecondaryClass = cardSecondary ? styles['card-secondary'] : '';
  const linkClass = link ? styles['link'] : '';
  const errorClass = error ? styles['error'] : '';

  const getColor = () => {
    if (color === 'primary') return primaryValue;
    if (color === 'secondary') return secondaryValue;

    if (color) return color;
    if (secondary) return secondaryValue;
    return undefined;
  };

  return (
    <Component
      className={`${styles[type]} ${primaryClass} ${errorClass}
       ${cardSecondaryClass} ${linkClass} ${className}`}
      style={{ color: getColor(), fontSize, fontWeight }}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default Typography;
