import { FactoryProps } from '@sqior/react/factory';
import { ListViewInfos } from '@sqior/viewmodels/visual';
import DashboardStage from '../dashboard-stage/dashboard-stage';
import styles from './std-dashboard.module.css';

export type StdDashboardProps = FactoryProps<ListViewInfos>;

export function StdDashboard(props: StdDashboardProps) {
  return (
    <div className={styles['container']}>
      {props.data.lists.map((list, index) => (
        <DashboardStage
          key={list.path}
          className={styles['list']}
          title={list.title}
          path={list.path}
          separator={index + 1 < props.data.lists.length}
        />
      ))}
    </div>
  );
}

export default StdDashboard;
