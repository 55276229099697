import { classes } from '@sqior/react/utils';
import styles from './svg-button.module.css';

/* eslint-disable-next-line */
export interface SvgButtonProps {
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  className?: string;
}

export function SvgButton(props: SvgButtonProps) {
  return (
    <button onClick={props.onClick} className={classes(styles['svg-button'], props.className)}>
      {props.children}
    </button>
  );
}

export default SvgButton;
