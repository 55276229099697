import { Operation } from '@sqior/js/operation';
import { ValueInspectionStateOverlay } from '@sqior/js/state-operation';
import { FixedPushDevicesType } from './fixed-push-devices-vm';

/** Overlay for setting fixed push devices */
export function FixedPushDevicesOverlay(op: Operation) {
  return ValueInspectionStateOverlay<string, FixedPushDevicesType>(
    op,
    { roles: [], selected: '' },
    (state, op) => {
      return state.selected === op;
    },
    (state, op) => {
      return { roles: state.roles, selected: op };
    }
  );
}
