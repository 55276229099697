import styles from './page-header.module.css';

/* eslint-disable-next-line */
export interface PageHeaderProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  onClick?: () => void;
  metaStyle?: React.CSSProperties;
}

export function PageHeader(props: PageHeaderProps) {
  return (
    <div className={styles['title-container']} onClick={() => props.onClick?.()}>
      <div className={styles['title-icon']}>{props.icon}</div>
      <div className={styles['meta-container']} style={props.metaStyle}>
        <div className={styles['meta-container-title']}>{props.title}</div>
        <div className={styles['meta-container-subtitle']}>{props.subTitle}</div>
      </div>
    </div>
  );
}

export default PageHeader;
