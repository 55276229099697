/** Malformed request data
 * HTTP Status Code: 400
 */
export class MalformedRequestError extends Error {
  constructor(message?: string | undefined) {
    super(message);
  }
}

/** Forbidden
 * HTTP Status Code: 403
 */
export class ForbiddenError extends Error {
  constructor(message?: string | undefined) {
    super(message);
  }
}

/** Data not found
 * HTTP Status Code: 404
 */
export class NotFoundError extends Error {
  constructor(message?: string | undefined) {
    super(message);
  }
}

/** Conflicting data
 * HTTP Status Code: 409
 */
export class ConflictError extends Error {
  constructor(message?: string | undefined) {
    super(message);
  }
}
