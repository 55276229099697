import { v4 as uuid } from 'uuid';
import { HmacSHA256, MD5, enc } from 'crypto-js';

/* Basic UID type */

export type UID = string;

/* Function creating a UID */

export function createUID(): UID {
  /* Prepending the current timestamp to a UUID to make UID even more unique */
  return Date.now().toString(16) + '-' + uuid();
}

/* Converts a cryptjs WordArray to native Uint8Array */
function CryptJsWordArrayToUint8Array(wordArray: CryptoJS.lib.WordArray) {
  const l = wordArray.sigBytes;
  const words = wordArray.words;
  const result = new Uint8Array(l);
  let i = 0 /*dst*/,
    j = 0; /*src*/
  while (i < l) {
    // here i is a multiple of 4
    const w = words[j++];
    result[i++] = (w & 0xff000000) >>> 24;
    if (i == l) break;
    result[i++] = (w & 0x00ff0000) >>> 16;
    if (i == l) break;
    result[i++] = (w & 0x0000ff00) >>> 8;
    if (i == l) break;
    result[i++] = w & 0x000000ff;
  }
  return result;
}

/** Function deterministically deriving a UID from a UID and an optional sub-key */

export function deriveUID(base: UID, sub = '') {
  /* Get MD5 hash */
  const hash = MD5(base + sub);
  /* Create UID with original prefix, if found */
  const index = base.indexOf('-');
  return index > 0
    ? base.substring(0, index) + '-' + uuid({ random: CryptJsWordArrayToUint8Array(hash) })
    : uuid({ random: CryptJsWordArrayToUint8Array(hash) });
}

/** Function pseudonimizing a value based on a key using HMAC */

export function pseudonomizeUID(value: string, key: string) {
  return HmacSHA256(value, key).toString(enc.Base64);
}
