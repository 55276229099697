import { ArraySource, ensureArray } from '@sqior/js/data';
import { LogBackend } from './log-backend';
import { LogData } from './log-data';
import { Emitter } from '@sqior/js/event';

/** Log backend implementation providing the log data to multiple clients */
export class ProxyLogger implements LogBackend {
  constructor(clients: ArraySource<LogBackend>) {
    this.clients = ensureArray(clients);
  }

  /** Implementation of log backend */
  log(data: LogData) {
    /* Provide to all clients */
    for (const client of this.clients) client.log(data);
    /* Emit signal for dynamic clients */
    this.added.emit(data);
  }

  private clients: LogBackend[];
  readonly added = new Emitter<[LogData]>();
}
