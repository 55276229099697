import styles from './sqior-photo-shoot.module.css';
import { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Button, IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { v4 as uuidv4 } from 'uuid';
import CameraswitchRoundedIcon from '@mui/icons-material/CameraswitchRounded';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { useIsMobile } from '@sqior/react/hooks';
import { ContentTypes } from '@sqior/js/operation';

export enum ImageMimeType {
  png = 'image/png',
  jpeg = ContentTypes.JPEG,
}

export enum FileAttachmentType {
  image = 'image',
}

export interface FileAttachment {
  id: string;
  type: FileAttachmentType;
  mimeType: ImageMimeType;
  imageURL: string;
  blob: Blob | null;
  finalId?: string;
  progress?: number;
}

interface Props {
  onClose: () => void;
  onPhotoSubmit?: (image: FileAttachment) => void;
  maxImageSize?: number;
}

interface CaptureImageResult {
  imageURL: string;
  blob: Blob;
}

enum FacingMode {
  USER = 'user',
  ENVIRONMENT = 'environment',
}

const MAX_IMAGE_SIZE = 3000000; // 3 MB

const getBlobFromCanvas = async (canvas: HTMLCanvasElement, quality?: number): Promise<Blob> => {
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve, ImageMimeType.jpeg, quality);
  });
  if (!blob) throw new Error('Blob creation failed');
  return blob;
};

export const SqiorPhotoShoot: FC<Props> = ({ onClose, onPhotoSubmit, maxImageSize }) => {
  const firstRender = useRef(true);
  const mediaStream = useRef<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isMobile = useIsMobile();

  const [facingMode, setFacingMode] = useState<FacingMode>(FacingMode.ENVIRONMENT);

  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [showWhiteLayout, setShowWhiteLayout] = useState<boolean>(false);
  const [captureImageUrl, setCaptureImageUrl] = useState<string | null>(null);
  const [captureImageBlob, setCaptureImageBlob] = useState<Blob | null>(null);

  const [showFlipCamera, setShowFlipCamera] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [errorPermission, setErrorPermission] = useState<boolean>(false);

  useEffect(() => {
    if (!firstRender.current) return;
    const runAsync = async () => {
      await getUserMedia(facingMode);
      const hasMultipleCamerasResult = await hasMultipleCameras();
      setShowFlipCamera(hasMultipleCamerasResult);
    };
    runAsync();
    firstRender.current = false;
    return () => {
      removeUserMedia();
    };
  }, []);

  const hasMultipleCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputs = devices.filter((device) => device.kind === 'videoinput');
      return videoInputs.length > 1;
    } catch (e) {
      console.error('Error checking devices: ', e);
      return false;
    }
  };

  const getUserMedia = async (mode: FacingMode) => {
    if (!videoRef.current) return;
    if (mediaStream.current) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: !isMobile ? 3840 : 1280 },
          height: { ideal: !isMobile ? 2160 : 720 },
          facingMode: mode,
        },
      });
      videoRef.current!.srcObject = stream;
      mediaStream.current = stream;
    } catch (e) {
      setErrorPermission(true);
    }
  };

  const removeUserMedia = () => {
    if (!videoRef.current) return;
    if (!mediaStream.current) return;
    (videoRef.current!.srcObject as MediaStream).getTracks().forEach((track) => {
      track.stop();
    });
    mediaStream.current = null;
  };

  const captureImage = async (): Promise<CaptureImageResult | null> => {
    try {
      if (!videoRef.current || !canvasRef.current) return null;
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (!context) return null;

      // Original dimensions
      const originalWidth = video.videoWidth;
      const originalHeight = video.videoHeight;

      // Scale factor (initially 1, adjust based on trial and error)
      let scaleFactor = 1;
      let quality = 0.92; // default quality

      do {
        canvas.width = originalWidth * scaleFactor;
        canvas.height = originalHeight * scaleFactor;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const blob = await getBlobFromCanvas(canvas, quality);
        const allowedMaxImageSize = maxImageSize || MAX_IMAGE_SIZE;

        if (blob.size < allowedMaxImageSize) {
          return {
            imageURL: canvas.toDataURL(ImageMimeType.jpeg, quality),
            blob: blob,
          };
        }

        // Adjust scale factor and quality for the next iteration
        scaleFactor *= 0.95; // Reduce size by 5%
        quality *= 0.95; // Reduce quality by 5%
        // eslint-disable-next-line no-constant-condition
      } while (true);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const onLocaleClose = () => {
    if (captureImageUrl) return setCaptureImageUrl(null);
    removeUserMedia();
    onClose();
  };

  const onLoadedData = () => {
    setLoading(true);
    setVideoLoaded(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };

  const onPhotoShoot = async () => {
    try {
      setShowWhiteLayout(true);

      const canvasData = await captureImage();
      if (!canvasData) return;

      setCaptureImageUrl(canvasData.imageURL);
      setCaptureImageBlob(canvasData.blob);

      setTimeout(() => {
        setShowWhiteLayout(false);
      }, 200);
    } catch (e) {
      console.error(e);
    }
  };

  const onLocalePhotoSubmit = () => {
    removeUserMedia();
    if (captureImageUrl && onPhotoSubmit) {
      onPhotoSubmit({
        id: uuidv4(),
        type: FileAttachmentType.image,
        imageURL: captureImageUrl,
        blob: captureImageBlob,
        mimeType: ImageMimeType.jpeg,
      });
    }
    onClose();
  };

  const toggleCamera = async () => {
    setLoading(true);
    removeUserMedia();
    const newFacingMode =
      facingMode === FacingMode.ENVIRONMENT ? FacingMode.USER : FacingMode.ENVIRONMENT;
    setFacingMode(newFacingMode);
    await getUserMedia(newFacingMode);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const variants = {
    user: { rotateY: 0 },
    environment: { rotateY: 180 },
  };

  const rotateImage = async (angle: number) => {
    if (!canvasRef.current || !captureImageUrl) return;
    try {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      if (!context) return;

      const image = new Image();
      image.src = captureImageUrl;
      const width = image.width;
      const height = image.height;
      canvas.width = width;
      canvas.height = height;

      // Translate and rotate
      context.translate(width / 2, height / 2);
      context.rotate((angle * Math.PI) / 180);
      context.drawImage(image, -width / 2, -height / 2, width, height);

      const blob = await getBlobFromCanvas(canvas);

      // Update state
      setCaptureImageBlob(blob);
      setCaptureImageUrl(canvas.toDataURL(ImageMimeType.jpeg));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <motion.div className={styles['container']}>
      <div className={styles['video-container']}>
        <video
          className={styles['video']}
          style={{
            display: captureImageUrl ? 'none' : 'block',
          }}
          ref={videoRef}
          autoPlay
          playsInline
          onLoadedData={onLoadedData}
        />

        {captureImageUrl && (
          <img
            className={styles['video']}
            src={captureImageUrl}
            alt="capture image"
            style={{
              display: captureImageUrl ? 'block' : 'none',
            }}
          />
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </div>

      <div className={styles['overlay-top']}>
        <IconButton onClick={onLocaleClose}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      {loading && <div className={styles['loading-state']}></div>}
      <div className={styles['overlay-bottom']}>
        {!captureImageUrl && (
          <div className={styles['capture-actions']}>
            <div className={styles['center-content']}>
              {showFlipCamera && (
                <IconButton onClick={toggleCamera}>
                  <motion.div
                    animate={facingMode}
                    variants={variants}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    style={{ display: 'inline-block' }}
                  >
                    <CameraswitchRoundedIcon fontSize="large" />
                  </motion.div>
                </IconButton>
              )}
            </div>

            <div className={styles['capture-button-container']}>
              <motion.div
                className={styles['capture-button']}
                whileTap={{ scale: 0.9 }}
                onClick={onPhotoShoot}
              />
            </div>
          </div>
        )}
        {captureImageUrl && (
          <div className={styles['action-buttons']}>
            <Button
              color="inherit"
              startIcon={<ArrowBackIosNewRoundedIcon />}
              variant="outlined"
              style={{
                borderRadius: 100,
              }}
              onClick={onLocaleClose}
            >
              Zürück
            </Button>
            <div className={styles['center-content']}>
              <IconButton onClick={() => rotateImage(90)}>
                <ScreenRotationIcon fontSize="large" />
              </IconButton>
            </div>
            <Button
              endIcon={<ArrowForwardIosRoundedIcon />}
              variant="contained"
              style={{
                borderRadius: 100,
                color: 'white',
              }}
              onClick={onLocalePhotoSubmit}
            >
              Weiter
            </Button>
          </div>
        )}
      </div>
      {!videoLoaded && (
        <div className={styles['loading']}>
          {errorPermission ? (
            <div className={styles['error-screen']}>
              <div className={styles['error-screen-title']}>Keine Berechtigung für Kamera</div>
              <div className={styles['error-screen-message']}>
                Bitte erlaube den Zugriff auf die Kamera in den Einstellungen deines Browsers.
              </div>
              <br />
              <Button onClick={onLocaleClose} startIcon={<ArrowBackIosNewRoundedIcon />}>
                Zürück
              </Button>
            </div>
          ) : (
            'Kamera wird initialisiert....'
          )}
        </div>
      )}
      {showWhiteLayout && <div className={styles['photo-shoot']} />}
    </motion.div>
  );
};

export default SqiorPhotoShoot;
