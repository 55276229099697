import styles from './location-view.module.css';
import { SvgSurgical } from '../svg-icon/svg-icon';

export interface LocationViewProps {
  location?: string;
}

export function LocationView({ location }: LocationViewProps) {
  if (!location) return null;
  return (
    <div className={styles['container']}>
      <SvgSurgical />
      <div className={styles['location']}>{location}</div>
    </div>
  );
}

export default LocationView;
