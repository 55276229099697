// keyid only for private key
export type KeyTriplet = {
  privateKey: string;
  keyid: string;
  publicKeys: string[];
};
export type KeyPair = {
  publicKey: string;
  privateKey: string;
};

export type KeyPairModel = KeyPair & {
  createdAt: number;
  expiration: number;
  usageType: string;
};

export enum KeyExpirationState {
  Valid,
  InGracePeriod,
  Expired,
  NotFound,
}
