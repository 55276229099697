import { RetainedLogData } from '@sqior/js/log';
import { Message } from './message';

/** Type of log messages */
export const LogMessageType = 'LogData';
export const LogConfirmType = 'LogConfirm';

/** Structure of log messages */
export type LogMessageData = Message & { data: RetainedLogData[] };
/** Structure of log confirmation messages */
export type LogConfirmData = Message & { sequenceNumber: number };
