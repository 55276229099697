import React, { FC } from 'react';
import PillIcon from './pill-icon';
import { BedAllocationWardListItemBed } from '@sqior/viewmodels/occupancy';
import styles from '../pill-view.module.css';

interface Props {
  bed: BedAllocationWardListItemBed;
}

const MultiPillIcon: FC<Props> = ({ bed }) => {
  return (
    <div className={styles['multi-pill-icon-container']}>
      <PillIcon
        bed={bed}
        style={{ width: '80%', height: '80%', position: 'absolute', top: 0, left: 0 }}
        occupiedFrom={bed.other[0].sex}
      />
      <div className={styles['multi-pill-icon-second-wrapper']} style={{}}>
        <PillIcon
          bed={bed}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default MultiPillIcon;
