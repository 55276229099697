import { useContext, useEffect, useRef, useState } from 'react';
import styles from './confirm-identity-page.module.css';
import { NumPad, TitlePage, useTextResources } from '@sqior/react/uibase';
import PinNumber from '../pin-number/pin-number';
import { FactoryProps } from '@sqior/react/factory';
import { EntityHeader } from '@sqior/js/entity';
import { OperationContext } from '@sqior/react/operation';
import {
  ConfirmCredentials,
  ConfirmCredentialsType,
  PinCodePath,
  VerifyConfirmCredentials,
} from '@sqior/viewmodels/user';
import { encodePIN } from '@sqior/js/authbase';
import { AuthContext } from '../auth-context';
import { useDynamicState, useTimer } from '@sqior/react/state';
import { addMilliseconds, addSeconds } from '@sqior/js/data';
import ConfirmLoginControl from '../confirm-login-control/confirm-login-control';

export type ConfirmIdentityPageProps = FactoryProps<EntityHeader & { title: string }>;

export function ConfirmIdentityPage(props: ConfirmIdentityPageProps) {
  const { timer } = useTimer();
  const dispatcher = useContext(OperationContext);
  const authContext = useContext(AuthContext);
  const textDict = useTextResources();
  const indexes: number[] = [];
  const [pinNumbers, setPinNumbers] = useState<number[]>([]);
  const pageAlive = useRef<boolean>(true);
  useEffect(() => {
    return () => {
      pageAlive.current = false;
    };
  }, []);
  /* Current PIN of the user */
  const pinLength = useDynamicState(PinCodePath, 0);
  for (let i = 0; i < pinLength; i++) indexes.push(i);
  /* Explicit wish for login */
  const [login, setLogin] = useState(false);
  /* Error message */
  const [error, setError] = useState('');
  useEffect(() => {
    return timer.schedule(() => {
      setError('');
    }, addSeconds(3));
  }, [error, setError, timer]);
  return (
    <TitlePage
      title={props.data.title}
      onClose={() => {
        props.onClose?.(false);
      }}
    >
      {pinLength > 0 && !login && (
        <div className={styles['container']}>
          <div className={styles['number-outer-container']}>
            <div className={styles['number-container']}>
              {indexes.map((index) => (
                <PinNumber key={index} num={pinNumbers[index]} />
              ))}{' '}
            </div>
            {error && (
              <div className={styles['error-container']}>
                <span>{error}</span>
              </div>
            )}
          </div>
          <NumPad
            select={(num) => {
              if (pinNumbers.length >= pinLength) return;
              const fullPin = pinNumbers.concat(num);
              setPinNumbers(fullPin);
              /* Start the verification process in case that the full PIN was entered */
              if (fullPin.length === pinLength)
                timer.schedule(() => {
                  const credentials: ConfirmCredentials = {
                    type: ConfirmCredentialsType.PIN,
                    data: encodePIN(authContext.provider.userInfo.userId ?? '', fullPin.join()),
                  };
                  VerifyConfirmCredentials(dispatcher, credentials)
                    .then((res) => {
                      if (!pageAlive) return;
                      if (res) props.onClose?.(true, credentials);
                      else {
                        setError(textDict.get('pin_incorrect'));
                        setPinNumbers([]);
                      }
                    })
                    .catch(() => {
                      if (!pageAlive) return;
                      setError(textDict.get('pin_verification_failed'));
                      setPinNumbers([]);
                    });
                }, addMilliseconds(500));
            }}
            back={() => {
              if (pinNumbers.length) setPinNumbers(pinNumbers.slice(0, -1));
            }}
          />
          <div
            onClick={() => {
              setLogin(true);
            }}
          >
            {textDict.get('enter_password_instead')}
          </div>
        </div>
      )}
      {(!pinLength || login) && (
        <div className={styles['login-container']}>
          <ConfirmLoginControl
            confirm={(token) => {
              const credentials: ConfirmCredentials = {
                type: ConfirmCredentialsType.Token,
                data: token,
              };
              VerifyConfirmCredentials(dispatcher, credentials)
                .then((res) => {
                  if (!pageAlive) return;
                  if (res) props.onClose?.(true, credentials);
                  else props.onClose?.(false);
                })
                .catch(() => {
                  if (!pageAlive) return;
                  props.onClose?.(false);
                });
            }}
          />
          <div className={styles['login-shade']} />
        </div>
      )}
    </TitlePage>
  );
}

export default ConfirmIdentityPage;
