export class ExternalPromise<Type = void> {
  constructor() {
    this.resolve = () => {
      console.log('Unexpected resolve');
    };
    this.reject = () => {
      console.log('Unexpected reject');
    };
    this.promise = new Promise<Type>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  readonly promise: Promise<Type>;
  resolve: (param: Type | PromiseLike<Type>) => void;
  reject: (reason?: unknown) => void;
}
