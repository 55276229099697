import { OperationSpec, OperationType } from '@sqior/js/operation';

/** Profile picture URL */

export const ProfilePicturePath = 'profile-picture';

/** Operation setting the profile picture of a user */

export function SetProfilePicture(id: string): OperationSpec<string> {
  return { type: OperationType.Add, path: ProfilePicturePath, data: id };
}
