import styles from './tabs-group.module.css';
import { Button } from '@mui/material';
import Typography from '../typography/typography';
import { useCallback } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@sqior/react/theme';

export interface TabOption {
  id: string;
  value: string;
}

export interface TabsGroupProps {
  options: TabOption[];
  selectedTabId?: string;
  onChange?: (id: string) => void;
}

export function TabsGroup({ options, selectedTabId, onChange }: TabsGroupProps) {
  const {
    theme: {
      colors: {
        primary: {
          sqiorBlue: { value: primaryValue },
        },
      },
    },
  } = useTheme();

  const getTextType = useCallback(
    (id: string): 'primary' | 'secondary' => {
      return id === selectedTabId ? 'primary' : 'secondary';
    },
    [selectedTabId]
  );

  return (
    <div className={styles['container']}>
      {options.map((option) => (
        <div className={styles['tab']}>
          <Button
            onClick={() => onChange?.(option.id)}
            key={option.id}
            style={{ padding: '10px 20px' }}
          >
            <Typography color={getTextType(option.id)} fontSize={14} fontWeight={500}>
              {option.value}
            </Typography>
          </Button>
          <motion.div
            className={styles['line']}
            animate={{
              backgroundColor: option.id === selectedTabId ? primaryValue : 'transparent',
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default TabsGroup;
