import { MouseEvent } from 'react';
import { classes } from '@sqior/react/utils';
import Button from '../button/button';
import styles from './button-x.module.css';

/* eslint-disable-next-line */
export interface ButtonXProps {
  onClick: (e: MouseEvent) => void;
  additionalClassName?: string;
}

export function ButtonX(props: ButtonXProps) {
  return (
    <Button
      onClick={props.onClick}
      additionalClassName={classes(styles['button-x-btn'], props.additionalClassName)}
    >
      <div className={styles['button-x']}>
        <span className={styles['button-x-line']}></span>
      </div>
    </Button>
  );
}

export default ButtonX;
