import { ClockTimestamp } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';

export interface AuthorizationHeader {
  Authorization: string;
  'Sqior-Sub-User-Id'?: string;
}

/** Type representation the result of a token generation */
export type TokenGenerationResult = {
  token: string; // Token to transfer
  subUserId?: string; // Additional identifier further specializing a pot. generic user account
  sessionStart?: ClockTimestamp; // Session start timestamp
};

/** Interface to generate and consume tokens
 */
export interface IAuthContext {
  generateToken: (scope: string) => Promise<TokenGenerationResult>;
  getIdentityToken: () => Promise<string | undefined>;

  getAuthorizationHeader(scope: string): Promise<AuthorizationHeader>;

  readonly tokenRefreshed: Emitter<[string]>;
}

export abstract class BearerTokenAuthContext implements IAuthContext {
  abstract generateToken(scope: string): Promise<TokenGenerationResult>;

  abstract getIdentityToken(): Promise<string | undefined>;

  async getAuthorizationHeader(scope: string): Promise<AuthorizationHeader> {
    return {
      Authorization: 'Bearer ' + (await this.generateToken(scope)).token,
    };
  }

  readonly tokenRefreshed = new Emitter<[string]>();
  readonly beforeLogOut = new Emitter();
}
