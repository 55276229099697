import { ValueObject } from '@sqior/js/data';
import { EntityHeader } from '@sqior/js/entity';
import { joinPath } from '@sqior/js/url';
import { EntityHTMLWithInterpreter } from './entity-html';
import { ReadConfirmationTypes } from './inform-projection';

export const StandardInfoPath = 'standard-info';
export const StandardInfoItemsPath = joinPath(StandardInfoPath, 'items');

export const CommandCommunicationPath = 'communication';
export const CommandCommunicationItemsPath = joinPath(CommandCommunicationPath, 'items');

export enum CommunicationVMTypes {
  InformItemVMType = 'InformItemVMType',
  MainInfoItemVMType = 'MainInfoItemVMType',
  MainInfoCollapsedStandardInfoItemVMType = 'CollapsedStandardInfoItemVMType',
}
export const PageStandardInfoThreadType = 'page.standard-info-thread';

export type CollpasedStandardInfoItemVM = EntityHeader & {
  id: string; // Originating MainId InformProjectionVM ids
  ids: string[]; // Originating InformProjectionVM ids
  reference: ValueObject; // InformProjectionVM ID as Entity
  message: { message: EntityHTMLWithInterpreter; timestamp: number }[]; // the message to inform
  itemCount: number;
  myReadConfirmationStatus?: ReadConfirmationTypes;
};
