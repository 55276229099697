import { ExternalPromise } from './external-promise';

export function wrapFunction<Type>(
  func: () => Promise<Type>
): [() => Promise<void>, Promise<Type>] {
  const exprom = new ExternalPromise<Type>();
  const wrapped = async () => {
    try {
      const res = await func();
      exprom.resolve(res);
    } catch (e) {
      exprom.reject(e);
    }
  };
  return [wrapped, exprom.promise];
}
