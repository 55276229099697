import styles from './messenger-screen.module.css';
import ButtonClose from '../button-close/button-close';

/* eslint-disable-next-line */
export interface MessengerScreenProps {
  visible: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
  actions?: React.ReactNode;
}

export function MessengerScreen({
  visible,
  onClose,
  title,
  actions,
  children,
}: MessengerScreenProps) {
  if (!visible) return null;
  return (
    <div className={styles['container']}>
      <div className={styles['header-container']} style={{ width: title ? '100%' : undefined }}>
        <div className={styles['title']}>{title}</div>

        <div className={styles['action-buttons']}>
          {actions}
          <ButtonClose additionalClassName={styles['btn-close']} onClick={onClose} />
        </div>
      </div>
      <div className={styles['body']}>{children}</div>
    </div>
  );
}

export default MessengerScreen;
