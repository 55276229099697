import { OperationSpec, OperationType } from '@sqior/js/operation';

export type SpecialtyData = { text: string; id?: string };

export const ActiveSpecialtyPath = 'active-specialty';
export const AvailableSpecialtiesPath = 'available-specialties';

export function AddSpecialty(specId: string): OperationSpec<string> {
  return { type: OperationType.Add, path: ActiveSpecialtyPath, data: specId };
}
export function RemoveSpecialty(specId: string): OperationSpec<string> {
  return { type: OperationType.Delete, path: ActiveSpecialtyPath, data: specId };
}
