import { Emitter } from '@sqior/js/event';
import { Channel, ChannelStateListener } from './channel';

export class BiChannel {
  constructor(out: Channel = new Channel(), inChannel: Channel = new Channel()) {
    this.out = out;
    this.in = inChannel;
    this.out.onOpen(() => {
      this.outOpen = true;
      if (this.allOpen) this.opened.emit();
    });
    this.in.onOpen(() => {
      this.inOpen = true;
      if (this.allOpen) this.opened.emit();
    });
    this.out.onClose(() => {
      const wasOpen = this.allOpen;
      this.outOpen = false;
      if (wasOpen) this.closed.emit();
    });
    this.in.onClose(() => {
      const wasOpen = this.allOpen;
      this.inOpen = false;
      if (wasOpen) this.closed.emit();
    });
  }

  reverse() {
    return new BiChannel(this.in, this.out);
  }

  /** Requests to close the channels */
  requestClose() {
    this.closeRequested.emit();
  }

  /** Checks if all sub-channels are closed */
  get allClosed(): boolean {
    return !this.inOpen && !this.outOpen;
  }

  /** Checks if all sub-channels are open */
  get allOpen(): boolean {
    return this.inOpen && this.outOpen;
  }

  /* Subscribes to the opening of the channel */
  onOpen(list: ChannelStateListener) {
    /* Register the listener */
    const stopListening = this.opened.on(list);
    /* Inform the listener right away if the channel is already open */
    if (this.allOpen) list();
    return stopListening;
  }

  /* Subscribes to the closing of the channel */
  onClose(list: ChannelStateListener) {
    /* Register the listener */
    const stopListening = this.closed.on(list);
    /* Inform the listener right away if the channel is already open */
    if (!this.allOpen) list();
    return stopListening;
  }

  /** Opens both channels */
  open() {
    this.in.open();
    this.out.open();
  }

  /** Closes both channels */
  close() {
    this.in.close();
    this.out.close();
  }

  readonly out: Channel;
  readonly in: Channel;
  private inOpen = false;
  private outOpen = false;
  readonly closeRequested = new Emitter();
  readonly opened = new Emitter();
  readonly closed = new Emitter();
}
