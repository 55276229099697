import styles from './log-out-button.module.css';
import { Button } from '@sqior/react/uibase';
import { useContext } from 'react';
import { AuthContext } from '../auth-context';

/* eslint-disable-next-line */
export interface LogOutButtonProps {}

export function LogOutButton(props: LogOutButtonProps) {
  const authContext = useContext(AuthContext);

  return <Button onClick={() => authContext.provider.logOut()}>Log Out</Button>;
}

export default LogOutButton;
