import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, onMessage, isSupported } from 'firebase/messaging';
import {
  convertMessageToNote,
  convertNoteToNotification,
  FirebaseConfig,
  VapidKey,
} from '@sqior/js/push-firebase-common';
import { PushNote } from '@sqior/js/push';

export type AskUserPushNotificationCB = ((response: string) => void) | undefined;
export type AskUserPushNotification = (responseCB: AskUserPushNotificationCB) => void;

let g_askUserPushNotification: AskUserPushNotification | undefined = undefined;
let g_askUserPushNotificationSetter: ((ask: AskUserPushNotification) => void) | undefined;
export function SetAskUserPushNotificationSetter(
  setter: ((ask: AskUserPushNotification) => void) | undefined
): AskUserPushNotification | undefined {
  g_askUserPushNotificationSetter = setter;
  return g_askUserPushNotification;
}
export function SetAskUserPushNotification(ask: AskUserPushNotification) {
  g_askUserPushNotification = ask;
  if (g_askUserPushNotificationSetter !== undefined) g_askUserPushNotificationSetter(ask);
}

function askUserNeeded() {
  return Notification.permission === 'default';
}

export async function initFirebaseFrontend(
  serviceWorker: ServiceWorkerRegistration,
  config: FirebaseConfig,
  vapidKey: VapidKey
): Promise<string> {
  if (!(await isSupported())) {
    throw new Error('Browser does not support Firebase API');
  }

  return new Promise<string>((resolve, reject) => {
    function askAndInit(ask: AskUserPushNotification) {
      ask((response: string) => {
        if (response === 'granted')
          initFirebaseFrontendInternal(serviceWorker, config, vapidKey)
            .then((pushToken) => {
              console.log('=========== received push token: ', pushToken);
              resolve(pushToken);
              ask(undefined);
            })
            .catch((reason) => {
              console.log('=========== could not find push token')
              reject(reason);
            });
        else reject(new Error('user did not grant permission'));
      });
    }

    // Check permissions first
    if (askUserNeeded()) {
      console.log('... asking user ...');
      const ask = SetAskUserPushNotificationSetter((ask: AskUserPushNotification) => {
        askAndInit(ask);
      });
      if (ask !== undefined) askAndInit(ask);
    } else {
      const ask = SetAskUserPushNotificationSetter((ask: AskUserPushNotification) => {
        ask(undefined);
      });
      ask?.(undefined);
      initFirebaseFrontendInternal(serviceWorker, config, vapidKey)
        .then((pushToken) => {
          console.log('---------- found token: ', pushToken);
          resolve(pushToken);
        })
        .catch((reason) => {
          console.log('---------- could not find token: ', reason);
          reject(reason);
        });
    }
  });
}

async function initFirebaseFrontendInternal(
  serviceWorker: ServiceWorkerRegistration,
  config: FirebaseConfig,
  vapidKey: VapidKey
): Promise<string> {
  const firebaseApp = initializeApp(config);
  const messaging = getMessaging(firebaseApp);

  const token = await getFirebaseToken(serviceWorker, messaging, vapidKey);

  const displayPushMessageIfBrowserVisible = false;
  if (displayPushMessageIfBrowserVisible)
    onMessage(messaging, async (payload) => {
      const note = convertMessageToNote(payload);
      if (note) {
        if (note.message) {
          const notificationData = await convertNoteToNotification(note, serviceWorker.scope);
          serviceWorker.showNotification(notificationData[0], notificationData[1]);
        } else {
          // Cancel notifications
          const notifications = await serviceWorker.getNotifications(
            note.threadId ? { tag: note.threadId } : undefined
          );
          notifications
            .filter(
              (n) => n.data !== undefined && (n.data as PushNote).messageId === note.messageId
            )
            .forEach((n) => n.close());
        }
      }
    });

  return token;
}

async function getFirebaseToken(
  serviceWorker: ServiceWorkerRegistration,
  messaging: Messaging,
  vapidKey: VapidKey
): Promise<string> {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: vapidKey,
      serviceWorkerRegistration: serviceWorker,
    });
    if (currentToken) {
      console.log('current firebase token for client: ', currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      throw new Error('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    throw err;
  }
}
