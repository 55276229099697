import styles from './expandable-image.module.css';
import { ChatVMType } from '@sqior/viewmodels/communication';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import ChatPicture from '../chat-picture/chat-picture';
import { motion, MotionStyle, useMotionValue } from 'framer-motion';
import { ConfigContext } from '@sqior/react/utils';
import { IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export interface ExpandableImageProps {
  type: ChatVMType;
  url?: string;
}

const MIN_SCALE = 1;
const MAX_SCALE = 3;
const DAMPENING_FACTOR = 0.5;
const DRAG_CONSTRAINTS = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};
const DRAG_ELASTIC = 1;

const MOTION_CONTAINER_STYLE: MotionStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgba(0,0,0)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
  overflow: 'hidden',
};

export const ExpandableImage: FC<ExpandableImageProps> = ({ type, url }) => {
  let initialDistance: number;
  const configContext = useContext(ConfigContext);
  const scale = useMotionValue(1);

  const [expanded, setExpanded] = useState(false);

  const onExpand = () => setExpanded(true);
  const onExpandClose = () => setExpanded(false);

  const constraintsRef = useRef<HTMLDivElement | null>(null);

  const handleTouchStart = (e: TouchEvent) => {
    if (e.touches.length !== 2) return;
    initialDistance = getDistanceBetweenPoints(
      e.touches[0].clientX,
      e.touches[0].clientY,
      e.touches[1].clientX,
      e.touches[1].clientY
    );
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (e.touches.length !== 2) return;
    const distance = getDistanceBetweenPoints(
      e.touches[0].clientX,
      e.touches[0].clientY,
      e.touches[1].clientX,
      e.touches[1].clientY
    );
    const scaleChange = (distance - initialDistance) * DAMPENING_FACTOR;
    let newScale = scale.get() + scaleChange / initialDistance;
    newScale = Math.min(MAX_SCALE, Math.max(MIN_SCALE, newScale)); // Clamp between MIN and MAX
    scale.set(newScale);
  };

  const getDistanceBetweenPoints = (x1: number, y1: number, x2: number, y2: number) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  useEffect(() => {
    const elem = constraintsRef.current;
    if (elem) {
      elem.addEventListener('touchstart', handleTouchStart, false);
      elem.addEventListener('touchmove', handleTouchMove, false);
    }
    return () => {
      if (elem) {
        elem.removeEventListener('touchstart', handleTouchStart);
        elem.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  if (!url) return <ChatPicture type={type} url={url} />;

  return (
    <>
      <motion.div
        ref={constraintsRef}
        initial={{
          opacity: 0,
          scale: 0,
        }}
        animate={{
          opacity: expanded ? 1 : 0,
          scale: expanded ? 1 : 0,
        }}
        style={MOTION_CONTAINER_STYLE}
      >
        <div className={styles['close-button']}>
          <IconButton size="large" onClick={onExpandClose}>
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <motion.div
          drag
          style={{ scale }}
          dragConstraints={DRAG_CONSTRAINTS}
          dragElastic={DRAG_ELASTIC}
        >
          <img
            className={styles['image']}
            src={configContext.getEndpoint(url).toString()}
            alt="user uploaded image"
          />
        </motion.div>
      </motion.div>
      <div onClick={onExpand} className={styles['container-chat-picture']}>
        <ChatPicture type={type} url={url} />
      </div>
    </>
  );
};

export default ExpandableImage;
