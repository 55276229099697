import { Value, ValueOrNothing } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';
import { DataOperation } from './data-operation';
import { isFinal, OperationType } from './operation';

export class StreamOperation extends DataOperation {
  constructor(data: Value) {
    super(OperationType.Stream, data);
    this.stream = new Emitter<[ValueOrNothing]>();
    this.closed = false;
  }

  send(data: Value) {
    /* Do not pass on data if the operation is not yet running */
    if (isFinal(this.state) || this.closed) return;
    /* Emit data */
    this.stream.emit(data);
  }
  close() {
    /* Do not close again */
    if (this.closed) return;
    /* Remember */
    this.closed = true;
    /* Emit signal that stream has ended */
    if (!isFinal(this.state)) this.stream.emit(undefined);
  }

  static fromJSON(json: Value) {
    return new StreamOperation(json);
  }

  readonly stream: Emitter<[ValueOrNothing]>;
  closed: boolean;
}
