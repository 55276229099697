import styles from './blocked-saal-view.module.css';
import React, { Dispatch, SetStateAction } from 'react';
import { OpConfigView } from '../op-config-view/op-config-view';
import { ORWorkflowOverviewDashboardORData } from '@sqior/viewmodels/orworkflow';
import BlockedWrapper from '../blocked-wrapper/blocked-wrapper';
import { SIcon, useTextResources } from '@sqior/react/uibase';
import { Icons } from '@sqior/viewmodels/visual';

export interface BlockedSaalViewProps {
  cardMinWidth: number;
  ors: ORWorkflowOverviewDashboardORData[];
}

export function BlockedSaalView({ cardMinWidth, ors }: BlockedSaalViewProps) {
  const textDict = useTextResources();
  return (
    <div
      className={styles['or-items-outer-test']}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      <div
        className={styles['or-items']}
        style={{
          display: 'grid',
          gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)`,
          gridAutoFlow: 'column',
        }}
      >
        {ors.map((or) => (
          <div
            key={or.name}
            style={{
              display: 'grid',
              gridAutoColumns: `minmax(${cardMinWidth}px, 1fr)`,
              position: 'relative',
            }}
          >
            {or.items.map((item, index) => {
              if (or.blocked)
                return (
                  <BlockedWrapper key={item.component.id} style={{ width: cardMinWidth }}>
                    <div className={styles['blocked-container']}>
                      <div className={styles['blocked-row']}>
                        <div className={styles['pat']}>{textDict.get('blocked_patients')}</div>
                        <SIcon icon={Icons.Lock} size={28} color="#e0e0e0" />
                      </div>
                      <div className={styles['reason']}>{or?.blockingReason}</div>
                    </div>
                  </BlockedWrapper>
                );
              return null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlockedSaalView;
