import styles from './number-formatter.module.css';
import { formatNumber } from './utils';
import { ValueWithUnit } from '@sqior/viewmodels/analytics-dashboard';
import { ReactNode } from 'react';
import { SqiorTooltip } from '@sqior/react/uibase';

export interface NumberFormatterProps extends ValueWithUnit {
  locale?: string;
  fontSize?: number;
  fontWeight?: number;
  variant?: 'default' | 'bold';
  showSign?: boolean;
  color?: string;
  tooltip?: ReactNode;
}

export function NumberFormatter({
  value,
  locale,
  decimals,
  fontSize,
  unit,
  separateUnit,
  fontWeight,
  showSign,
  color,
  tooltip,
}: NumberFormatterProps) {
  return (
    <SqiorTooltip title={tooltip}>
      <div className={styles['value']} style={{ fontSize, fontWeight, color }}>
        <span className={styles['value-number']} style={{ fontSize, fontWeight, color }}>
          {formatNumber({ value, locale, decimalPlaces: decimals, showSign })}
        </span>
        {separateUnit && (
          <span className={styles['value-separate-unit']} style={{ fontSize, fontWeight, color }}>
            {separateUnit}
          </span>
        )}
        {unit && (
          <span className={styles['value-unit']} style={{ fontSize, fontWeight, color }}>
            {unit}
          </span>
        )}
      </div>
    </SqiorTooltip>
  );
}

export default NumberFormatter;
