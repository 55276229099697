import { EntityHTMLWithInterpreter } from '@sqior/viewmodels/communication';
import { MatcherInterface, Node } from 'interweave';
import { IpMatcher, UrlMatcher, EmailMatcher } from 'interweave-autolink';
import { InterweaveExt } from '@sqior/react/uibase';
import EntityVisualElement from '../entity-visual-element/entity-visual-element';

export interface EntityVisualProps {
  content: EntityHTMLWithInterpreter | undefined;
  enableStdMatcher?: boolean; // If true, emails, IP Addresses and URLs will be rendered as links
}

const stdMatchers: MatcherInterface[] = [
  new EmailMatcher('email'),
  new IpMatcher('ip'),
  new UrlMatcher('url'),
];

export function EntityVisual(props: EntityVisualProps) {
  function templateTransform(node: HTMLElement, children: Node[]): React.ReactNode {
    if (node.tagName.toLowerCase() === 'e') {
      const key = children[0]?.toString();
      if (key !== undefined && props.content?.params !== undefined) {
        const visual = props.content.params[key];
        const interpreterKey = props.content.interpreterKeys?.[key];
        if (visual)
          return (
            <EntityVisualElement
              content={visual.htmlVisual}
              entityKey={visual.entityKey}
              interpreterKey={interpreterKey}
            />
          );
      }
      return null; // Key not found return null to skip
    }

    return undefined; // Skip eveyrthing else
  }

  return (
    <InterweaveExt
      content={props.content?.template.toString()}
      transform={templateTransform}
      matchers={props.enableStdMatcher ? stdMatchers : []}
      newWindow={true}
    />
  );
}

export default EntityVisual;
