import { OperationSpec, OperationType } from '@sqior/js/operation';

export const RoleSelectionPath = 'role-selection';
export const RoleSelectionStatePath = RoleSelectionPath;
export const RoleSelectionOpPath = RoleSelectionPath;

export type RoleSelectionItem = { label: string; active: boolean; id: string };

export function AddRole(role: string): OperationSpec<string> {
  return { type: OperationType.Add, path: RoleSelectionOpPath, data: role };
}
export function SetRoles(roles: string[]): OperationSpec<string[]> {
  return { type: OperationType.Add, path: RoleSelectionOpPath, data: roles };
}
export function RemoveRole(role: string): OperationSpec<string> {
  return { type: OperationType.Delete, path: RoleSelectionOpPath, data: role };
}
