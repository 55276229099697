import { Value } from '@sqior/js/data';
import { State } from './state';

export type FilteredSetStateStatus = {
  numUnfiltered: number;
  numFiltered: number;
  numLimitted: number;
};
export const EmptyFilteredSetStateStatus: FilteredSetStateStatus = {
  numUnfiltered: 0,
  numFiltered: 0,
  numLimitted: 0,
};
export enum FilterMaxCountStart {
  Begin,
  End,
}

export interface IFilteredSetState {
  readonly state: State;
  readonly stateSet: State;
  readonly stateMaxCount: State;
  readonly stateStatus: State;

  setMaxCount(maxCount?: number): void;
  get maxCount(): number | undefined;
}

export class FilteredSetState<FromType extends Value, ToType extends Value = FromType>
  implements IFilteredSetState
{
  constructor(
    srcState: State,
    filterFn?: (item: FromType) => boolean,
    sortFn?: (a: FromType, b: FromType) => number,
    processItemsFn?: (items: FromType[]) => ToType[]
  ) {
    this.srcState = srcState;
    this.stateSet = new State();
    this.stateMaxCount = new State();
    this.stateStatus = new State();
    this.stateSet.set([]);

    this.state = new State();
    this.state.map('items', this.stateSet);
    this.state.map('maxCount', this.stateMaxCount);
    this.state.map('status', this.stateStatus);

    this.filterFn = filterFn;
    this.sortFn = sortFn;
    this.processItemsFn =
      processItemsFn !== undefined
        ? processItemsFn
        : (items: FromType[]): ToType[] => items as unknown as ToType[];
    this._maxCount = undefined;

    this.srcState.onTyped<FromType[]>((items) => {
      this.proxyItems(items);
    });
    // Initial proxing
    this.refreshItems();
  }

  setFilter(filter?: (item: FromType) => boolean) {
    this.filterFn = filter;
    this.refreshItems();
  }

  setSort(sortFn?: (a: FromType, b: FromType) => number) {
    this.sortFn = sortFn;
    this.refreshItems();
  }

  setMaxCount(maxCount?: number) {
    this._maxCount = maxCount;
    this.stateMaxCount.set(maxCount);
    this.refreshItems();
  }

  setMaxCountStart(start: FilterMaxCountStart) {
    this.maxCountStart = start;
    this.refreshItems();
  }

  protected refreshItems() {
    this.proxyItems(this.srcState.getRaw<FromType[]>());
  }

  protected proxyItems(srcItems: FromType[] | undefined) {
    let items: FromType[] = srcItems ? srcItems : [];
    const srcItemCount = items.length;

    /* Check if there is a filter function defined */
    const filteredItems = this.filterFn ? items.filter(this.filterFn) : undefined;

    /* Check if a sort function is defined, only if yes we need to create a shallow clone of the whole array */
    if (this.sortFn) {
      items = filteredItems || [...items];
      items.sort(this.sortFn);
    } else items = filteredItems || items;

    // Process items if there is a processItemsFn defined
    let retItems = this.processItemsFn(items);

    /* Check if the number needs to be limited  */
    if (this.maxCount !== undefined && retItems.length > this.maxCount)
      if (this.maxCountStart === FilterMaxCountStart.Begin)
        retItems = retItems.slice(0, this.maxCount);
      else retItems = retItems.slice(retItems.length - this.maxCount);

    const status: FilteredSetStateStatus = {
      numUnfiltered: srcItemCount,
      numFiltered: filteredItems ? filteredItems.length : srcItemCount,
      numLimitted: retItems.length,
    };
    this.setState(retItems, status);
  }

  protected setState(items: ToType[], status: FilteredSetStateStatus) {
    this.stateSet.set(items);
    this.stateStatus.set(status);
  }

  readonly state: State;
  readonly stateSet: State;
  readonly stateMaxCount: State;
  readonly stateStatus: State;
  private srcState: State;
  private filterFn?: (item: FromType) => boolean;
  private sortFn?: (a: FromType, b: FromType) => number;
  private processItemsFn: (items: FromType[]) => ToType[];
  private _maxCount?: number;
  private maxCountStart = FilterMaxCountStart.Begin;
  get maxCount() {
    return this._maxCount;
  }
}
