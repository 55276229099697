import { FC, useEffect, useRef } from 'react';

interface Props {
  width: number;
  height: number;
  borderBottom?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;

  borderTopLeftRadius?: boolean;
  borderTopRightRadius?: boolean;
  borderBottomLeftRadius?: boolean;
  borderBottomRightRadius?: boolean;

  borderFix?: boolean;

  children?: React.ReactNode;
  style?: React.CSSProperties;
  content?: React.ReactNode;
}

const BORDER_COLOR = '#303859';
const BORDER_WIDTH = 2;
const RADIUS = 8;
const PADDING = 10;

const borderStyle = (border?: boolean) => {
  if (border) {
    return `${BORDER_WIDTH}px solid ${BORDER_COLOR}`;
  }
  return `none`;
};

export const SqiorBlock: FC<Props> = ({
  width,
  height,
  borderBottom,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  borderLeft,
  borderTop,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderRight,
  borderFix,
  children,
  style,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.backgroundColor = findParentBackgroundColor(ref.current);
    }
  }, []);

  const findParentBackgroundColor = (element: HTMLElement): string => {
    while (element) {
      const color = window.getComputedStyle(element).backgroundColor;
      if (color && color !== 'rgba(0, 0, 0, 0)') {
        // This condition checks for non-transparent colors
        return color;
      }
      element = element.parentElement as HTMLElement;
    }
    return '#11182E'; // Fallback color if none is found
  };

  return (
    <div
      style={{
        ...style,
        position: 'relative',
        width,
        height,
        boxShadow: generateShadows({
          borderTop,
          borderLeft,
          borderRight,
          borderBottom,
          color: BORDER_COLOR,
          borderWidth: BORDER_WIDTH,
        }),
        borderBottomLeftRadius: borderBottomLeftRadius ? RADIUS : 0,
        borderBottomRightRadius: borderBottomRightRadius ? RADIUS : 0,
        borderTopLeftRadius: borderTopLeftRadius ? RADIUS : 0,
        borderTopRightRadius: borderTopRightRadius ? RADIUS : 0,
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: 4,
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 4,
            zIndex: 100,
            overflow: 'hidden',
          }}
        >
          {children}
        </div>
      </div>

      {borderFix && (
        <div
          style={{
            position: 'absolute',
            top: height,
            left: width,
            width: width / 2,
            height: height / 2,
            border: `${BORDER_WIDTH}px solid ${BORDER_COLOR}`,
            borderTopLeftRadius: RADIUS,
            borderBottom: 'none',
            borderRight: 'none',
            zIndex: 2,
          }}
        />
      )}
      {borderFix && (
        <div
          ref={ref}
          style={{
            position: 'absolute',
            bottom: 0 - PADDING / 2 - BORDER_WIDTH,
            right: 0 - PADDING / 2 - BORDER_WIDTH,
            width: 2 * PADDING + BORDER_WIDTH,
            height: 2 * PADDING + BORDER_WIDTH,
            borderBottom: 'none',
            borderLeft: 'none',
            zIndex: 1,
            borderRadius: '100%',
          }}
        />
      )}
    </div>
  );
};

export default SqiorBlock;

interface GenerateShadowsParams {
  borderTop?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  color: string;
  borderWidth?: number;
}

const generateShadows = ({
  borderTop,
  borderRight,
  borderLeft,
  borderBottom,
  color,
  borderWidth = 2,
}: GenerateShadowsParams) => {
  const shadows: string[] = [];
  if (borderTop) {
    shadows.push(`0px -${borderWidth}px 0px 0px ${color}`);
  }
  if (borderLeft) {
    shadows.push(`-${borderWidth}px 0px 0px 0px ${color}`);
  }
  if (borderRight) {
    shadows.push(`${borderWidth}px 0px 0px 0px ${color}`);
  }
  if (borderBottom) {
    shadows.push(`0px ${borderWidth}px 0px 0px ${color}`);
  }
  return shadows.join(',');
};
