import { ZIndex } from '@sqior/react/utils';
import ClosablePage from '../closable-page/closable-page';
import styles from './title-page.module.css';

export interface TitlePageProps {
  title: string;
  titleSize?: string;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  className?: string;
  zIndex?: ZIndex;
}

export function TitlePage(props: TitlePageProps) {
  return (
    <ClosablePage onClose={props.onClose} className={props.className} zIndex={props.zIndex}>
      <div className={styles['container']}>
        <div className={styles['title']} style={{ fontSize: props.titleSize }}>
          {props.title}
        </div>
        {props.children}
      </div>
    </ClosablePage>
  );
}

export default TitlePage;
