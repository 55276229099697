import { TextResourceMap, TextState } from './text-resource-map';

export class TextResourceAccess {
  constructor(textState: TextState) {
    this.textResources = textState.resources;
    this.lang = textState.lang;
  }

  get(id: string): string {
    const res = this.textResources[id];
    if (typeof res === 'string') return res;
    else if (typeof res === 'object') return res[''];

    return '';
  }

  language(): string {
    return this.lang;
  }

  private textResources: TextResourceMap;
  private lang: string;
}
