import { shallowClone, ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec } from '@sqior/js/operation';

export enum SelectionEntities {
  Options = 'SelectionOptions',
  OptionsViewModel = 'SelectionOptionsViewModel',
  Numerical = 'NumericalSelection',
  NumericalResult = 'NumericalResult',
  Text = 'TextSelection',
  QRScanner = 'QRScannerSelection',
  QRScannerResult = 'QRScannerResult',
  HierarchicalSelection = 'HierarchicalSelection',
  ChallengeHierarchicalMultiSelection = 'ChallengeHierarchicalMultiSelection',
}
export const SelectionMenuType = 'SelectionMenu';

/** Numerical input */
export type NumberInput = EntityHeader & {
  default?: number;
  min: number;
  max: number;
  defaultTitle: string;
  unit: string;
  numericalResult?: boolean;
};
export type NumericalResultEntity = EntityHeader & { number: number };

/** Input form a list of pre-defined options */
export type SelectionInput = {
  visual: string;
  data?: ValueObject;
  selection?: SelectionFromOptions; // Nested selection
};
export type SelectionFromOptions = EntityHeader & { options: SelectionInput[] };

/** Text input */
export type TextInput = EntityHeader & { text: string; textEntityResult?: boolean };

/** QR Scanner Input */
export type QRScannerInput = EntityHeader & { prefixes?: string[] };
export type QRScannerResult = EntityHeader & { qrcode: string };

/** Selection entity with configurable selection option */
export type SelectionControlBaseEntity = EntityHeader & { operation?: OperationSpec<ValueObject> };
export type SelectionMenuContent = {
  title: string;
  header?: Entity;
  selection: Entity;
  multiSelect?: boolean;
};
export type SelectionMenu = SelectionControlBaseEntity &
  (SelectionMenuContent | { selections: SelectionMenuContent[] });

/* Selects the mixin structure from a selection option */

export function getSelectionOptionMixIn(option: SelectionInput) {
  return option.data !== undefined ? option.data : { text: option.visual };
}

/* Mixes in the selected option to the selection operation */

export function mixInSelectionOption(
  op: OperationSpec<ValueObject>,
  data: ValueObject
): OperationSpec<ValueObject> {
  const res = shallowClone(op);
  res.data = { ...op.data, ...data };
  return res;
}

/** Hierarchical selection */

export type HierarchicalSelectionOption = { label: string; value: ValueObject };
export type HierarchicalSelectionGroup = { label: string; options: HierarchicalSelectionOption[] };
export type HierarchicalSelection = EntityHeader & {
  groups: HierarchicalSelectionGroup[];
};

/** Hierarchical multiple selection */

export type HierarchicalMultiSelectionOption = {
  label: string;
  value: ValueObject;
  selected: boolean;
};
export type HierarchicalMultiSelectionGroup = {
  label: string;
  options: HierarchicalMultiSelectionOption[];
};
export type HierarchicalMultiSelection = SelectionControlBaseEntity & {
  groups: HierarchicalMultiSelectionGroup[];
};
