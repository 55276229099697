import { classes } from '@sqior/react/utils';
import styles from './thread-item-separator.module.css';

/* eslint-disable-next-line */
export interface ThreadItemSeparatorProps {
  children: React.ReactNode;
  className?: string;
}

export function ThreadItemSeparator(props: ThreadItemSeparatorProps) {
  return <div className={classes(styles['container'], props.className)}>{props.children}</div>;
}

export default ThreadItemSeparator;
