import { FactoryProps } from '@sqior/react/factory';
import styles from './challenge-hierarchical-multi-selection.module.css';
import {
  HierarchicalMultiSelection,
  HierarchicalMultiSelectionGroup,
  mixInSelectionOption,
} from '@sqior/viewmodels/input';
import { useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { classes } from '@sqior/react/utils';
import { OperationSpec } from '@sqior/js/operation';
import { ValueObject } from '@sqior/js/data';
import { SvgIconExpandLess, SvgIconExpandMore } from '@sqior/react/uibase';

export type ChallengeHierarchicalMultiSelectionProps = FactoryProps<HierarchicalMultiSelection>;

export function ChallengeHierarchicalMultiSelection(
  props: ChallengeHierarchicalMultiSelectionProps
) {
  return (
    <div className={styles['container']}>
      {props.data.groups.map((group, index) => (
        <Group
          key={group.label}
          data={group}
          operation={props.data.operation}
          topBorder={index > 0}
        />
      ))}
    </div>
  );
}

/** Group */

export type GroupProps = {
  data: HierarchicalMultiSelectionGroup;
  operation?: OperationSpec<ValueObject>;
  topBorder: boolean;
};

function Group(props: GroupProps) {
  const dispatcher = useContext(OperationContext);
  const group = props.data;
  const [folded, setFolded] = useState(true);
  return (
    <div className={classes(styles['inner-container'], props.topBorder && styles['next-group'])}>
      <div className={styles['group-container']} onClick={() => setFolded(!folded)}>
        <div
          className={classes(
            styles['group'],
            group.options.find((option) => option.selected) && styles['selected']
          )}
        >
          {group.label}
        </div>
        {folded ? (
          <SvgIconExpandMore className={styles['more-button']} />
        ) : (
          <SvgIconExpandLess className={styles['less-button']} />
        )}
      </div>
      {!folded && (
        <div className={styles['option-container']}>
          {group.options.map((option) => (
            <div key={option.label}>
              <div
                className={classes(styles['option'], option.selected && styles['selected'])}
                onClick={() => {
                  if (props.operation)
                    dispatcher.start(
                      mixInSelectionOption(props.operation, {
                        ...option.value,
                        selected: !option.selected,
                      })
                    );
                }}
              >
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ChallengeHierarchicalMultiSelection;
