import { useContext } from 'react';
import { AuthContext } from '@sqior/react/uiauth';
import { useDynamicState } from '@sqior/react/state';
import { ChatListPath, EnrichedChatVM } from '@sqior/viewmodels/communication';

export const CHAT_PERSIST_ROOT_KEY = 'chat-input';

interface Props {
  type?: 'support' | 'all';
}

export const useChats = ({ type }: Props) => {
  const context = useContext(AuthContext);
  const userId = context.provider.userInfo.userId;
  const chats = useDynamicState<EnrichedChatVM[]>(ChatListPath, []);
  return { chats: getFilteredChats(chats, type || 'all', userId), userId };
};

export default useChats;

const getFilteredChats = (chats: EnrichedChatVM[], filter: 'support' | 'all', userId?: string) => {
  const filteredChats = chats.filter((chat) => {
    if (filter === 'support') return chat.type === 'support';
    return true;
  });

  return getNonEmptyChats(filteredChats, userId);
};

const getNonEmptyChats = (chats: EnrichedChatVM[], userId?: string): EnrichedChatVM[] => {
  const persistedKeys = getAllChatPersistedKeys();

  const filteredChats: EnrichedChatVM[] = [];

  chats.forEach((chat) => {
    if (chat.showAlways) {
      filteredChats.push(chat);
    } else {
      const chatId = chat.id['id'];
      const hasPersistedValue = userId
        ? persistedKeys.find((key) => key.chatId === chatId && key.userId === userId)
        : undefined;
      const isEmpty = chat.isEmpty;
      if (isEmpty && hasPersistedValue) filteredChats.push(chat);
      if (!isEmpty) filteredChats.push(chat);
    }
  });

  return filteredChats;
};

interface PersistedKey {
  key: string;
  userId: string;
  chatId: string;
}

const getAllChatPersistedKeys = (): PersistedKey[] => {
  const allPersistedKeys: PersistedKey[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) continue;
    if (!key.startsWith(CHAT_PERSIST_ROOT_KEY)) continue;

    const parts = key.split('?');
    if (parts.length !== 3) continue;

    const persistedKey: PersistedKey = {
      key,
      chatId: parts[1],
      userId: parts[2],
    };

    allPersistedKeys.push(persistedKey);
  }

  return allPersistedKeys;
};
