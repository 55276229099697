import styles from './search-input.module.css';
import { SIcon } from '@sqior/react/uibase';
import { Icons } from '@sqior/viewmodels/visual';
import { IconButton } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';

export interface SearchInputProps {
  onClear?: () => void;
  value?: string;
  onChange?: (value: string) => void;
}

export function SearchInput({ onClear, value, onChange }: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const onLocalClear = () => {
    onChange?.('');
    onClear?.();
    inputRef.current?.focus();
  };

  const toggleOpen = () => {
    if (isOpen && value) onChange?.('');
    setIsOpen((prev) => !prev);
  };

  const onBlur = () => {
    if (!value) setIsOpen(false);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') toggleOpen();
  };

  return (
    <motion.div
      className={styles['container']}
      initial={{ width: '40px' }}
      animate={{
        width: isOpen ? '300px' : '40px',
        borderColor: isOpen ? '#242a42' : 'rgba(0,0,0,0)',
        backgroundColor: isOpen ? '#121830' : 'rgba(0,0,0,0)',
      }}
      transition={{ duration: 0.3 }}
    >
      <div className={styles['icon']}>
        <IconButton onClick={toggleOpen}>
          <SIcon icon={Icons.Search} />
        </IconButton>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.input
            onKeyDown={onKeyDown}
            ref={inputRef}
            autoFocus
            key="search-input"
            value={value}
            onChange={onLocalChange}
            type="text"
            className={styles['input']}
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: '100%' }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.3 }}
            onBlur={onBlur}
          />
        )}
      </AnimatePresence>
      <div className={styles['clear']}>
        {isOpen && value && value.length > 0 && (
          <div key="clear-button">
            <IconButton onClick={onLocalClear}>
              <SIcon icon={Icons.Close} />
            </IconButton>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default SearchInput;
