import styles from './auth-frame.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { ClosablePage } from '@sqior/react/uibase';
import { ZIndex } from '@sqior/react/utils';
import { AuthContext } from '../auth-context';
import ConfirmLoginControl from '../confirm-login-control/confirm-login-control';

/* eslint-disable-next-line */
export interface AuthFrameProps {
  children: React.ReactNode;
}

/** Does the following:
 *  - Switches the content depending on whether the user is authenticated or not
 *  - Provides an AuthContext (IAuthContext) which does not depend on the Auth0 interface
 */
export function AuthFrame(props: AuthFrameProps) {
  const auth = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [confirm, setConfirm] = useState('');
  const confirmFunc = useRef<(() => void) | undefined>();

  useEffect(() => {
    auth.provider.isAuthenticated
      .then((authenticated) => {
        setLoading(false);
        setAuthenticated(authenticated);
        // If not authenticated, directly proceed to the login page ...
        if (!authenticated) auth.provider.tryLogIn();
      })
      .catch(() => {
        setLoading(false);
      });
  }, [auth.provider]);

  /* Listen for identity confirmation requests */
  useEffect(() => {
    return auth.confirmIdentity.on((text: string, cf: () => void) => {
      /* Remember confirmation function and activate pop-up */
      confirmFunc.current = cf;
      setConfirm(text);
    });
  });

  if (loading || window.parent !== window)
    // Simply return empty container
    return <div className={styles['login-container']}></div>;

  return (
    <div className={styles['container']}>
      {authenticated ? (
        <div className={styles['children-container']}>
          {props.children}
          {confirm.length > 0 && (
            <ClosablePage
              onClose={() => {
                setConfirm('');
              }}
              className={styles['confirm-container']}
              zIndex={ZIndex.AppUpdatePage}
            >
              <ConfirmLoginControl
                confirm={() => {
                  if (confirmFunc.current) {
                    confirmFunc.current();
                    confirmFunc.current = undefined;
                  }
                  setConfirm('');
                }}
              />
              <div className={styles['confirm-instruction']}>{confirm}</div>
            </ClosablePage>
          )}
        </div>
      ) : (
        <div className={styles['login-container']}>
          {/* Login Screen is empty, as display only temporarily */}
        </div>
      )}
    </div>
  );
}

export default AuthFrame;
