import styles from './treatment-path-item-adapt.module.css';
import { FactoryProps } from '@sqior/react/factory';
import {
  AdaptTreatmentPathItem,
  TreatmentPathAdaptPath,
  TreatmentPathItemAdaptChangeData,
  TreatmentPathItemAdaptEntity,
  TreatmentPathItemChangeResult,
  TreatmentPathItemField,
} from '@sqior/viewmodels/treatment-path';
import {
  ClosablePage,
  ContainerWithShadow,
  HighlightButton,
  useTextResources,
} from '@sqior/react/uibase';
import { useContext, useState } from 'react';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { SelectionMenuType } from '@sqior/viewmodels/input';
import { ValueObject, clone } from '@sqior/js/data';
import { OperationContext } from '@sqior/react/operation';
import { ReadOperation } from '@sqior/js/operation';

export type TreatmentPathItemAdaptProps = FactoryProps<TreatmentPathItemAdaptEntity>;

export function TreatmentPathItemAdapt(props: TreatmentPathItemAdaptProps) {
  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  const selContext = useContext(SelectionPageContext);
  const [fields, setFields] = useState(
    props.data.fields.map<TreatmentPathItemField & { change?: ValueObject }>((field) => {
      return { ...field };
    })
  );
  /* Render */
  return (
    <ClosablePage
      onClose={() => {
        if (props.onClose) props.onClose(false);
      }}
    >
      <div className={styles['container']}>
        <div className={styles['header-container']}>
          <div className={styles['title']}>{textDict.get('adapt_clinical_order')}</div>
          <div className={styles['order']}>{props.data.title}</div>
          {props.data.titleComment && (
            <div className={styles['order-comment']}>{props.data.titleComment}</div>
          )}
        </div>
        <ContainerWithShadow className={styles['field-container']}>
          <div className={styles['content-container']}>
            {fields.map((field, index) => (
              <div
                key={field.name}
                className={styles['field']}
                onClick={() => {
                  if (field.selection)
                    selContext(
                      {
                        entityType: SelectionMenuType,
                        title: field.name,
                        selection: field.selection,
                      },
                      (ok, data) => {
                        if (ok && data) {
                          /* Send the change to the server to get the updated field */
                          const readOp = new ReadOperation(field.id, {
                            patient: props.data.patient,
                            item: props.data.id,
                            change: data,
                          });
                          readOp.result().then((res) => {
                            const data = res[0] as TreatmentPathItemChangeResult;
                            /* Find the field */
                            const newFields = clone(fields);
                            const field = newFields.find((f) => {
                              return f.id === data.id;
                            });
                            if (field) {
                              Object.assign(field, data);
                              setFields(newFields);
                            }
                          });
                          dispatcher.handle(readOp, TreatmentPathAdaptPath);
                        }
                      }
                    );
                }}
              >
                <div className={styles['field-name']}>
                  <div>{field.name}</div>
                </div>
                <div className={styles['field-value']}>{field.current}</div>
              </div>
            ))}
            <div className={styles['button']}>
              <HighlightButton
                disabled={
                  fields.find((field) => {
                    return field.change !== undefined;
                  }) === undefined
                }
                onClick={() => {
                  const modifications: TreatmentPathItemAdaptChangeData[] = [];
                  for (const field of fields)
                    if (field.change !== undefined)
                      modifications.push({ prop: field.id, change: field.change });
                  dispatcher.start(
                    AdaptTreatmentPathItem(props.data.patient, props.data.id, modifications)
                  );
                  if (props.onClose) props.onClose(true);
                }}
              >
                {textDict.get('take_over')}
              </HighlightButton>
            </div>
          </div>
        </ContainerWithShadow>
      </div>
    </ClosablePage>
  );
}

export default TreatmentPathItemAdapt;
