import { ClosablePage } from '@sqior/react/uibase';
import styles from './log-view.module.css';
import { useDynamicState } from '@sqior/react/state';
import { DateTime } from 'luxon';
import { LogLevel, RetainedLogData } from '@sqior/js/log';

export interface LogViewProps {
  onClose: () => void;
}

function levelStyle(level: LogLevel) {
  switch (level) {
    case LogLevel.Critical:
    case LogLevel.Fatal:
      return 'error';
    case LogLevel.Warning:
      return 'warning';
    case LogLevel.Debug:
      return 'debug';
    case LogLevel.Trace:
      return 'trace';
  }
  return 'info';
}

export function LogView(props: LogViewProps) {
  const entries = useDynamicState<RetainedLogData[]>('app/log', []);
  return (
    <ClosablePage onClose={props.onClose}>
      <div className={styles['container']}>
        <div className={styles['sub-container']}>
          {entries.map((entry) => (
            <div key={entry.sequenceNumber} className={styles['entry']}>
              {entry.timestamp && (
                <div className={styles['timestamp']}>
                  {DateTime.fromMillis(entry.timestamp).toFormat('LLL dd, HH:mm:ss.SSS')}
                </div>
              )}
              <div className={styles[levelStyle(entry.level)]}>{entry.text}</div>
            </div>
          ))}
        </div>
      </div>
    </ClosablePage>
  );
}

export default LogView;
