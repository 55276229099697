import { LogBackend } from './log-backend';
import { LogData } from './log-data';
import { LogInterface, LogOptions } from './log-interface';

/** Default implementation of the log interface providing the log to a defined backend */
export class StdLogger extends LogInterface {
  constructor(options: LogOptions, backend: LogBackend) {
    super(options);
    this.backend = backend;
  }
  protected override doLog(data: LogData) {
    /* Simply provide to back-end */
    this.backend.log(data);
  }
  backend: LogBackend;
}
