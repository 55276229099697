import { useContext } from 'react';

import styles from './active-items-selection.module.css';
import { OperationContext } from '@sqior/react/operation';
import { OperationSpec } from '@sqior/js/operation';
import {
  ButtonX,
  InterweaveExt,
  SelectControl,
  SelectModel,
  useTextResources,
} from '@sqior/react/uibase';

export type ItemData = { text: string; id?: string };
export type ItemsData = ItemData[];

/* eslint-disable-next-line */
export interface ActiveItemsSelectionProps {
  activeItems: ItemsData;
  availableItems: ItemsData;
  addOperation: (id: string) => OperationSpec;
  removeOperation: (id: string) => OperationSpec;
  title: string;
  description: string;
  defaultSelectionText: string;
}

export function ActiveItemsSelection(props: ActiveItemsSelectionProps) {
  const textDict = useTextResources();
  const activeItems = props.activeItems;
  const availableItems = props.availableItems;

  const filteredAvailableItems = availableItems.filter((item) => {
    return (
      activeItems.findIndex((act) => {
        return act.id === item.id;
      }) < 0
    );
  });

  const rooms: SelectModel = {
    items: filteredAvailableItems.map((item) => {
      return item.text;
    }),
    selected: -1,
    defaultSelectionText: textDict.get(props.defaultSelectionText),
  };

  const dispatcher = useContext(OperationContext);
  function roomManuallySelected(index: number) {
    const roomId = filteredAvailableItems[index];
    if (roomId?.id) dispatcher.start(props.addOperation(roomId.id));
  }

  return (
    <div className={styles['container']} style={{ flex: 1 }}>
      <div className={styles['content']}>
        <div className={styles['title']}>{textDict.get(props.title)}</div>
        <InterweaveExt
          className={styles['description']}
          content={textDict.get(props.description)}
          disableMatchers
        />
      </div>

      <div style={{ flex: 1 }} />
      <ActiveItemsList
        items={activeItems.filter((item) => item.id)}
        removeOperation={props.removeOperation}
      />
      {rooms.items.length > 0 && (
        <SelectControl
          className={styles['room-select']}
          data={rooms}
          onSelect={roomManuallySelected}
        />
      )}
    </div>
  );
}

export interface ItemsListProps {
  items: ItemsData;
  removeOperation: (id: string) => OperationSpec;
}

export function ActiveItemsList(props: ItemsListProps) {
  const dispatcher = useContext(OperationContext);
  const deleteLocation = (id: string | undefined) => {
    if (id) dispatcher.start(props.removeOperation(id));
  };

  return (
    <div className={styles['location-list']}>
      {props.items.map((item, index) => {
        return (
          <div
            key={item.text}
            className={styles['location-list-item']}
            onClick={() => deleteLocation(item.id)}
          >
            {item.text}
            <ButtonX onClick={() => deleteLocation(item.id)} />
          </div>
        );
      })}
    </div>
  );
}

export default ActiveItemsSelection;
