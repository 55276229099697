import styles from './react-state.module.css';

/* eslint-disable-next-line */
export interface ReactStateProps {}

export function ReactState(props: ReactStateProps) {
  return (
    <div className={styles['container']}>
      <h1>Welcome to ReactState!</h1>
    </div>
  );
}

export default ReactState;
