import { TemporaryText } from '@sqior/viewmodels/input';
import { isEqual } from '@sqior/js/data';
import { Dispatcher, isFinal, StreamOperation } from '@sqior/js/operation';
import { useRefWithDestructor } from '@sqior/react/hooks';
import { useDynamicState } from '@sqior/react/state';
import { KeyboardEvent, RefObject, useState } from 'react';
import { fixSqiorNodes, setCursorPosition } from '../conversation-entities/conversation-entities';
import { InputProposalSelectionIF } from '../input-proposal-selection/input-proposal-selection';
import {
  applyEdits,
  calculateNewCurAtNextInputRequired,
  convertToString,
  curPosCompare,
  CursorPos,
  getClosestInputPartWithItem,
  InputChain,
  InputPart,
  inputRequired,
  InputState,
  insertSuggestion,
  makeCurPos,
  maxCurPos,
  mergeLocalRemoteInputState,
  temporaryTextJoinMatch,
} from './input-control-processor';

export enum ManipulationType {
  None,
  Edit,
  Suggestion,
}
export enum EditDirection {
  ToLeft,
  ToRight,
}

export function useInputControlLogic(dispatcher: Dispatcher) {
  const [icLogicState, setIcLogicState] = useState<number>(0);
  const [closesInputPart, setClosesInputPart] = useState<InputPart | undefined>(undefined);
  const [editActive, setEditActive] = useState<boolean>(false);

  const icLogic = useRefWithDestructor<InputControlLogic>(
    () => {
      return new InputControlLogic(dispatcher, onInternalStateChanged);
    },
    (o) => {
      o.destroy();
    }
  );

  // Mapping sub-parts of InputControlLogic to dedicated react states, triggering rendering
  function onInternalStateChanged() {
    setIcLogicState(icLogicState + 1);
    setClosesInputPart(icLogic.current.getClosestInputPartWithItem()?.[0]);
    setEditActive(icLogic.current.editActive);
  }

  // The dialog template from server
  const commandInputTemplate = useDynamicState<TemporaryText[]>('main-command-state', []);
  icLogic.current.setTextData(commandInputTemplate);

  return {
    icLogic: icLogic.current,
    closesInputPart: closesInputPart,
    editActive: editActive,
  };
}

/** Object keeping track of the edit process and models the editing state
 */
export class InputControlLogic {
  constructor(dispatcher: Dispatcher, onInternalStateChanged: () => void) {
    this.onInternalStateChanged = onInternalStateChanged;
    this.referenceToDiv = undefined; // Reference to editDiv
    this.referenceToProposalSelection = undefined; // Reference to InputProposalSelection

    this._renderedText = '';
    this._textDataTT = []; // Current TemporaryText from server
    this._inputStateLocal = { input: [], curPos: makeCurPos(0, 0) };
    this._inputStateLocalAsText = '';
    this._inputStateLocalAsTextLast = '';

    this._curPosDesiredEffective = undefined; // Desired cursor position (after an edit happened)
    this._curPosDesiredEffectiveDone = undefined;
    this._curPosUI = undefined; // Current cursor position in UI
    this._curPosUILastValid = undefined; // Last valid cursor position in UI
    this._editActive = false; // true if user is editing (i.e. focus is in input control)
    this._isComposing = false;
    this._editDirection = EditDirection.ToRight;

    this._lastManipulationType = ManipulationType.None;

    this.dispatcher = dispatcher;
    this.op = undefined;
    this.lastSuggest = '';
  }

  destroy() {
    this.op?.close();
    this.op = undefined;
  }

  public setReferenceToDiv(ref: RefObject<HTMLDivElement>) {
    this.referenceToDiv = ref;
  }

  public setReferenceToProposalSelection(ref: RefObject<InputProposalSelectionIF>) {
    this.referenceToProposalSelection = ref;
  }

  private spacePressed() {
    console.log('Space pressed');
    if (this.referenceToProposalSelection?.current)
      return this.referenceToProposalSelection.current.triggerSelectedItem();
    return false;
  }

  public setTextData(tt: TemporaryText[]) {
    if (this._textDataTT !== tt) {
      console.log(`setTextData(${temporaryTextJoinMatch(tt)})`);
      // eslint-disable-next-line prefer-const
      let [icNew, curPosNew] = mergeLocalRemoteInputState(
        this.inputStateLocal,
        tt,
        this._editDirection !== EditDirection.ToRight
      );
      this._editDirection =
        curPosCompare(this.inputStateLocal.curPos, curPosNew) >= 0
          ? EditDirection.ToRight
          : EditDirection.ToLeft;

      // Re-suggest - it may be that fillwords got inserted which make a re-suggest necessary
      const newText = convertToString(icNew);
      if (
        newText.startsWith(this.lastSuggest) &&
        newText.length > this.lastSuggest.length &&
        this._editDirection === EditDirection.ToRight
      )
        this.sendSuggest(convertToString(icNew));

      // Jump to next item for special modes
      if (
        this.lastManipulationType === ManipulationType.Suggestion ||
        this.lastManipulationType === ManipulationType.None
      )
        curPosNew = calculateNewCurAtNextInputRequired(icNew, curPosNew);

      this._textDataTT = tt;
      this.setInputStateLocal({ input: icNew, curPos: curPosNew }, true);

      this.setCurPosDesiredEffective(curPosNew);
    }
  }

  public setEditText(text: string) {
    console.log('setEditText (old, new): ', this.renderedText, text);

    if (this.renderedText !== text) {
      // eslint-disable-next-line prefer-const
      let [icNew, curPosNew] = applyEdits(this.inputStateLocal, this.renderedText, text, () => {
        return this.spacePressed();
      });
      if (icNew !== undefined) {
        this._lastManipulationType = ManipulationType.Edit;
        this._editDirection =
          curPosCompare(this.inputStateLocal.curPos, curPosNew) >= 0
            ? EditDirection.ToRight
            : EditDirection.ToLeft;
        console.log('edit direction', this._editDirection);
        this.setInputStateLocal({ input: icNew, curPos: curPosNew });
      }
    }
  }

  public setComposing(flag: boolean) {
    this._isComposing = flag;
  }

  public beforeRender() {}

  public afterRender() {
    if (this.referenceToDiv?.current) {
      if (!this._isComposing) {
        fixSqiorNodes(this.referenceToDiv?.current);
        if (!isEqual(this._curPosDesiredEffectiveDone, this._curPosDesiredEffective))
          this._moveCursorPositionInDiv();
      }

      this._renderedText = this.referenceToDiv?.current?.innerText?.replace(/\n/g, '') || '';
      console.log(`Text after render: '${this.renderedText}'`);
    }
  }

  public setCurPosUI(pos: CursorPos | undefined | null) {
    if (pos === null)
      // Cursor Position is behind the last input part => thus the highest CursorPosition is used
      this._curPosUI = maxCurPos(this.inputStateLocal.input);
    else this._curPosUI = pos;

    if (pos !== undefined && pos !== this._curPosUILastValid)
      this._curPosUILastValid = this._curPosUI;

    this.onInternalStateChanged();
  }

  public setFocus(focus: boolean) {
    if (focus) {
      if (this._curPosUILastValid === undefined) this.setCurPosUI(makeCurPos(0, 0));

      this._editActive = true;
      this.onInternalStateChanged();

      this.ensureOperation();
    }
  }

  public isFinal(): boolean {
    return (
      this.editActive && !inputRequired(this.inputChain) && this.inputStateLocalAsText.length > 0
    );
  }

  public insertSuggestion(inputPart: InputPart, selected: string) {
    let assumedCurPos: CursorPos | undefined = undefined;

    // Calculate a valid assumed CursorPosition with the provided inputPart
    const idx = this.inputStateLocal.input.indexOf(inputPart);
    if (idx >= 0) assumedCurPos = makeCurPos(idx, 0);

    if (assumedCurPos !== undefined) {
      let newInputChain: InputChain;
      let newPos: CursorPos | undefined;

      // eslint-disable-next-line prefer-const
      [newInputChain, newPos] = insertSuggestion(
        this.inputStateLocal.input,
        assumedCurPos,
        selected
      );

      newPos = calculateNewCurAtNextInputRequired(newInputChain, newPos);

      this._lastManipulationType = ManipulationType.Suggestion;
      this.setInputStateLocal({ input: newInputChain, curPos: newPos });

      // Set focus to input field (might got lost while clicking)
      this.referenceToDiv?.current?.focus();
    }
  }

  public reset() {
    this.resetInternalEditState();
  }

  private resetInternalEditState() {
    this._lastManipulationType = ManipulationType.None;
    this.setInputStateLocal({ input: [], curPos: makeCurPos(0, 0) });
    this.setCurPosUI(makeCurPos(0, 0));

    this._inputStateLocal = { input: [], curPos: makeCurPos(0, 0) };
    this._inputStateLocalAsText = '';
    this._inputStateLocalAsTextLast = '';

    this._curPosDesiredEffective = undefined;
    this._curPosDesiredEffectiveDone = undefined;
    this._curPosUI = undefined;
    this._curPosUILastValid = undefined;
    this._editDirection = EditDirection.ToRight;
  }

  public cancel() {
    console.log('icl.cancel()');

    this.op?.close();
    this.op = undefined;
  }

  public handleKeyDown(e: KeyboardEvent<HTMLElement>) {
    // Forward events from focused control (= inputDivRef) to proposal control (= proposalRef) which is displaying suggestion or selection in case it is visible
    if (this.referenceToProposalSelection?.current) {
      this.referenceToProposalSelection?.current.onKeyDown(e);
      if (e.isDefaultPrevented()) return;
    }
  }

  private setInputStateLocal(value: InputState, internal = false) {
    this._inputStateLocalAsText = convertToString(value.input);

    if (!isEqual(this._inputStateLocal.input, value.input)) {
      this._inputStateLocal.input = value.input;
      this.onInternalStateChanged();
    }

    if (!isEqual(value.curPos, this._inputStateLocal.curPos)) {
      this._inputStateLocal.curPos = value.curPos;
      this.onInternalStateChanged();
      this.setCurPosDesiredEffective(value.curPos);
    }

    if (!internal && this._inputStateLocalAsTextLast !== this._inputStateLocalAsText) {
      this.sendSuggest(this._inputStateLocalAsText);
      this._inputStateLocalAsTextLast = this._inputStateLocalAsText;
    }
  }

  public getClosestInputPartWithItem() {
    return this.lastCurPos !== undefined
      ? getClosestInputPartWithItem(this.inputChain, this.lastCurPos)
      : undefined;
  }

  private setCurPosDesiredEffective(pos: CursorPos | undefined) {
    if (!isEqual(pos, this._curPosDesiredEffective)) {
      this._curPosDesiredEffective = pos;
    }
    this._curPosDesiredEffectiveDone = undefined;
    //this._moveCursorPositionInDiv();
  }

  focusAndReposition() {
    console.log('focusAndReposition()');
    //this.referenceToDiv?.current?.focus();
    const curPos = makeCurPos(
      this.inputStateLocal.input.length - 1,
      this.inputStateLocal.input[this.inputStateLocal.input.length - 1].text.length
    );
    this.setCurPosDesiredEffective(curPos);
    //this._moveCursorPositionInDiv();
  }

  private _moveCursorPositionInDiv() {
    if (
      this.referenceToDiv !== undefined &&
      this.editActive &&
      this._curPosDesiredEffective !== undefined &&
      !this._isComposing
    ) {
      console.log('_moveCursorPositionInDiv', this._curPosDesiredEffective);
      setCursorPosition(this._curPosDesiredEffective, this.referenceToDiv);
      this._curPosDesiredEffectiveDone = this._curPosDesiredEffective;
    }
  }

  private ensureOperation(interpreterKey?: string, initialSuggest?: string) {
    if (!this.op || isFinal(this.op.state)) {
      if (interpreterKey !== undefined) {
        const init: { interpreterKey: string; suggest?: string } = {
          interpreterKey: interpreterKey,
        };
        if (initialSuggest !== undefined) init.suggest = initialSuggest;
        this.op = new StreamOperation(init);
      } else {
        this.op = new StreamOperation({});
      }

      this.op.stateChange.on((state) => {
        if (isFinal(state)) {
          this.resetInternalEditState();

          this._editActive = false;
          this._isComposing = false;
          this.onInternalStateChanged();
        }
      });

      this.dispatcher.handle(this.op, 'main-command');
    }
  }

  takeOverInterpreter(interpreterKey: string | undefined, text: string) {
    // stop any potential conversation first
    if (this.op !== undefined) {
      this.op.close();
      this.op = undefined;
    }

    // Start new conversation with interpreterKey
    this.ensureOperation(interpreterKey, text);

    // Directly suggest the input
    // TODO: - implementthis.suggest(text, true);
    throw new Error('please implement once this functionality is needed again - SF');
  }

  private sendSuggest(suggest: string) {
    console.log(`suggest('${suggest}') - last suggest: '${this.lastSuggest}'`);
    if (suggest !== this.lastSuggest) {
      this.lastSuggest = suggest;
      this.ensureOperation();
      this.op?.send({ suggest: suggest });
    }
  }

  private sendFinalInternal() {
    console.log('final()', this.lastSuggest);
    this.ensureOperation();
    this.op?.send({ finalize: this.lastSuggest });

    this.lastSuggest = '';
    this.resetInternalEditState();
  }

  public sendFinal() {
    if (this.isFinal()) this.sendFinalInternal();
  }

  public get curPosDesired() {
    return this.inputStateLocal.curPos;
  }
  public get curPosDesiredEffective() {
    return this._curPosDesiredEffective;
  }
  public get curPosUI() {
    return this._curPosUI;
  }
  public get editActive() {
    return this._editActive;
  }
  public get inputChain() {
    return this.inputStateLocal.input;
  }
  public get inputStateLocal() {
    return this._inputStateLocal;
  }
  public get inputStateLocalAsText() {
    return this._inputStateLocalAsText;
  }
  public get lastCurPos() {
    return this._curPosUILastValid;
  }
  public get lastManipulationType() {
    return this._lastManipulationType;
  }
  public get renderedText() {
    return this._renderedText;
  }

  private onInternalStateChanged: () => void;
  private referenceToDiv: RefObject<HTMLDivElement> | undefined;
  private referenceToProposalSelection: RefObject<InputProposalSelectionIF> | undefined;
  private _renderedText: string;

  private _textDataTT: TemporaryText[];
  private _inputStateLocal: InputState;
  private _inputStateLocalAsText: string;
  private _inputStateLocalAsTextLast: string;

  private _curPosDesiredEffective: CursorPos | undefined;
  private _curPosDesiredEffectiveDone: CursorPos | undefined;
  private _curPosUI: CursorPos | undefined;
  private _curPosUILastValid: CursorPos | undefined;
  private _editDirection: EditDirection;

  private _editActive: boolean;
  private _isComposing: boolean;

  private _lastManipulationType: ManipulationType;

  private dispatcher: Dispatcher;
  private op: StreamOperation | undefined;
  private lastSuggest: string;
}
