import { FC, useEffect } from 'react';
import { ClickAwayListener, Paper, Popper } from '@mui/material';

export type SContextAnchorEl = {
  position: {
    getBoundingClientRect(): DOMRect;
  } | null;
  value: Element | null;
};

export interface SContextMenuProps {
  anchorEl: SContextAnchorEl | null;
  onClose: () => void;
  children?: React.ReactNode;
}

export const SContextMenu: FC<SContextMenuProps> = ({ anchorEl, onClose, children }) => {
  const onClickAway = () => {
    onClose();
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClickAway}>
      <Popper
        sx={{ zIndex: 1200 }}
        open={Boolean(anchorEl?.position)}
        anchorEl={anchorEl?.position}
        placement="right-start"
      >
        <Paper sx={{ background: '#293042' }}>{children}</Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default SContextMenu;
