import { doUndo, UndoInfoVM } from '@sqior/viewmodels/communication';
import { OperationContext } from '@sqior/react/operation';
import { useDynamicState } from '@sqior/react/state';
import { ButtonX, useTextResources } from '@sqior/react/uibase';
import { classes } from '@sqior/react/utils';
import { useContext, useEffect, useState } from 'react';
import EntityVisual from '../entity-visual/entity-visual';
import styles from './undo-info-toast.module.css';

/* eslint-disable-next-line */
export interface UndoInfoToastProps {
  className?: string;
}

const AnimationTime = 300;

export function UndoInfoToast(props: UndoInfoToastProps) {
  const textResources = useTextResources();
  const dispatcher = useContext(OperationContext);

  const [undoInfo, setUndoInfo] = useState<UndoInfoVM | undefined>(undefined); // Preserve data for longer display
  const [displaySomething, setDisplaySomething] = useState(false); // Becomes true as soon as something is to be displayed and stays true as the whole animation lasts
  const [undoInfoDismiss, setUndoInfoDismiss] = useState<string | undefined>(undefined);

  const undoInfoArr = useDynamicState<UndoInfoVM[]>('undo-info', []);
  // const undoInfoArr: UndoInfoVM[] = [{
  //   text: { template: "Bitte undo mit sehr langem text und noch mehr", params: {}},
  //   undoInterpreterKey: "-1",
  //   timestamp: Date.now()
  // }];
  const undoInfoSrc =
    undoInfoArr.length > 0 && undoInfoDismiss !== undoInfoArr[0].undoInterpreterKey
      ? undoInfoArr[0]
      : undefined;

  // Animation Stuff
  useEffect(() => {
    if (undoInfoSrc !== undefined) {
      setDisplaySomething(true);
      setTimeout(() => {
        if (undoInfoSrc !== undefined) setUndoInfo(undoInfoSrc);
      }, 100);
    } else
      setTimeout(() => {
        if (undoInfoSrc === undefined) {
          setUndoInfo(undefined);
          setDisplaySomething(false);
        }
      }, AnimationTime);
  }, [undoInfoSrc]);

  const visible = undoInfo !== undefined && undoInfoSrc !== undefined;
  const visibleClass = styles[visible ? 'visible' : 'invisible'];

  function onUndo() {
    if (undoInfo?.undoInterpreterKey) doUndo(dispatcher, undoInfo.undoInterpreterKey);
  }
  function onClose() {
    setUndoInfoDismiss(undoInfo?.undoInterpreterKey);
  }

  const cssVariables: Record<string, string> = {
    '--animation-time': `${AnimationTime}ms`,
  };

  return displaySomething ? (
    <div
      className={classes(styles['container'], props.className, visibleClass)}
      style={cssVariables}
      key="123"
    >
      <div className={styles['container2']}>
        {undoInfo && (
          <div className={styles['inner-container']}>
            <div className={styles['text']}>
              <EntityVisual content={undoInfo.text} />
            </div>
            <div className={styles['revert']} onClick={onUndo}>
              {textResources.get('revert')}
            </div>
          </div>
        )}
        {false && <ButtonX onClick={onClose} additionalClassName={styles['cancelbutton']} />}
      </div>
    </div>
  ) : null;
}

export default UndoInfoToast;
