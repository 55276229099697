import { SpecialtyVM } from '@sqior/viewmodels/patient';
import SpecialtyView from '../specialty-view/specialty-view';
import SqiorChip from '../sqior-chip/sqior-chip';
import SqiorClockProgress from '../sqior-clock-progress/sqior-clock-progress';
import styles from './tags.module.css';

function TagSpecialty(props: { name: string; color?: string; nameLong?: string }) {
  const specialtyVM: SpecialtyVM = {
    specialty: props.name,
  };
  if (props.color) specialtyVM.specialtyColor = props.color;
  if (props.nameLong) specialtyVM.specialtyLong = props.nameLong;
  return (
    <SpecialtyView
      uppercase={true}
      specialty={specialtyVM}
      style={{ borderRadius: 2, padding: '2px 4px', fontSize: 9 }}
    />
  );
}
export function TagIsolation() {
  return (
    <SqiorChip
      label="Iso"
      icon={<div style={{ color: 'rgba(255, 192, 0, 1)', fontSize: 9 }}>!</div>}
    />
  );
}

export function TagPriority() {
  return (
    <SqiorChip
      label="Prio"
      icon={<div style={{ color: 'rgba(245, 93, 93, 1)', fontSize: 9 }}>!</div>}
    />
  );
}
export function TagMonitoring() {
  return (
    <SqiorChip
      label="Mon"
      icon={<div style={{ color: 'rgba(245, 93, 93, 1)', fontSize: 9 }}>!</div>}
    />
  );
}

export function TagNursingComplexity(props: { complexity: number }) {
  return (
    <div className={styles['tag-nursing-complexity']}>
      <SqiorClockProgress value={props.complexity} crossColor="#121830" fontSize={8} />
    </div>
  );
  return <span>{props.complexity.toString()}</span>;
}

export default TagSpecialty;
