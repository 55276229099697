import { ClosablePage, HighlightButton, useTextResources } from '@sqior/react/uibase';
import ActiveLocationSelection from '../active-location-selection/active-location-selection';
import styles from './qrpage.module.css';
import { FactoryProps } from '@sqior/react/factory';

export function QRRoomPage(props: FactoryProps) {
  const textDict = useTextResources();

  function onClose() {
    props.onClose?.(true, undefined);
  }

  return (
    <ClosablePage onClose={onClose}>
      <div className={styles['container']}>
        <ActiveLocationSelection description={'qr_room_description'} />
        <div className={styles['bottombar']}>
          <HighlightButton onClick={onClose}>{textDict.get('close')}</HighlightButton>
        </div>
      </div>
    </ClosablePage>
  );
}

export default QRRoomPage;
