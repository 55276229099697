import { Closable } from './closable';

/* This class takes an async callback and returns the values as an async iterator */
export class AsyncIterator<Type> implements Closable {
  constructor(it: () => Promise<Type | null>, ret?: () => Promise<void>) {
    this.it = it;
    this.ret = ret;
  }

  async close() {
    if (!this.ret) return;
    await this.ret();
    this.ret = undefined;
  }

  [Symbol.asyncIterator]() {
    const iterator = this.it;
    return {
      async next(): Promise<{ value?: Type; done: boolean }> {
        const v = await iterator();
        if (v === null) return { done: true };
        else return { value: v, done: false };
      },
    };
  }

  readonly it: () => Promise<Type | null>;
  private ret?: () => Promise<void>;
}
