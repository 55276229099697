import { HighlightButton, useTextResources } from '@sqior/react/uibase';
import { useContext, useEffect } from 'react';
import { ServerConnectionContext } from '../server-connection-context/server-connection-context';
import { ReactComponent as ErrorIcon } from './conn-error.svg';
import { ReactComponent as DetailInfoIcon } from './conn-error-detailinfo.svg';
import styles from './server-connection-page.module.css';
import { ZIndex } from '@sqior/react/utils';
import { Logger } from '@sqior/js/log';

/* eslint-disable-next-line */
export interface ServerConnectionPageProps {}

export function ServerConnectionPage(props: ServerConnectionPageProps) {
  const textDict = useTextResources();
  const connContext = useContext(ServerConnectionContext);
  /* Log the display and removal of the server connection page to simplify log analysis */
  useEffect(() => {
    Logger.debug('Showing connection error dialog!');
    return () => {
      Logger.debug('Closing connection error dialog');
    };
  }, []);
  return (
    <div className={styles['container']} style={{ zIndex: ZIndex.ServerConnectionErrorPage }}>
      <ErrorIcon className={styles['icon']} />
      <div className={styles['description']}>{textDict.get('connection_lost')}</div>
      <div className={styles['assistance']}>{textDict.get('check_connection')}</div>
      <HighlightButton
        additionalClassName={styles['button']}
        onClick={() => {
          connContext.reconnect();
        }}
      >
        {textDict.get('connect')}
      </HighlightButton>
      <div className={styles['detailinfo']}>
        <DetailInfoIcon className={styles['detailinfo-icon']} viewBox="0 0 40 40" />
        <div className={styles['detailinfo-text']}>{connContext.errorString(textDict)}</div>
      </div>
    </div>
  );
}

export default ServerConnectionPage;
