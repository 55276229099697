import { TemporaryTextType } from '@sqior/viewmodels/input';
import { classes } from '@sqior/react/utils';
import { RefObject, useState, useEffect } from 'react';
import {
  CursorPos,
  InputPart,
  InputChain,
  makeCurPos,
} from '../input-control/input-control-processor';
import styles from './conversation-entities.module.css';

function GetMatchAndProposal(ip: InputPart, index = 0): string[] {
  const txtMatch = ip.text;
  const txtProposal =
    (Array.isArray(ip.item?.text) ? ip.item?.text[index].option : ip.item?.text) || '';
  const txtProposalWOMatch = txtProposal.substring(txtMatch.length);

  return [txtMatch, txtProposalWOMatch];
}

export interface ConversationEntityProps {
  renderPropsal?: boolean;
  active?: boolean;
  debug?: boolean;
  part: InputPart;
  ipId: number;
  itemSelected?: (inputPart: InputPart, value: string) => void;
}

function classesBase(props: ConversationEntityProps) {
  const active = props.active ? styles['item-base-active'] : '';
  const debug = props.debug ? styles['item-base-debug'] : '';
  return classes(styles['item-base'], active, debug);
}

export function ConversationEntityFiller(props: ConversationEntityProps) {
  return (
    <span
      tabIndex={0}
      className={classesBase(props)}
      sqior-type="filler"
      sqior-text={props.part.text}
      sqior-ip-id={props.ipId}
    >
      {props.part.text}
    </span>
  );
}

export function ConversationEntityBase(props: ConversationEntityProps) {
  if (props.part.item === undefined)
    // Never happens
    throw Error('props.part.item must be defined');

  const [txtMatch, txtUnmatched] = GetMatchAndProposal(props.part);

  const items = [];
  if (txtMatch)
    items.push(
      <span key="match" sqior-text-type="match" className={styles['item-base-text-match']}>
        {txtMatch}
      </span>
    );

  if (txtUnmatched && props.renderPropsal === true)
    items.push(
      <span key="unmatch" sqior-text-type="unmatch" className={styles['item-base-text-unmatch']}>
        {txtUnmatched}
      </span>
    );

  return (
    <span tabIndex={0} className={classesBase(props)} sqior-type="base" sqior-ip-id={props.ipId}>
      {items}
    </span>
  );
}

export function ConversationEntityExample(props: ConversationEntityProps) {
  if (props.part.item === undefined)
    // Never happens
    throw Error('props.part.item must be defined');

  const [txtMatch, txtUnmatched] = GetMatchAndProposal(props.part);

  const items = [];
  if (txtMatch)
    items.push(
      <span key="match" sqior-text-type="match" className={styles['item-base-text-match']}>
        {txtMatch}
      </span>
    );

  if (txtUnmatched && props.renderPropsal === true)
    items.push(
      <span
        key="unmatch"
        sqior-text-type="unmatch"
        className={`${styles['item-base-text-unmatch']} ${styles['item-example-unmatch']}`}
      >
        {txtUnmatched}
      </span>
    );

  return (
    <span tabIndex={0} className={classesBase(props)} sqior-type="example" sqior-ip-id={props.ipId}>
      {items}
    </span>
  );
}

export function ConversationEntitySelection(props: ConversationEntityProps) {
  if (props.part.item === undefined)
    // Never happens
    throw Error('props.part.item must be defined');

  const [txtMatch] = GetMatchAndProposal(props.part);

  const items = [];
  if (txtMatch)
    items.push(
      <span key="match" sqior-text-type="match" className={styles['item-base-text-match']}>
        {txtMatch}
      </span>
    );

  if (props.renderPropsal === true)
    items.push(
      <span
        key="unmatch"
        sqior-text-type="unmatch"
        className={`${styles['item-base-text-unmatch']} ${styles['item-selection-unmatch']}`}
      >
        {'Auswahl'}
      </span>
    );

  return (
    <span
      tabIndex={0}
      className={classesBase(props)}
      sqior-type="selection"
      sqior-ip-id={props.ipId}
    >
      {items}
    </span>
  );
}

export type ConversationEntitiesProps = {
  input: InputChain | undefined;
  activeInputPart?: InputPart;
  renderPropsal?: boolean;
  cbInputSelected?: (inputPart: InputPart, value: string) => void;
  debug?: boolean;
};
export function ConversationEntities(props: ConversationEntitiesProps): JSX.Element {
  const resultElements: JSX.Element[] = [];

  let i = 0;
  if (props.input !== undefined) {
    for (const v of props.input) {
      if (v.item === undefined) {
        resultElements.push(
          <ConversationEntityFiller
            key={i}
            renderPropsal={props.renderPropsal}
            part={v}
            ipId={i}
            active={props.activeInputPart === v}
            debug={props.debug}
          />
        );
      } else {
        if (v.item.type === TemporaryTextType.Fixed) {
          resultElements.push(
            <ConversationEntityBase
              key={i}
              renderPropsal={props.renderPropsal}
              part={v}
              ipId={i}
              active={props.activeInputPart === v}
              debug={props.debug}
            />
          );
        } else {
          if (v.item.type === TemporaryTextType.Suggestion) {
            resultElements.push(
              <ConversationEntityBase
                key={i}
                renderPropsal={props.renderPropsal}
                part={v}
                ipId={i}
                active={props.activeInputPart === v}
                debug={props.debug}
                itemSelected={props.cbInputSelected}
              />
            );
          } else if (v.item.type === TemporaryTextType.Selection) {
            resultElements.push(
              <ConversationEntitySelection
                key={i}
                renderPropsal={props.renderPropsal}
                part={v}
                ipId={i}
                active={props.activeInputPart === v}
                debug={props.debug}
                itemSelected={props.cbInputSelected}
              />
            );
          } else if (v.item.type === TemporaryTextType.Example) {
            resultElements.push(
              <ConversationEntityExample
                key={i}
                renderPropsal={props.renderPropsal}
                part={v}
                ipId={i}
                active={props.activeInputPart === v}
                debug={props.debug}
                itemSelected={props.cbInputSelected}
              />
            );
          } else if (v.item.type === TemporaryTextType.Pending) {
          }
        }
      }
      i++;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{resultElements}</>;
}

/** Observes the cursor position in document tree containing InputChain elements and returns a corresponding CursorPos if cursor is located inside such an item
 * @param inputChain The source InputChain to look in.
 * @param referenceToDiv The element where to stop the search (is typically the surrounding div-element).
 * @returns Valid CursorPos object or undefined if cursor is not located in a InputChain element.
 */
export function useGetCursorPosition(
  referenceToDiv: RefObject<HTMLDivElement>
): [
  CursorPos | undefined | null,
  React.Dispatch<React.SetStateAction<CursorPos | undefined | null>>
] {
  const [curPos, setCurPos] = useState<CursorPos | undefined | null>(undefined);

  useEffect(() => {
    function handleSelection() {
      // Get current cursor position via
      const selection = document.getSelection();

      if (selection) {
        let node = selection.focusNode;
        let pos = selection.focusOffset;
        let foundCurPos: CursorPos | undefined = undefined;

        console.log('current cursor pos', node, pos);

        // If node is selected => signal with null (typically, the cursor is behind the last sqior node)
        if (node === referenceToDiv.current) {
          setCurPos(null);
          return;
        }
        // Otherwise traverse the DOM
        while (node !== null && node !== referenceToDiv.current) {
          if (foundCurPos === undefined && node instanceof Element) {
            const element = node as Element;
            const value = element.getAttribute('sqior-ip-id');
            if (value) {
              const ipId = parseInt(value);
              foundCurPos = makeCurPos(ipId, pos);
            }
          }

          // Walk through the nodes, siblings first; count and add text-length for siblings
          if (foundCurPos === undefined && node.previousSibling) {
            node = node.previousSibling;
            const nodeTextLen = node.textContent ? node.textContent.length : 0;
            pos = pos + nodeTextLen;
          } else {
            node = node.parentNode;
          }
        }

        if (foundCurPos !== undefined && node === referenceToDiv.current) {
          setCurPos(foundCurPos);
          return;
        }
      }
      setCurPos(undefined);
    }

    document.addEventListener('selectionchange', handleSelection);

    return () => {
      document.removeEventListener('selectionchange', handleSelection);
    };
  }, [referenceToDiv]);

  return [curPos, setCurPos];
}

/**Sets the cursor to a position within a InputChain element.
 *
 * @param cursorPos The cursor position to set.
 * @param referenceToDiv The surrounding div-element which contains the Elements repreenting the InputChain.
 */
export function setCursorPosition(
  cursorPos: CursorPos | undefined,
  referenceToDiv: RefObject<HTMLDivElement>
) {
  function findCursorNode(node: Node | undefined, cursorPos: CursorPos): HTMLElement | undefined {
    if (!node) return;

    // Check this node
    if (node instanceof HTMLElement) {
      const element = node as Element;
      const value = element.getAttribute('sqior-ip-id');
      if (value === cursorPos.ipId.toString()) {
        return node;
      }
    }

    // Check siblings
    if (node?.nextSibling) {
      const s = findCursorNode(node?.nextSibling, cursorPos);
      if (s) return s;
    }

    // Check childs
    if (node?.firstChild) {
      const c = findCursorNode(node?.firstChild, cursorPos);
      if (c) return c;
    }

    return undefined;
  }

  function setCursorPositionInternal(node: Node | undefined, cursorPos: number) {
    if (!node) return;

    if (node.firstChild) setCursorPositionInternal(node.firstChild, cursorPos);
    else {
      // Check this node
      const thisTextLen = node.textContent ? node.textContent.length : 0;
      if (cursorPos <= thisTextLen) {
        // Set position
        console.log('setCursorPosition() - set selection to node: ', node, cursorPos);
        document.getSelection()?.setPosition(node, cursorPos);
      } else {
        if (node.nextSibling) setCursorPositionInternal(node.nextSibling, cursorPos - thisTextLen);
      }
    }
  }

  const pos = cursorPos;
  console.log('setCursorPosition()', pos);
  if (pos !== undefined) {
    //setTimeout(() => {
    const node: Node | undefined = referenceToDiv.current ? referenceToDiv.current : undefined;
    const baseNode = findCursorNode(node, pos);
    if (baseNode !== undefined) {
      setCursorPositionInternal(baseNode, pos.curPos);
      referenceToDiv.current?.focus();
      // console.log("setCursorPosition() - set focus to node: ", baseNode)
      // baseNode.focus();
    }
    //})
  }
}

export function fixSqiorNodes(root: HTMLElement) {
  deleteNonSqiorNodes(root);
  fixSqiorTextNodes(root);
}

function deleteNonSqiorNodes(root: Element) {
  const nodesToRemove: Node[] = [];

  if (root.getAttribute('sqior-text-type') !== null || root.getAttribute('sqior-text') !== null)
    return;

  root.childNodes.forEach((node) => {
    let del = true;
    if (node instanceof Element) {
      const element = node as Element;
      if (element.getAttribute('sqior-text-type') !== null) del = false;
      else if (element.getAttribute('sqior-type') !== null) {
        del = false;
        deleteNonSqiorNodes(element);
      }
    }

    if (del) nodesToRemove.push(node);
  });
  nodesToRemove.forEach((node) => {
    console.log('Delete node', node);
    root.removeChild(node);
  });
}

function fixSqiorTextNodes(root: Node) {
  function fixTextNode(node: Element) {
    const text = node.getAttribute('sqior-text');
    if (node.childNodes.length === 1 && node.childNodes[0].nodeValue !== text)
      node.childNodes[0].nodeValue = text;
  }

  root.childNodes.forEach((node) => {
    if (node instanceof Element) {
      const element = node as Element;
      if (element.getAttribute('sqior-text') !== null) {
        fixTextNode(element);
      } else {
        fixSqiorTextNodes(node);
      }
    }
  });
}

export function extractText(node: Node): string {
  if (!(node instanceof HTMLElement)) return '';

  const element = node as HTMLElement;
  const textType = element.getAttribute('sqior-text-type');
  const type = element.getAttribute('sqior-type');
  if (textType === 'unmatch') return '';
  if (textType === 'match') return element.innerText;
  if (type === 'filler') return ' ';
  if (!node.hasChildNodes()) return element.innerText;

  let curText = '';
  node.childNodes.forEach((node) => {
    curText = curText + extractText(node);
  });
  return curText;
}

export default ConversationEntityBase;
