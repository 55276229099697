function magicPrefix(type: string) {
  return '__' + type + '__';
}

export function writeMagic(type: string, value = '') {
  return magicPrefix(type) + value;
}

export function readMagic(value: string, type: string) {
  const prefix = magicPrefix(type);
  if (value.substring(0, prefix.length) == prefix) return value.substring(prefix.length);
  else return undefined;
}
