import { addSeconds, clone, shallowClone } from '@sqior/js/data';
import { AddOperation, Operation, OperationState } from '@sqior/js/operation';
import { StateOverlay } from '@sqior/js/state';
import {
  InformProjectionVM,
  MarkReadData,
  ReadConfirmationTypes,
  ResponseData,
} from './inform-projection';

function handleResponse(value: InformProjectionVM[], index: number, data: ResponseData) {
  /* Set pre-empted response if not already set, otherwise disable overlay */
  const resOptions = value[index].responseOptions?.[data.responseIndex];
  if (resOptions && !resOptions.response && resOptions.options) {
    const res = shallowClone(value);
    res[index] = clone(value[index]);
    const changeResOptions = res[index].responseOptions?.[data.responseIndex];
    if (changeResOptions) {
      changeResOptions.response = resOptions.options[data.responseId].text;
      changeResOptions.responseTimestamp = data.timestamp;
    }
    return { value: res, deact: false };
  }
  return { value, deact: true };
}

function handleReadConfirmation(value: InformProjectionVM[], index: number, data: MarkReadData) {
  if (!value[index].myReadConfirmationStatus) {
    const res = shallowClone(value);
    res[index] = clone(value[index]);
    res[index].myReadConfirmationStatus = data.confirmationType;
    return { value: res, deact: false };
  }
  return { value, deact: true };
}

export function PersonsCommunicationStateOverlayBase(
  op: Operation,
  responseHandler = handleResponse,
  readConfirmationHandler = handleReadConfirmation
) {
  if (!(op instanceof AddOperation)) return undefined;
  const data = op.data as ResponseData | MarkReadData;
  /* Do not react on read confirmations with implicit mode */
  if ('confirmationType' in data && data.confirmationType !== ReadConfirmationTypes.Explicit)
    return undefined;
  /* Create overlay inserting temporary string if not found */
  const overlay = StateOverlay.create<InformProjectionVM[]>((value) => {
    /* Find the entry */
    let index = -1;
    if (Array.isArray(data.id)) {
      for (const id of data.id) {
        index = value.findIndex((item) => {
          return item.id === id;
        });
        if (index >= 0) break;
      }
    } else
      index = value.findIndex((item) => {
        return item.id === data.id;
      });
    /* If the respective item is no longer found, disable the overlay */
    if (index < 0) {
      setTimeout(() => {
        overlay.clear();
      });
      return value;
    }
    /* Call appropriate handler */
    let res: { value: InformProjectionVM[]; deact: boolean };
    if ('responseIndex' in data) res = responseHandler(value, index, data);
    else res = readConfirmationHandler(value, index, data);
    /* Deactivate overlay, if desired */
    if (res.deact)
      setTimeout(() => {
        overlay.clear();
      });
    return res.value;
  }, []);

  /* Clear overlay if operation failed */
  op.stateChange.on((state) => {
    if (state === OperationState.Failed) overlay.clear();
    else if (state === OperationState.Completed)
      setTimeout(() => {
        overlay.clear();
      }, addSeconds(5));
  });

  return overlay;
}

export function PersonsCommunicationStateOverlay(op: Operation) {
  return PersonsCommunicationStateOverlayBase(op);
}

/** Special handler for response in main info state, removing the message if final response is provided */

function handleMainInfoResponse(value: InformProjectionVM[], index: number, data: ResponseData) {
  const respOptions = value[index].responseOptions;
  if (respOptions) {
    /* Check if the is the last response option, in this case remove the message */
    if (data.responseIndex + 1 >= respOptions.length) {
      const res = shallowClone(value);
      res.splice(index, 1);
      return { value: res, deact: false };
    } else return handleResponse(value, index, data);
  }
  return { value, deact: true };
}

/** Special handler for read confirmation in main info state, simply remove the message */

function handleMainInfoReadConfirmation(value: InformProjectionVM[], index: number) {
  const res = shallowClone(value);
  res.splice(index, 1);
  return { value: res, deact: false };
}

export function MainInfoStateOverlay(op: Operation) {
  return PersonsCommunicationStateOverlayBase(
    op,
    handleMainInfoResponse,
    handleMainInfoReadConfirmation
  );
}
