import styles from './analytic-dashboard-group-factory.module.css';
import AnalyticDashboardGroup from '../analytic-dashboard-group/analytic-dashboard-group';
import { FactoryProps } from '@sqior/react/factory';
import { AnalyticsDashboardGroupVM } from '@sqior/viewmodels/analytics-dashboard';

export type AnalyticDashboardGroupFactory = FactoryProps<AnalyticsDashboardGroupVM>;

export function AnalyticDashboardGroupFactory({ data }: AnalyticDashboardGroupFactory) {
  return <AnalyticDashboardGroup {...data} />;
}

export default AnalyticDashboardGroupFactory;
