import { FactoryProps } from '@sqior/react/factory';
import styles from './treatment-path-overview.module.css';
import { TreatmentPathOverviewEntity } from '@sqior/viewmodels/treatment-path';
import { DiagnosisInfo } from '@sqior/react/uipatient';
import { ContainerWithShadow, useTextResources } from '@sqior/react/uibase';
import { ReactComponent as CheckIcon } from './icon-check.svg';
import TreatmentPath from '../treatment-path/treatment-path';

export type TreatmentPathOverviewProps = FactoryProps<TreatmentPathOverviewEntity>;

export function TreatmentPathOverview(props: TreatmentPathOverviewProps) {
  const textDict = useTextResources();
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>{textDict.get('treatment_path')}</div>
        {props.data.diagnosis && <DiagnosisInfo diagnosis={props.data.diagnosis} />}
        {props.data.stay && (
          <div className={styles['icon-container']}>
            <CheckIcon className={styles['icon']} />
            <div className={styles['icon-description']}>{textDict.get('planned_stay')}</div>
            <div className={styles['icon-description-highlight']}>{props.data.stay}</div>
          </div>
        )}
      </div>
      <ContainerWithShadow className={styles['path-container']}>
        <TreatmentPath
          patient={props.data.patient}
          path={props.data.path}
          changed={props.data.changed}
        />
      </ContainerWithShadow>
    </div>
  );
}

export default TreatmentPathOverview;
