import { Operation } from '@sqior/js/operation';
import { ValueInspectionStateOverlay } from '@sqior/js/state-operation';

export function DefaultLocationOverlay(op: Operation) {
  return ValueInspectionStateOverlay<string, string>(
    op,
    '',
    (state, op) => {
      return state === op;
    },
    (state, op) => {
      return op;
    }
  );
}
