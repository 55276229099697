import ButtonClose from '../button-close/button-close';
import Logo, { LogoType } from '../logo/logo';
import PopupPage from '../popup-page/popup-page';
import { useOnCancel } from '../use-on-cancel';
import styles from './sqior-page.module.css';

export interface SqiorPageProps {
  children: React.ReactNode;
  onClose: () => void;
}

export function SqiorPage(props: SqiorPageProps) {
  useOnCancel(props.onClose);

  return (
    <PopupPage className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['btn-balancer']} />
        <Logo type={LogoType.RectWhite} className={styles['logo']} />
        <ButtonClose additionalClassName={styles['btn-close']} onClick={props.onClose} />
      </div>
      {props.children}
    </PopupPage>
  );
}

export default SqiorPage;
