import { IConfigContext, SimpleConfigContext } from '@sqior/js/url';
import React from 'react';

const envURL = process.env['REACT_APP_SQIOR_SERVICES_BASEURL'];
const currentURL = new URL(window.location.href);
const backendPort = currentURL.searchParams.get('backend');

const useURL = backendPort
  ? new URL(`http://localhost:${backendPort}`)
  : envURL
  ? new URL(envURL)
  : currentURL;

export const ConfigContext = React.createContext<IConfigContext>(new SimpleConfigContext(useURL));
