import { OperationSpec, OperationType } from '@sqior/js/operation';

/** Path for device dynamic roles */

export const DeviceDynamicRolePath = 'device-dynamic-role';

/** View model for device dynamic role */

export type DeviceDynamicRolesItemViewModel = { label: string; id: string };
export type DeviceDynamicRolesViewModel = {
  items: DeviceDynamicRolesItemViewModel[];
  selected?: string;
};

/** Operation to select the device dynamic role */

export function SelectDeviceDynamicRole(id: string): OperationSpec<string> {
  return { type: OperationType.Add, path: DeviceDynamicRolePath, data: id };
}
